import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import {distinctUntilChanged, filter, map, Observable, startWith} from 'rxjs';

import {classPredicate} from '@azarus/common/utils/class-predicate';

import type {AzaCdkResolveKey} from './resolve-key.type';

function findOutletBranch(
  snapshot: ActivatedRouteSnapshot,
  outlet: string,
): ActivatedRouteSnapshot | null {
  if (snapshot.outlet === outlet) {
    return snapshot;
  }
  for (let childSnapshot of snapshot.children) {
    const foundSnapshot = findOutletBranch(childSnapshot, outlet);
    if (foundSnapshot !== null) {
      return foundSnapshot;
    }
  }
  return null;
}

@Injectable({providedIn: 'root'})
export class AzaCdkRouterResolverHelperService {
  private readonly _navigationEnd$ = this._router.events.pipe(
    filter(classPredicate(NavigationEnd)),
    startWith(null),
  );

  public constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
  ) {}

  public deepestByKey<T, F = never>(
    key: AzaCdkResolveKey<T>,
    targetOutlet: string = 'primary',
    fallback: () => F = () => {
      throw new Error('key not found in router');
    },
  ): Observable<T | F> {
    return this._navigationEnd$.pipe(
      map(() => {
        let snapshot: ActivatedRouteSnapshot | null = findOutletBranch(
          this._activatedRoute.snapshot,
          targetOutlet,
        );
        if (snapshot === null) {
          return fallback();
        }

        let result: T | undefined;

        do {
          let data: T | undefined = snapshot.data[key];

          if (data !== undefined) {
            result = data;
          }

          snapshot = snapshot.firstChild;
        } while (snapshot !== null);

        if (result === undefined) {
          return fallback();
        }

        return result;
      }),
      distinctUntilChanged(),
    );
  }

  public stackByKey<T>(key: AzaCdkResolveKey<T>): Observable<T[]> {
    return this._navigationEnd$.pipe(
      map(() => {
        let snapshot: ActivatedRouteSnapshot | null =
          this._activatedRoute.snapshot;

        let result: T[] = [];

        do {
          let data: T | undefined = snapshot.data[key];

          if (data !== undefined) {
            result.push(data);
          }

          snapshot = snapshot.firstChild;
        } while (snapshot !== null);

        return result;
      }),
    );
  }
}
