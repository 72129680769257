import {NgModule} from '@angular/core';

import {AzaCoreLogoSvgModule} from '@azarus/frontend/core/modules/logo-svg/logo-svg.module';

import {LogoComponent} from './logo.component';

@NgModule({
  imports: [AzaCoreLogoSvgModule],
  declarations: [LogoComponent],
  exports: [LogoComponent],
})
export class LogoModule {}
