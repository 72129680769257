// DP - distribution part
export enum TransactionType {
  // user's transactions
  GAME_SESSION_WIN = 'GAME_SESSION_WIN',
  SHOP_PURCHASE = 'SHOP_PURCHASE',
  PAYMENT = 'PAYMENT',
  USER_BALANCE_CORRECTION = 'USER_BALANCE_CORRECTION',
  TRANSFER_BALANCE = 'TRANSFER_BALANCE',
  USER_REFUND = 'USER_REFUND',
  KYC = 'KYC',
  BLOCKCHAIN_BRIDGE_WRAP = 'BLOCKCHAIN_BRIDGE_WRAP',
  BLOCKCHAIN_BRIDGE_UNWRAP = 'BLOCKCHAIN_BRIDGE_UNWRAP',
  BLOCKCHAIN_BRIDGE_UNWRAP_REFUND = 'BLOCKCHAIN_BRIDGE_UNWRAP_REFUND',
  BLOCKCHAIN_BRIDGE_UNWRAP_FEE = 'BLOCKCHAIN_BRIDGE_UNWRAP_FEE',
  BLOCKCHAIN_BRIDGE_UNWRAP_FEE_REFUND = 'BLOCKCHAIN_BRIDGE_UNWRAP_FEE_REFUND',
  GAME_SESSION_STREAMER_DP = 'GAME_SESSION_STREAMER_DP',

  // streamer's transactions
  GAME_SESSION_CHANNEL_TREASURY_DP = 'GAME_SESSION_CHANNEL_TREASURY_DP',
  STREAMER_PAYOUT = 'STREAMER_PAYOUT',
  STREAMER_BALANCE_CORRECTION = 'STREAMER_BALANCE_CORRECTION',

  //azarus transactions
  GAME_SESSION_AZARUS_DP = 'GAME_SESSION_AZARUS_DP',
  GAME_SESSION_AZARUS_TREASURY_DP = 'GAME_SESSION_AZARUS_TREASURY_DP',
}
