import {animate, style, transition, trigger} from '@angular/animations';

export const AZA_CORE_SHRINK_CURRENCY_AMOUNT_ANIMATION = trigger(
  'shrinkCurrencyAmount',
  [
    transition(':decrement', [
      style({width: '{{ rowWidthStart }}px'}),
      animate('150ms ease', style({width: '*'})),
    ]),
  ],
);
