import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {MobileMenuService} from '../../services/mobile-menu.service';

import {MobileMenuContentDirective} from './mobile-menu-content.directive';

@Component({
  selector: 'aza-mobile-menu-base',
  templateUrl: './mobile-menu-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('menuOverlayOpenClose', [
      state(
        'void',
        style({
          clipPath: 'var(--aza-mobile-menu-overlay-clip-path)',
        }),
      ),
      state(
        '*',
        style({
          clipPath: 'var(--aza-mobile-menu-overlay-open-clip-path)',
        }),
      ),
      transition('void <=> *', [animate('0.4s cubic-bezier(0.4, 0, 0.2, 1)')]),
    ]),
  ],
})
export class MobileMenuBaseComponent extends AzaCdkHsvsDirective {
  public readonly opened$ = this._mobileMenuService.opened$;

  @ContentChild(MobileMenuContentDirective, {read: TemplateRef})
  public readonly mobileContent!: TemplateRef<unknown>;

  public constructor(private readonly _mobileMenuService: MobileMenuService) {
    super('aza', 'mobileMenuBase');
  }

  public onMobileMenuTriggerClick(): void {
    this._mobileMenuService.toggle();
  }
}
