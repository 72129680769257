<button
  [class]="view('trigger')"
  data-cy="user-profile-menu-button"
  type="button"
  (click)="onTriggerClick()"
  #dropdownTrigger
>
  <ng-content></ng-content>

  <div>
    <aza-core-svg-icon
      [class]="view('chevron', {opened: opened})"
      [icon]="chevronDownIcon"
    ></aza-core-svg-icon>
  </div>
</button>

<aza-core-dropdown [reference]="dropdownTrigger" [(opened)]="opened">
  <div [class]="view('dropdown')">
    <nav [class]="view('dropdownNav')">
      <ng-container [ngTemplateOutlet]="navigationContent"></ng-container>

      <aza-vertical-menu-item
        data-cy="user-profile-menu-logout-item"
        snowplowAction="logout"
        snowplowCategory="auth"
        snowplowEvent
        [path]="logoutNavItem.path"
      >
        <aza-core-svg-icon [icon]="logoutNavItem.icon"></aza-core-svg-icon>
        {{ logoutNavItem.label }}
      </aza-vertical-menu-item>
    </nav>
  </div>
</aza-core-dropdown>
