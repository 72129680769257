import type {MonoTypeOperatorFunction} from 'rxjs';
import {ReplaySubject, share, timer} from 'rxjs';

export function retentionState<T>(
  timeout: number,
): MonoTypeOperatorFunction<T> {
  return share<T>({
    connector: () => new ReplaySubject<T>(1),
    resetOnRefCountZero: () => timer(timeout),
  });
}
