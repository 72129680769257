import {azaCorePayouts} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const WALLET_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.WALLET],
  label: $localize`Wallet`,
  icon: azaCorePayouts,
};
