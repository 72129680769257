import {AzaCdkHsvsPropertyDecorator} from './hsvs-property-decorator';
import {AzaCdkHsvsValue} from './hsvs-value';
import {AZA_CDK_METADATA_HSVS} from './metadata-hsvs-keys';

export function hsvs(cssName?: string): AzaCdkHsvsPropertyDecorator {
  return (prototype, key) => {
    let map: Map<string, AzaCdkHsvsValue> | undefined = Reflect.getOwnMetadata(
      AZA_CDK_METADATA_HSVS,
      prototype,
    );

    if (map === undefined) {
      const notOwnMetadata: ReadonlyMap<string, AzaCdkHsvsValue> | undefined =
        Reflect.getMetadata(AZA_CDK_METADATA_HSVS, prototype);

      map = new Map<string, AzaCdkHsvsValue>(notOwnMetadata ?? []);

      Reflect.defineMetadata(AZA_CDK_METADATA_HSVS, map, prototype);
    }

    if (map.has(key)) {
      throw new Error('There is already hsvs on this field');
    }

    map.set(key, cssName ?? key);
  };
}
