import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../../tokens';
import {ApiGameGameBaseRequest} from '../../../../types/game-base-request';
import {ApiGameRPSListPartyResponse} from '../types/list-party-response';
import {ApiGameRPSPartyUpdateRequest} from '../types/party-update-request';
import {ApiGameRPSSetHostAnswerRequest} from '../types/set-host-answer-request';

@Injectable({providedIn: 'root'})
export class ApiGameRPSService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getListParties({
    gameId,
    gameVariationId,
    userId,
  }: ApiGameGameBaseRequest): Observable<ApiGameRPSListPartyResponse> {
    return this._httpClient.get<ApiGameRPSListPartyResponse>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties`,
    );
  }

  public setHostAnswer({
    gameId,
    userId,
    gameVariationId,
    partyId,
    sessionId,
    answer,
  }: ApiGameRPSSetHostAnswerRequest): Observable<unknown> {
    return this._httpClient.post(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${partyId}/sessions/${sessionId}/answer`,
      {
        option: answer,
      },
    );
  }

  public updateParty({
    gameId,
    gameVariationId,
    partyId,
    userId,
    closingMessage,
  }: ApiGameRPSPartyUpdateRequest): Observable<unknown> {
    return this._httpClient.patch<unknown>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${partyId}`,
      {closingMessage},
    );
  }
}
