import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {GuardsCheckEnd, NavigationStart, Router} from '@angular/router';
import {TransferStateService} from '@scullyio/ng-lib';
import {
  BehaviorSubject,
  filter,
  first,
  map,
  NEVER,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import {classPredicate} from '@azarus/common/utils/class-predicate';

import {getCachableScullyUrl} from './get-cachable-scully-url';

// copied from scully
const initialStateDone = '__done__with__Initial__navigation__';

// copied from scully
interface State {
  [key: string]: any;
}

interface PatchedThis {
  initialUrl: string;
  nextUrl: Observable<string>;
  router: Router;
  stateBS: BehaviorSubject<State | undefined>;
}

// copied from scully
function basePathOnly(str: string): string {
  if (str.includes('#')) {
    str = str.split('#')[0];
  }

  if (str.includes('?')) {
    str = str.split('?')[0];
  }

  if (str.includes('(')) {
    str = str.split('(')[0];
  }

  const cleanedUpVersion = str.endsWith('/') ? str.slice(0, -1) : str;
  return cleanedUpVersion === '' ? '/' : cleanedUpVersion;
}

@Injectable()
export class AzaScullyPatchedTransferStateService extends TransferStateService {
  public constructor(
    @Inject(DOCUMENT) document: Document,
    router: Router,
    http: HttpClient,
  ) {
    super(document, router, http);

    const patchedThis = this as unknown as PatchedThis;

    // https://github.com/scullyio/scully/issues/1529
    patchedThis.nextUrl = patchedThis.router.events.pipe(
      filter(classPredicate(NavigationStart)),
      switchMap((start) => {
        if (basePathOnly(patchedThis.initialUrl) === basePathOnly(start.url)) {
          patchedThis.initialUrl = initialStateDone;
          return NEVER;
        }
        return of(start);
      }),
      tap(() => {
        patchedThis.stateBS.next(undefined);
      }),
      switchMap((start) =>
        patchedThis.router.events.pipe(
          filter(classPredicate(GuardsCheckEnd)),
          filter((event) => event.url === start.url),
          first(),
        ),
      ),
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      map((end) => basePathOnly(end.urlAfterRedirects || end.url)),
      shareReplay(1),
    );

    const readFromJson = 'readFromJson';
    this[readFromJson] = async (url: string): Promise<object> => {
      return fetch(
        getCachableScullyUrl(`${url.replace(/(^\/|\/$)/g, '')}/data.json`),
      ).then((response) => response.json());
    };
  }
}
