import {inject, InjectionToken} from '@angular/core';

import {SHOP_USER_PURCHASES_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../../tokens/rrpc-client';

export const API_GATEWAY_SHOP_USER_PURCHASES_METHOD = new InjectionToken(
  'API_GATEWAY_SHOP_USER_PURCHASES_METHOD',
  {
    providedIn: 'root',
    factory: () =>
      SHOP_USER_PURCHASES_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  },
);
