<div class="__aza-container-fluid__" [class]="view('bottomSection')">
  <div [class]="view('intro')">
    <aza-logo size="medium"></aza-logo>

    <p [class]="view('introTitle')" i18n>Watch, play, win</p>
  </div>

  <div [class]="view('bottomSectionNavContainer')">
    <div [class]="view('bottomSectionNav')">
      <div>
        <h3 [class]="view('columnTitle')" i18n>About Us</h3>

        <nav [class]="view('verticalNav')">
          <ng-container
            *ngFor="
              let item of aboutUsNavItems;
              template: footerExternalNavItemTemplate
            "
          ></ng-container>
        </nav>
      </div>

      <div>
        <h3 [class]="view('columnTitle')" i18n>Help</h3>

        <nav [class]="view('verticalNav')">
          <ng-container
            *ngFor="let item of helpNavItems; template: footerNavItemTemplate"
          ></ng-container>
        </nav>
      </div>

      <div>
        <h3 [class]="view('columnTitle')" i18n>Corporate</h3>

        <nav [class]="view('verticalNav')">
          <ng-container
            *ngFor="
              let item of corporateNavItems;
              template: footerNavItemTemplate
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              footerExternalNavItemTemplate;
              context: {$implicit: careerLinkItem}
            "
          ></ng-container>
        </nav>
      </div>
    </div>

    <div [class]="view('socialLinks')">
      <h3 [class]="view('columnTitle')" i18n>Follow us online</h3>

      <nav [class]="view('socialLinksNav')">
        <a
          *ngFor="let item of socialLinks"
          rel="noopener"
          target="_blank"
          [href]="item.url"
        >
          <img
            height="32"
            loading="lazy"
            width="32"
            [alt]="item.name"
            [src]="item.iconUrl"
          />
        </a>
      </nav>

      <div [class]="view('copyright')" i18n>
        Copyright 2018 – 2023 Azarus, Inc.
      </div>
    </div>
  </div>
</div>

<ng-template let-item #footerNavItemTemplate>
  <a *ngIf="item.show" [class]="view('navItem')" [routerLink]="item.path">
    {{ item.label }}
  </a>
</ng-template>

<ng-template let-item #footerExternalNavItemTemplate>
  <a
    [class]="view('careerLink')"
    rel="noopener"
    target="_blank"
    [href]="item.link"
  >
    <span i18n>{{ item.label }}</span>

    <span *ngIf="item.linkText" [class]="view('careerLinkExternal')">
      &nbsp;{{ item.linkText }}&nbsp;
    </span>

    <aza-core-svg-icon
      [class]="view('icon')"
      [icon]="externalLinkIcon"
    ></aza-core-svg-icon>
  </a>
</ng-template>
