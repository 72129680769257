import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {LastDistributedNextDto} from './last-distributed-next.dto';
import {LastDistributedParamsDto} from './last-distributed-params.dto';

export const LAST_DISTRIBUTED_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'lastDistributed'],
  LastDistributedParamsDto,
  asClass(LastDistributedNextDto),
);
