import {inject, InjectionToken, NgZone} from '@angular/core';

import {AzaCdkMessagingAngularClient} from '@azarus/frontend/cdk/messaging/messaging-angular-client';

import {API_BROWSER_EXTENSION_MESSAGING_CLIENT_ID} from './messaging-client-id';

export const API_BROWSER_EXTENSION_MESSAGING_CLIENT = new InjectionToken(
  'API_BROWSER_EXTENSION_MESSAGING_CLIENT',
  {
    providedIn: 'root',
    factory: () => {
      return new AzaCdkMessagingAngularClient(
        inject(NgZone),
        inject(API_BROWSER_EXTENSION_MESSAGING_CLIENT_ID),
      );
    },
  },
);
