import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {hsvs} from '@azarus/frontend/cdk/hsvs/hsvs';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Component({
  selector: 'aza-logo',
  template: `
    <div [class]="view('container')">
      <aza-core-logo-svg [class]="view('logo')"></aza-core-logo-svg>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent extends AzaCdkHsvsDirective {
  @Input() @hsvs() public size:
    | 'small'
    | 'medium'
    | 'large'
    | 'extra-large'
    | null = null;

  public constructor() {
    super('aza', 'logo');
  }
}
