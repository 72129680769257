import {azaCoreShop} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const SHOP_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.SHOP],
  label: $localize`Azarus Store`,
  icon: azaCoreShop,
};
