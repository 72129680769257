import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';

import {
  LatestPurchase,
  PurchaseResponse,
  PurchaseWithCoinbaseResponse,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';
import {readFileFromResponse} from '@azarus/frontend/cdk/util/read-file-from-response';

import {API_GATEWAY_HTTP_URL} from '../../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayPurchasesService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _url: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getPurchasesLatest(): Observable<readonly LatestPurchase[]> {
    return this._http.get<readonly LatestPurchase[]>(
      `${this._url}/purchases/latest`,
    );
  }

  public getPurchases(): Observable<readonly PurchaseResponse[]> {
    return this._http.get<readonly PurchaseResponse[]>(
      `${this._url}/purchases`,
    );
  }

  public purchaseProduct(productId: string): Observable<PurchaseResponse> {
    return this._http.post<PurchaseResponse>(
      `${this._url}/purchases/buy/${productId}`,
      {},
    );
  }

  public viewPurchase(productId: string): Observable<unknown> {
    return this._http.patch<unknown>(
      `${this._url}/purchases/view/${productId}`,
      {},
    );
  }

  public getPurchaseImageCode(purchaseId: string): Observable<File> {
    return this._http
      .get(`${this._url}/purchases/${purchaseId}/code-image`, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(map(readFileFromResponse));
  }

  public purchaseProductWithCoinbase(
    productId: string,
  ): Observable<PurchaseWithCoinbaseResponse> {
    return this._http.post<PurchaseWithCoinbaseResponse>(
      `${this._url}/purchases/buy/coinbase/${productId}`,
      {},
    );
  }

  public claimAtt(attJwt: string): Observable<PurchaseResponse> {
    return this._http.post<PurchaseResponse>(
      `${this._url}/purchases/claim/att`,
      {attJwt},
    );
  }
}
