import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Directive({})
export abstract class AzaCoreAnimatedCurrencyAmountBase
  extends AzaCdkHsvsDirective
  implements OnChanges, AfterViewChecked
{
  @Input() public amount = 0;

  public frontValue = this.amount;
  public backValue = this.amount;

  public currentWidth?: number;

  public constructor(public readonly _element: ElementRef<HTMLElement>) {
    super('aza', 'animatedCurrencyAmount');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['amount'] !== undefined) {
      this.backValue = this.amount;
    }
  }

  public ngAfterViewChecked(): void {
    this.currentWidth = this._element.nativeElement.offsetWidth;
  }

  public onAnimationEnd(): void {
    this.frontValue = this.amount;
  }
}
