import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {CurrentYoutubeGameSessionNextDto} from './current-youtube-game-session-next.dto';
import {CurrentYoutubeGameSessionParamsDto} from './current-youtube-game-session-params.dto';

export const CURRENT_YOUTUBE_GAME_SESSION_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'currentYoutubeGameSession'],
  CurrentYoutubeGameSessionParamsDto,
  asClass(CurrentYoutubeGameSessionNextDto),
);
