<ng-container *azaCoreLet="opened$ | async as opened">
  <button
    [class]="view('trigger')"
    aria-label="menu-trigger"
    i18n-aria-label
    type="button"
    (click)="onMobileMenuTriggerClick()"
  >
    <div [class]="view('triggerBars', {opened})"></div>
  </button>

  <div *ngIf="opened" @menuOverlayOpenClose [class]="view('overlay')">
    <div [class]="view('overlayContent')">
      <nav [class]="view('nav', {opened})">
        <a
          [class]="view('logoLink')"
          aria-label="Azarus home"
          i18n-aria-label
          routerLink="/"
        >
          <aza-logo size="medium"></aza-logo>
        </a>

        <ng-container [ngTemplateOutlet]="mobileContent"></ng-container>
      </nav>
    </div>
  </div>
</ng-container>
