import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {catchError, Observable, of} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../tokens/http-url';
import {ApiGameListGamesResponse} from '../types/list-games-response';

@Injectable({providedIn: 'root'})
export class ApiGameListGamesService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getGameList(userId: string): Observable<ApiGameListGamesResponse> {
    return this._httpClient
      .get<ApiGameListGamesResponse>(`${this._httpUrl}/users/${userId}/games`)
      .pipe(
        catchError((error) => {
          console.error(error);
          //TODO fix on back
          // Return an empty response or a default value as needed
          return of({items: [], nextToken: null});
        }),
      );
  }
}
