import {field} from '@azarus/common/transformer/class/field';
import {asDate} from '@azarus/common/transformer/date/as-date';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';

import {KycStatus} from '../kyc-status';

export class KycStatusNextDto {
  @field(asNullable(asString<KycStatus>()))
  public readonly status: KycStatus | null;

  @field(asNullable(asDate()))
  public readonly nextVerifyAt: Date | null;

  @field(asNullable(asDate()))
  public readonly approvedAt: Date | null;

  public constructor(
    status: KycStatusNextDto['status'],
    nextVerifyAt: KycStatusNextDto['nextVerifyAt'],
    approvedAt: KycStatusNextDto['approvedAt'],
  ) {
    this.status = status;
    this.nextVerifyAt = nextVerifyAt;
    this.approvedAt = approvedAt;
  }
}
