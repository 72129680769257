import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  GameApp,
  GameAppId,
  GameSession,
  LaunchGameAppBody,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayGameAppsService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _url: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getGameApps(): Observable<GameApp[]> {
    return this._http.get<GameApp[]>(`${this._url}/game-apps`);
  }

  public getGameApp(gameAppId: GameAppId): Observable<GameApp> {
    return this._http.get<GameApp>(`${this._url}/game-apps/${gameAppId}`);
  }

  public launchGameApp(
    gameAppId: GameAppId,
    body: LaunchGameAppBody,
  ): Observable<GameSession> {
    return this._http.post<GameSession>(
      `${this._url}/game-apps/${gameAppId}`,
      body,
    );
  }
}
