import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {LogoModule} from '../logo/logo.module';

import {FooterComponent} from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, AzaCoreSvgIconModule, LogoModule, RouterModule],
  exports: [FooterComponent],
})
export class FooterModule {}
