import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

// TODO specs
export class StringTransformer extends ValueTransformer<string> {
  public static readonly simple = new StringTransformer();

  private constructor() {
    super();
  }

  public dataToLiteral(data: string): unknown {
    return data;
  }

  public literalToData(literal: unknown, path: LiteralPath): string {
    if (typeof literal !== 'string') {
      throw new LiteralDeserializationError(literal, path);
    }

    return literal;
  }

  public isSupport(data: unknown): data is string {
    return typeof data === 'string';
  }
}
