import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {GameSessionStateNextDto} from './game-session-state-next.dto';
import {GameSessionStateParamsDto} from './game-session-state-params.dto';

export const GAME_SESSION_STATE_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'gameSessionState'],
  GameSessionStateParamsDto,
  asClass(GameSessionStateNextDto),
);
