import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreButtonBase} from './button.base';

@Component({
  selector:
    'button[aza-core-button],button[aza-core-fab],a[aza-core-button],a[aza-core-fab]',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzaCoreButtonComponent extends AzaCoreButtonBase {}
