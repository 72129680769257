import type {MonoTypeOperatorFunction, Observable} from 'rxjs';
import {dematerialize, materialize, startWith, switchMapTo} from 'rxjs';

export function refreshWhen<T>(
  signal$: Observable<unknown>,
): MonoTypeOperatorFunction<T> {
  return (source$) =>
    signal$.pipe(
      startWith(null),
      switchMapTo(source$.pipe(materialize())),
      dematerialize(),
    );
}
