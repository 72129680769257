export enum AppRouteSegment {
  ROOT = '',
  LOGIN = 'login',
  SHOP = 'store',
  NEWS = 'news',
  ABOUT_AZARUS = 'about-azarus',
  LOGOUT = 'logout',
  PAYMENT = 'payment',
  DIALOG_STORE_ITEM_PURCHASE = 'store-item-purchase',
  DIALOG_PURCHASE_WITH_COINBASE_SUCCESS = 'crypto-purchase-success',
  ACTIVE_STREAMS = 'active-streams',
  FEED = 'feed',
  LOGIN_CALLBACK = 'login-callback',
  DASHBOARD_PLACEHOLDER = 'dashboard-placeholder',
  STREAMER = 'streamer',
  GAMES = 'games',
  SETUP = 'setup',
  STREAMER_DASHBOARD = 'streamer-dashboard',
  CONTACTS = 'contacts',
  FAQ = 'faq',
  PURCHASES = 'purchases',
  DATA_COLLECTION_PERMISSIONS = 'data-collection-permissions',
  WALLET = 'wallet',
  TRANSACTIONS = 'transactions',
  AZA_COIN = 'azacoin',
  ADVERTISE = 'advertise',
  GET_MORE_ENTRIES = 'get-more-entries',
  CONNECT_MOCA_ID = 'connect-moca-id',
  CONSOLE = 'console',
  RPC_GAME = 'rock-paper-scissors-game',
  TRIVIA_GAME = 'trivia-game',
}
