import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaCdkExtensionEventRef} from '@azarus/frontend/cdk/extension-event/extension-event-ref';

import {ApiBrowserExtensionVideoVolumeChangeDto} from './video-volume-change-event.dto';

export const API_BROWSER_EXTENSION_VIDEO_VOLUME_CHANGE_EVENT_REF =
  new AzaCdkExtensionEventRef(
    'videoVolumeChangeEvent',
    asClass(ApiBrowserExtensionVideoVolumeChangeDto),
  );
