import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionNotifyGameStateChangeParamsDto} from './notify-game-state-change-params.dto';

export const API_BROWSER_EXTENSION_NOTIFY_GAME_STATE_CHANGE_REF =
  new AzaCdkMessagingRef(
    'notifyGameStateChange',
    ApiBrowserExtensionNotifyGameStateChangeParamsDto,
    asNever(),
  );
