import {inject} from '@angular/core';
import {CanMatchFn, Router} from '@angular/router';
import {map, take} from 'rxjs';

import {AppRouteSegment} from '../app-route-segment.enum';
import {LoginPageQueryParam} from '../pages/login-page/login-page-query-param';
import {SignupStateService} from '../services/signup-state.service';

export const canMatchWithSignupComplete: CanMatchFn = (_route, urlSegments) => {
  const signupStateService = inject(SignupStateService);
  const router = inject(Router);
  let queryParams = {};
  const search = new URLSearchParams(location.search);
  for (let s of search) {
    queryParams = {...queryParams, [s[0]]: s[1]};
  }

  const returnPath = router.serializeUrl(
    router.createUrlTree(
      urlSegments.map((urlSegment) => urlSegment.path),
      {queryParams},
    ),
  );
  return signupStateService.signupComplete$.pipe(
    take(1),
    map((signupComplete) => {
      if (!signupComplete) {
        return router.createUrlTree(
          [AppRouteSegment.ROOT, AppRouteSegment.LOGIN],
          {
            queryParams: {
              [LoginPageQueryParam.RETURN_PATH]: returnPath,
            },
          },
        );
      }
      return true;
    }),
  );
};
