import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {LastWinnersNextDto} from './last-winners-next.dto';
import {LastWinnersParamsDto} from './last-winners-params.dto';

export const LAST_WINNERS_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'lastWinners'],
  LastWinnersParamsDto,
  asClass(LastWinnersNextDto),
);
