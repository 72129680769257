export enum RrpcServerCode {
  // 3000-3099: error

  INTERNAL_UNKNOWN_ERROR = 3000,

  UNSUPPORTED_MESSAGE_TYPE = 3001,

  INVALID_JSON = 3002,

  INVALID_FRAME_SIGNATURE = 3003,

  UNSUPPORTED_FRAME_TYPE = 3004,

  INVALID_ID = 3005,

  ID_COLLISION = 3006,

  INVALID_METHOD_SIGNATURE = 3007,
}
