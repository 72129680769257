/**
 * Truncate number to specified amount of digits after floating point
 *
 * @param number - number to truncate
 * @param digitsAmount - number of digits after floating point
 * @param expandToNonZero - flag that specifies whether it should try to return
 * a non-zero number even if it is smaller than the smallest number
 * with the specified number of digits after the floating point
 */
export function toFixedNumber(number: number, digitsAmount: number): number {
  const minDigits = 0;
  const maxDigits = 10;

  if (digitsAmount < minDigits || digitsAmount > maxDigits) {
    throw new Error(
      `toFixedNumber digits argument must be between ${minDigits} and ${maxDigits}`,
    );
  }

  if (number === 0) {
    return number;
  }

  const stringifiedNumber = number.toString();

  const [intPart, floatingPart] = stringifiedNumber.split('.');
  if (!/^-?\d+$/.test(intPart)) {
    throw new Error('only floating number are supported');
  }
  if (floatingPart === undefined) {
    return parseInt(intPart, 10);
  }
  const floatingPartToReturn = floatingPart.substring(0, digitsAmount);
  const floatToReturn = parseFloat(`${intPart}.${floatingPartToReturn}`);
  return floatToReturn;
}
