import {inject, InjectionToken} from '@angular/core';

import {BALANCE_MANAGEMENT_CURRENT_CUSTOM_TAG_BALANCE_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_CURRENT_CUSTOM_TAG_BALANCE_METHOD = new InjectionToken(
  'API_GATEWAY_CURRENT_CUSTOM_TAG_BALANCE_METHOD',
  {
    providedIn: 'root',
    factory: () =>
      BALANCE_MANAGEMENT_CURRENT_CUSTOM_TAG_BALANCE_REF.connect(
        inject(API_GATEWAY_RRPC_CLIENT),
      ),
  },
);
