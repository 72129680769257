import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {AzaCoreSvgIconContract} from './svg-icon-contract';

@Directive()
export abstract class AzaCoreSvgIconBase extends AzaCdkHsvsDirective {
  private _icon: AzaCoreSvgIconContract | null = null;
  public get icon(): AzaCoreSvgIconContract | null {
    return this._icon;
  }
  @Input() public set icon(icon: AzaCoreSvgIconContract | null) {
    if (icon === null) {
      return;
    }

    this._icon = icon;
    this._element.nativeElement.innerHTML = icon.data;
  }

  @HostBinding('attr.role') public readonly role = 'img';

  @HostBinding('attr.aria-label') public get iconLabel(): string | null {
    if (this.icon === null) {
      return null;
    }
    return `${this.icon.name}-icon`;
  }

  public constructor(private readonly _element: ElementRef<HTMLElement>) {
    super('aza', 'svgIcon');
  }
}
