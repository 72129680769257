import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Inject, inject, NgModule, NgZone} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationEnd, Router} from '@angular/router';
import {isScullyRunning} from '@scullyio/ng-lib';
import {
  GoogleTagManagerModule,
  GoogleTagManagerService,
} from 'angular-google-tag-manager';
import isMobile from 'ismobilejs';
import jwtDecode from 'jwt-decode';
import {distinctUntilChanged, filter, map, Observable} from 'rxjs';

import {AccessToken, AccessTokenPayload} from '@azarus/api-contract';
import {
  API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT,
  API_BROWSER_EXTENSION_DELETE_USER_METHOD,
  API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_METHOD,
  API_BROWSER_EXTENSION_MESSAGING_CLIENT_ID,
  API_BROWSER_EXTENSION_SIGN_OUT_METHOD,
} from '@azarus/frontend/api/browser-extension';
import {API_GAME_HTTP_URL, API_GAME_WS_URL} from '@azarus/frontend/api/game';
import {
  API_GATEWAY_HTTP_URL,
  API_GATEWAY_WS_URL,
  ApiGatewayAuthService,
  ApiGatewayTokenRefreshService,
  ApiGatewayUserService,
} from '@azarus/frontend/api/gateway';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';
import {AzaCdkSentryModule} from '@azarus/frontend/cdk/sentry/sentry.module';
import {AzaCoreBadgeModule} from '@azarus/frontend/core/modules/badge/badge.module';
import {AzaCoreButtonModule} from '@azarus/frontend/core/modules/button/button.module';
import {AzaCoreLetModule} from '@azarus/frontend/core/modules/let/let.module';
import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';
import {ATT_USER_TOKEN_PARAM_NAME} from '@azarus/frontend/custom-overlay-cdk';
import {AzaScullyPatchedScullyModule} from '@azarus/frontend/scully/patched-scully/patched-scully.module';
import {AZA_SCULLY_AZARUS_SNOWPLOW_NAME} from '@azarus/frontend/scully/snowplow/azarus-snowplow-name';
import {
  SNOWPLOW_DONT_TRACK_CLASSNAME,
  SnowplowModule,
} from '@azarus/frontend/snowplow';

import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AsyncAuthService} from './auth/async-auth.service';
import {ExtensionAsyncAuthService} from './auth/extension-async-auth.service';
import {LocalAsyncAuthService} from './auth/local-async-auth.service';
import {AuthInterceptorService} from './interceptors/auth.interceptor.service';
import {AppAttModule} from './modules/att/att.module';
import {ContactUsPopupModule} from './modules/contact-us-popup/contact-us-popup.module';
import {CurrencyDisplayModule} from './modules/currency-display/currency-display.module';
import {DiscordButtonModule} from './modules/discord-button/discord-button.module';
import {FooterModule} from './modules/footer/footer.module';
import {GoogleIdentityServiceModule} from './modules/google-identity-service/google-identity-service.module';
import {GreetingPopupModule} from './modules/greeting-popup/greeting-popup.module';
import {GreetingPopupNovemberModule} from './modules/greeting-popup-november/greeting-popup-november.module';
import {HeaderModule} from './modules/header/header.module';
import {MobileMenuModule} from './modules/mobile-menu/mobile-menu.module';
import {MocaWidgetModule} from './modules/moca-widget/moca-widget.module';
import {ProfileMenuModule} from './modules/profile-menu/profile-menu.module';
import {PurchaseErrorDialogModule} from './modules/purchase-error-dialog/purchase-error-dialog.module';
import {SnowplowEventDirective} from './modules/snowplow/snowplow-event.directive';
import {SnowplowEventModule} from './modules/snowplow/snowplow-event.module';
import {UserModule} from './modules/user/user.module';
import {UserCornerModule} from './modules/user-corner/user-corner.module';
import {VerticalMenuItemModule} from './modules/vertical-menu-item/vertical-menu-item.module';
import {VerticalMenuItemExternalModule} from './modules/vertical-menu-item-external/vertical-menu-item-external.module';
import {ExtensionDetectorService} from './services/extension-detector.service';
import {BALANCE} from './tokens/balance';
import {SNOWPLOW_SERVICE} from './tokens/snowplow-service';
import {USER_BALANCE} from './tokens/user-balance';
import {LandingComponent} from './top-level-components/landing/landing.component';
import {LandingHeaderComponent} from './top-level-components/landing-header/landing-header.component';
import {LandingWrapperComponent} from './top-level-components/landing-wrapper/landing-wrapper.component';

const spAppId =
  environment.production && !isScullyRunning()
    ? 'AZA_CORPORATE_WEB'
    : '__ignore__';

const authServiceFactory = (): AsyncAuthService => {
  const extensionDetector = inject(ExtensionDetectorService);
  const router = inject(Router);

  return extensionDetector.isInstalled()
    ? new ExtensionAsyncAuthService(
        extensionDetector,
        inject(API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_METHOD),
        inject(API_BROWSER_EXTENSION_SIGN_OUT_METHOD),
        inject(API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT),
        inject(API_BROWSER_EXTENSION_DELETE_USER_METHOD),
        router,
      )
    : new LocalAsyncAuthService(
        inject(ApiGatewayAuthService),
        inject(ApiGatewayTokenRefreshService),
        inject(ApiGatewayUserService),
        router,
      );
};

@NgModule({
  imports: [
    AzaCdkSentryModule.forRoot(environment.sentryEnabled),
    AzaScullyPatchedScullyModule.forRoot(),
    AppRoutingModule,
    AzaCoreBadgeModule,
    CurrencyDisplayModule,
    VerticalMenuItemModule,
    PurchaseErrorDialogModule,
    AzaCoreSvgIconModule,
    AzaCoreButtonModule,
    BrowserAnimationsModule,
    BrowserModule,
    SnowplowModule.forRoot(
      AZA_SCULLY_AZARUS_SNOWPLOW_NAME,
      'collector.azarus.io',
      {
        appId: spAppId,
        cookieDomain: null,
        discoverRootDomain: true,
        cookieSameSite: 'Lax',
        eventMethod: 'get',
        platform: isMobile(window.navigator).any ? 'mobile' : 'web',
        contexts: {
          webPage: true,
          performanceTiming: true,
          gaCookies: false,
          geolocation: false,
        },
        stateStorageStrategy: 'localStorage',
      },
    ),
    SnowplowEventModule,
    FormsModule,
    CommonModule,
    AzaCoreLetModule,
    FooterModule,
    HeaderModule,
    VerticalMenuItemExternalModule,
    ProfileMenuModule,
    MobileMenuModule,
    UserCornerModule,
    UserModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      gtm_preview: environment.googleTagManagerPreview,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      gtm_auth: environment.googleTagManagerAuth,
    }),
    GreetingPopupModule,
    GreetingPopupNovemberModule,
    ContactUsPopupModule,
    MocaWidgetModule,
    GoogleIdentityServiceModule.forRoot(),
    DiscordButtonModule,
    AppAttModule.forRoot(ATT_USER_TOKEN_PARAM_NAME, 60 * 60 * 12),
  ],
  declarations: [
    AppComponent,
    LandingComponent,
    LandingHeaderComponent,
    LandingWrapperComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: API_GATEWAY_HTTP_URL, useValue: environment.apiGatewayHttpUrl},
    {provide: API_GATEWAY_WS_URL, useValue: environment.rrpcGatewayWsUrl},
    {provide: API_GAME_HTTP_URL, useValue: environment.apiGameHttpUrl},
    {provide: API_GAME_WS_URL, useValue: environment.apiGameWsUrl},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: AsyncAuthService,
      useFactory: authServiceFactory,
    },
    {
      provide: API_BROWSER_EXTENSION_MESSAGING_CLIENT_ID,
      useValue: 'AZARUS_WEB_SITE',
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {maxWidth: 'var(--aza-container-responsive-width)'},
    },
    {provide: BALANCE, useExisting: USER_BALANCE},
  ],
  exports: [SnowplowEventDirective],
})
export class AppModule {
  public constructor(
    @Inject(SNOWPLOW_SERVICE)
    snowplowService: AzaCdkInjectionTokenType<typeof SNOWPLOW_SERVICE>,
    asyncAuthService: AsyncAuthService,
    router: Router,
    gtmService: GoogleTagManagerService,
    zone: NgZone,
  ) {
    if (!isScullyRunning()) {
      zone.runOutsideAngular(() => {
        void gtmService.addGtmToDom();
      });
    }
    asyncAuthService
      .getToken()
      .pipe(filter((token): token is AccessToken => token !== null))
      .subscribe((token) => {
        // FIXME: need to reactively provide updated user when he's authorized
        // this logic is incorrect
        const {userId} = jwtDecode<AccessTokenPayload>(token);
        snowplowService.setUserId(userId);
      });
    const siteOrigin: string = location.origin;
    snowplowService.enableLinkClickTracking({
      filter: (linkElement: HTMLLinkElement) => {
        // track only external links
        return (
          new URL(linkElement.href).origin !== siteOrigin &&
          !linkElement.classList.contains(SNOWPLOW_DONT_TRACK_CLASSNAME)
        );
      },
    });

    const navigationEnd$: Observable<string> = router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(({urlAfterRedirects}) => urlAfterRedirects),
      distinctUntilChanged(),
    );

    // link click event listener refresh
    navigationEnd$.subscribe(() => {
      // prevent messing with rendering
      zone.runOutsideAngular(() => {
        setTimeout(() => {
          snowplowService.refreshLinkClickTracking();
        });
      });
    });

    // page tracking
    navigationEnd$.subscribe((urlAfterRedirects) => {
      // prevent messing with rendering
      zone.runOutsideAngular(() => {
        setTimeout(() => {
          snowplowService.trackPageView(urlAfterRedirects);
        });
      });
    });

    snowplowService.trackStructEvent({category: 'app', action: 'track_init'});
  }
}
