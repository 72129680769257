import {field} from '@azarus/common/transformer/class/field';
import {asNumber} from '@azarus/common/transformer/number/as-number';

export class BalanceManagementCurrentBalanceNextDto {
  @field(asNumber())
  public readonly balance: number;

  public constructor(
    balance: BalanceManagementCurrentBalanceNextDto['balance'],
  ) {
    this.balance = balance;
  }
}
