import {field} from '@azarus/common/transformer/class/field';
import {asNumber} from '@azarus/common/transformer/number/as-number';

export class ApiBrowserExtensionVideoVolumeChangeDto {
  @field(asNumber()) public readonly volume: number;

  public constructor(
    volume: ApiBrowserExtensionVideoVolumeChangeDto['volume'],
  ) {
    this.volume = volume;
  }
}
