import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {isScullyRunning} from '@scullyio/ng-lib';
import {first, of, switchMap} from 'rxjs';

import {User} from '@azarus/api-contract';

import {AsyncAuthService} from '../auth/async-auth.service';
import {UserInfoActionService} from '../services/user-info-action.service';

export const resolveOptionalUserInfo: ResolveFn<User | null> = () => {
  if (isScullyRunning()) {
    return of(null);
  }
  const authService = inject(AsyncAuthService);
  const userInfoActionService = inject(UserInfoActionService);

  return authService.isAuthorized$.pipe(
    switchMap((isAuthorized) =>
      isAuthorized ? userInfoActionService.info$ : of(null),
    ),
    first(),
  );
};
