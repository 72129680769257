import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AccessToken, TokenCreds} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {
  API_GATEWAY_ACCESS_TOKE_REFRESH_SAFETY_FRAME_MS,
  API_GATEWAY_TOKEN_REFRESH_HELPER,
} from '../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayTokenRefreshService {
  public constructor(
    @Inject(API_GATEWAY_ACCESS_TOKE_REFRESH_SAFETY_FRAME_MS)
    private readonly _refreshSafetyFrame: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_ACCESS_TOKE_REFRESH_SAFETY_FRAME_MS
    >,
    @Inject(API_GATEWAY_TOKEN_REFRESH_HELPER)
    private readonly _refreshHelper: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_TOKEN_REFRESH_HELPER
    >,
  ) {}

  public getFreshToken(
    credentials: TokenCreds,
    onRefreshTokenExpiry: () => Observable<AccessToken | null>,
    refreshAccessToken: () => Observable<AccessToken | null>,
  ): Observable<AccessToken | null> {
    return this._refreshHelper.getFreshToken(
      credentials,
      onRefreshTokenExpiry,
      refreshAccessToken,
      this._refreshSafetyFrame,
    );
  }
}
