import {asNumber} from '@azarus/common/transformer/number/as-number';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionGetVideoVolumeParamsDto} from './get-video-volume-params.dto';

export const API_BROWSER_EXTENSION_GET_VIDEO_VOLUME_REF =
  new AzaCdkMessagingRef(
    'getVideoVolume',
    ApiBrowserExtensionGetVideoVolumeParamsDto,
    asNumber(),
  );
