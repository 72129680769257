<svg
  [class]="view('svg')"
  fill="none"
  viewBox="0 0 199 42"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M57.11 8.35 48 32.65h4.19L59 13.2h.1l6.73 19.44h4.21l-9.01-24.3h-3.92Zm35.53 2.44V8.35H75.3v3.25h12.35L75.19 30.1v2.55h17.87v-3.26H80.12l12.52-18.6Zm14.64-2.44-9.11 24.3h4.19l6.81-19.44h.1L116 32.65h4.2l-9-24.3h-3.92Zm60.58 16.28c0 1.7-.51 2.97-1.52 3.83a6.08 6.08 0 0 1-4.07 1.28 5.75 5.75 0 0 1-3.94-1.28c-.97-.85-1.46-2.13-1.46-3.83V8.35h-4.14v16.29c0 2.68.88 4.74 2.64 6.19 1.76 1.44 4.06 2.17 6.9 2.17 2.9 0 5.24-.72 7.03-2.16 1.8-1.45 2.7-3.51 2.7-6.2V8.35h-4.14v16.28Zm28.06-2.98c-1.38-1.23-3.41-2.2-6.08-2.9a14.81 14.81 0 0 1-4.37-1.73c-.88-.61-1.32-1.38-1.32-2.3 0-1.02.4-1.85 1.22-2.5.81-.64 1.97-.96 3.48-.96 1.6 0 2.84.38 3.7 1.15.86.78 1.3 1.79 1.3 3.03h3.96l.04-.1a6.4 6.4 0 0 0-2.4-5.18C193.8 8.72 191.61 8 188.9 8c-2.61 0-4.75.63-6.4 1.9a5.86 5.86 0 0 0-2.47 4.84c0 1.9.74 3.44 2.23 4.62 1.5 1.17 3.63 2.11 6.4 2.83 1.94.5 3.3 1.08 4.06 1.72a3.08 3.08 0 0 1 1.15 2.48c0 1.03-.42 1.85-1.28 2.45-.85.6-2.04.9-3.57.9a6.98 6.98 0 0 1-4.07-1.09c-1.05-.72-1.57-1.83-1.57-3.31h-3.99l-.03.1a6.18 6.18 0 0 0 2.84 5.53 11.68 11.68 0 0 0 6.82 2.03c2.69 0 4.86-.6 6.51-1.8a5.66 5.66 0 0 0 2.48-4.82c0-1.92-.7-3.5-2.08-4.73Zm-51.19 9.06a8.06 8.06 0 0 1-.27-2.08V26.6c0-1.44-.3-2.65-.92-3.63a5.18 5.18 0 0 0-3.02-2.13 6.83 6.83 0 0 0 2.9-2.18c.67-.9 1-1.99 1-3.24 0-2.27-.75-4.02-2.26-5.24-1.51-1.23-3.65-1.84-6.42-1.84h-9.17v24.3h4.15V22.57h5.51c1.27 0 2.27.37 3 1.1a4.03 4.03 0 0 1 1.09 2.97v1.95c0 .76.06 1.53.2 2.32.12.8.42 1.37.87 1.74h4.26v-.39a2.91 2.91 0 0 1-.92-1.55Zm-5.58-12.33c-.77.62-1.97.93-3.63.93h-4.8v-7.7h5.02c1.53 0 2.67.34 3.42 1.04s1.13 1.65 1.13 2.88c0 1.28-.38 2.23-1.14 2.85Z"
    fill="currentColor"
  />
  <path
    d="M40.63 20.96a19.67 19.67 0 1 1-39.34 0 19.67 19.67 0 0 1 39.34 0Z"
    fill="#fff"
  />
  <path
    clip-rule="evenodd"
    fill-rule="evenodd"
    d="M21 0a21 21 0 1 0 0 42 21 21 0 0 0 0-42Zm11.8 35.1a2.9 2.9 0 0 1-4.08-4.07l-6.27-7.49a2.88 2.88 0 0 1-2.89 0l-6.27 7.49a2.9 2.9 0 1 1-.8-.67l6.28-7.48a2.88 2.88 0 0 1 1.71-4.69V8.41a2.9 2.9 0 1 1 1.04 0v9.78a2.9 2.9 0 0 1 1.72 4.68l6.28 7.49a2.9 2.9 0 0 1 3.29 4.73Z"
    fill="#EF3D57"
  />
</svg>
