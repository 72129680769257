import {azaCoreMegaphone} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const ADVERTISE_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.ADVERTISE],
  label: $localize`For Advertisers`,
  icon: azaCoreMegaphone,
};
