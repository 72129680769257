import {inject, InjectionToken} from '@angular/core';

import {API_BROWSER_EXTENSION_EVENT_CLIENT} from '../../tokens';

import {API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT_REF} from './auth-state-change-event-ref';

export const API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT = new InjectionToken(
  'API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT',
  {
    providedIn: 'root',
    factory: () =>
      API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT_REF.connect(
        inject(API_BROWSER_EXTENSION_EVENT_CLIENT),
      ),
  },
);
