import {InjectionToken} from '@angular/core';

import {ApiGatewayTokenRefreshHelper} from '../helpers';

export const API_GATEWAY_TOKEN_REFRESH_HELPER = new InjectionToken(
  'API_GATEWAY_TOKEN_REFRESH_HELPER',
  {
    providedIn: 'root',
    factory: () => new ApiGatewayTokenRefreshHelper(),
  },
);
