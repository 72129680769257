import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  CreatePurchasePayPalOrderBody,
  CreatePurchasePayPalOrderResponse,
  PayPalOrder,
  ProductPriceRemainingResponse,
  PurchaseResponse,
} from '@azarus/api-contract';

import {API_GATEWAY_HTTP_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayPaymentsService {
  private readonly _apiGatewayHttpUrl = inject(API_GATEWAY_HTTP_URL);

  private readonly _http = inject(HttpClient);

  public createOrder(
    data: CreatePurchasePayPalOrderBody,
  ): Observable<CreatePurchasePayPalOrderResponse> {
    return this._http.post<CreatePurchasePayPalOrderResponse>(
      `${this._apiGatewayHttpUrl}/purchases/paypal/order`,
      data,
    );
  }

  public getOrder(orderId: string): Observable<PayPalOrder> {
    return this._http.get<PayPalOrder>(
      `${this._apiGatewayHttpUrl}/paypal/orders/${orderId}`,
    );
  }

  public purchasesBuy(
    productId: string,
    data: CreatePurchasePayPalOrderResponse,
  ): Observable<PurchaseResponse> {
    return this._http.post<PurchaseResponse>(
      `${this._apiGatewayHttpUrl}/purchases/buy/${productId}`,
      data,
    );
  }

  public getPriceRemaining(
    productId: string,
  ): Observable<ProductPriceRemainingResponse> {
    return this._http.get<ProductPriceRemainingResponse>(
      `${this._apiGatewayHttpUrl}/products/${productId}/price-remaining`,
    );
  }
}
