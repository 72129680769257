import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {AZA_CORE_DISCORD_URL} from '@azarus/frontend/core/const/discord-url';
import {AZA_CORE_LIGHT_PAPER_URL} from '@azarus/frontend/core/const/light-paper-url';
import {AZA_CORE_PRIVACY_POLICY_URL} from '@azarus/frontend/core/const/privacy-policy-url';
import {AZA_CORE_TOS_URL} from '@azarus/frontend/core/const/tos-url';
import {azaCoreExternalLink} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';

interface FooterNavItem {
  path: string[];
  label: string;
  show: boolean;
}

interface SocialLink {
  readonly url: string;
  readonly iconUrl: string;
  readonly name: string;
}

@Component({
  selector: 'aza-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent extends AzaCdkHsvsDirective {
  public readonly externalLinkIcon = azaCoreExternalLink;

  public readonly aboutUsNavItems = [
    {
      link: AZA_CORE_LIGHT_PAPER_URL,
      label: $localize`Lite Paper`,
    },
    {
      link: AZA_CORE_TOS_URL,
      label: $localize`TOS`,
    },
    {
      link: AZA_CORE_PRIVACY_POLICY_URL,
      label: $localize`Privacy Policy`,
    },
  ];

  public readonly helpNavItems: readonly FooterNavItem[] = [
    {
      path: [AppRouteSegment.ROOT, AppRouteSegment.FAQ],
      label: $localize`FAQ`,
      show: true,
    },
    {
      path: [AppRouteSegment.ROOT, AppRouteSegment.CONTACTS],
      label: $localize`Contacts`,
      show: true,
    },
  ];

  public readonly corporateNavItems: readonly FooterNavItem[] = [
    {
      path: [AppRouteSegment.ROOT, AppRouteSegment.NEWS],
      label: $localize`News`,
      show: true,
    },
    {
      path: [AppRouteSegment.ROOT, AppRouteSegment.ABOUT_AZARUS],
      label: $localize`About Azarus`,
      show: true,
    },
  ];

  public readonly socialLinks: readonly SocialLink[] = [
    {
      url: 'https://twitter.com/azarusio',
      iconUrl: 'assets/img/social-icons/twitter-x.svg',
      name: 'Twitter',
    },
    {
      url: AZA_CORE_DISCORD_URL,
      iconUrl: 'assets/img/social-icons/discord.svg',
      name: 'Discord',
    },
    {
      url: 'https://www.facebook.com/azarusio/',
      iconUrl: 'assets/img/social-icons/facebook.svg',
      name: 'Facebook',
    },
  ];

  public readonly careerLink = 'https://angel.co/company/azarus/jobs';
  public readonly careerLinkItem = {
    link: this.careerLink,
    label: 'Careers:',
    linkText: this.careerLink,
  };
  public readonly discordLink = AZA_CORE_DISCORD_URL;

  public constructor() {
    super('aza', 'footer');
  }
}
