import {isUnknownEntry} from '../../utils/is-unknown-entry';
import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

import {AsOneOfVariantsArray} from './as-one-of-variants-array';
import {CollisionOneOfSerializationError} from './collision-one-of-serialization-error';
import {UnsupportedOneOfSerializationError} from './unsupported-one-of-serialization-error';

function isValidVariant(value: unknown, length: number): value is number {
  return (
    typeof value === 'number' &&
    Number.isInteger(value) &&
    value >= 0 &&
    value < length
  );
}

// TODO specs
export class OneOfTransformer<
  V extends readonly unknown[],
> extends ValueTransformer<V[number]> {
  public constructor(private readonly _variants: AsOneOfVariantsArray<V>) {
    super();
  }

  public dataToLiteral(data: V[number]): unknown {
    let transformer: ValueTransformer<V[number]> | null = null;
    let index = -1;

    for (const [variantIndex, variantTransformer] of this._variants.entries()) {
      if (!variantTransformer.isSupport(data)) {
        continue;
      }

      if (transformer !== null) {
        throw new CollisionOneOfSerializationError(data);
      }

      transformer = variantTransformer;
      index = variantIndex;
    }

    if (transformer === null) {
      throw new UnsupportedOneOfSerializationError(data);
    }

    return [index, transformer.dataToLiteral(data)];
  }

  public literalToData(literal: unknown, path: LiteralPath): V[number] {
    if (!isUnknownEntry(literal)) {
      throw new LiteralDeserializationError(literal, path);
    }

    const [variant, value] = literal;

    if (!isValidVariant(variant, this._variants.length)) {
      throw new LiteralDeserializationError(literal, [path, 0]);
    }

    return this._variants[variant].literalToData(value, [path, 1]);
  }

  public isSupport(data: unknown): data is V[number] {
    return this._variants.some((variant) => variant.isSupport(data));
  }
}
