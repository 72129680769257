import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

export class ApiBrowserExtensionSignInParamsDto {
  @field(asString())
  public readonly email: string;

  @field(asString())
  public readonly password: string;

  public constructor(
    email: ApiBrowserExtensionSignInParamsDto['email'],
    password: ApiBrowserExtensionSignInParamsDto['password'],
  ) {
    this.email = email;
    this.password = password;
  }
}
