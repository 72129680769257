import {NgZone} from '@angular/core';
import {MonoTypeOperatorFunction, Observable} from 'rxjs';

export function insideZone<T>(zone: NgZone): MonoTypeOperatorFunction<T> {
  return (source) =>
    new Observable<T>((observer) =>
      source.subscribe({
        next: (value) => {
          zone.run(() => {
            observer.next(value);
          });
        },
        error: (error) => {
          zone.run(() => {
            observer.error(error);
          });
        },
        complete: () => {
          zone.run(() => {
            observer.complete();
          });
        },
      }),
    );
}
