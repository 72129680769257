import {NgZone} from '@angular/core';
import {Observable} from 'rxjs';

import {insideZone} from '../util/inside-zone';

import {AzaCdkExtensionEvent} from './extension-event';
import {AzaCdkExtensionEventClient} from './extension-event-client';

export class AzaCdkExtensionEventAngularClient extends AzaCdkExtensionEventClient {
  public constructor(
    private readonly _zone: NgZone,
    protected override readonly _window: Window = window,
    protected override readonly _eventOrigin: string = window.origin,
  ) {
    super(_window, _eventOrigin);
  }

  public override addListener(
    name: string,
  ): Observable<AzaCdkExtensionEvent<unknown>> {
    return super.addListener(name).pipe(insideZone(this._zone));
  }
}
