import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Streamer} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayStreamerService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getStreamer(): Observable<Streamer> {
    return this._http.get<Streamer>(`${this._httpUrl}/streamer`);
  }
}
