export enum RrpcServerFrameType {
  PING = 'p',

  NEXT = 'n',

  COMPLETE = 'c',

  ERROR = 'e',

  DRAINING = 'd',
}
