import {Directive} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Directive()
export abstract class AzaCoreLogoSvgBase extends AzaCdkHsvsDirective {
  public constructor() {
    super('aza', 'logoSvg');
  }
}
