import {inject, InjectionToken} from '@angular/core';

import {RrpcClient} from '@azarus/common/rrpc-client/rrpc-client';

import {API_GATEWAY_WS_URL} from './ws-url';

export const API_GATEWAY_RRPC_CLIENT = new InjectionToken<RrpcClient>(
  'API_GATEWAY_RRPC_CLIENT',
  {
    providedIn: 'root',
    factory: () => {
      const url = inject(API_GATEWAY_WS_URL);

      // TODO remove magic numbers
      return new RrpcClient(60000, () => new WebSocket(url));
    },
  },
);
