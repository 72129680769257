import {
  animate,
  animation,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

const flipAnimation = animation([
  style({
    transform: 'rotateX(0deg)',
  }),
  animate(
    '500ms 150ms cubic-bezier(0.76, 0.36, 0.68, 1.42)',
    style({transform: 'rotateX(180deg)'}),
  ),
]);

export const AZA_CORE_FLIP_CURRENCY_AMOUNT_ANIMATION = trigger(
  'flipCurrencyAmount',
  [
    transition(':increment', useAnimation(flipAnimation)),
    transition(':decrement', useAnimation(flipAnimation)),
  ],
);
