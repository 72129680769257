import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import {isScullyRunning} from '@scullyio/ng-lib';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {azaCoreExternalLink, azaCoreLogin} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {AsyncAuthService} from '../../auth/async-auth.service';
import {ADVERTISE_NAV_ITEM} from '../constants/advertise-nav-item';
import {AZA_COIN_NAV_ITEM} from '../constants/aza-coin-nav-item';
import {DATA_COLLECTION_PERMISSIONS_NAV_ITEM} from '../constants/data-collection-permissions-nav-item';
import {GET_MORE_ENTRIES_NAV_ITEM} from '../constants/get-more-entries-nav-item';
import {SHOP_NAV_ITEM} from '../constants/shop-nav-item';
import {STREAMER_DASHBOARD_NAV_ITEM} from '../constants/streamer-dashboard-nav-item';
import {STREAMER_NAV_ITEM} from '../constants/streamer-nav-item';
import {WALLET_NAV_ITEM} from '../constants/wallet-nav-item';

@Component({
  selector: 'aza-landing-header',
  templateUrl: './landing-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingHeaderComponent extends AzaCdkHsvsDirective {
  public readonly externalLinkIcon = azaCoreExternalLink;
  public readonly loginLink = [AppRouteSegment.ROOT, AppRouteSegment.LOGIN];

  public readonly logInIcon = azaCoreLogin;

  public readonly navItems = [
    STREAMER_NAV_ITEM,
    SHOP_NAV_ITEM,
    ADVERTISE_NAV_ITEM,
  ];

  public readonly dataCollectionPermissionsNavIem =
    DATA_COLLECTION_PERMISSIONS_NAV_ITEM;
  public readonly streamerDashboardNavIem = STREAMER_DASHBOARD_NAV_ITEM;
  public readonly streamerNavIem = STREAMER_NAV_ITEM;
  public readonly shopNavItem = SHOP_NAV_ITEM;
  public readonly walletNavItem = WALLET_NAV_ITEM;
  public readonly azaCoinNavItem = AZA_COIN_NAV_ITEM;
  public readonly advertiseNavItem = ADVERTISE_NAV_ITEM;
  public readonly getMoreEntriesNavItem = GET_MORE_ENTRIES_NAV_ITEM;

  public readonly isScullyRunning = isScullyRunning();

  public readonly isAuthorized$ = this._asyncAuthService.isAuthorized$;

  public constructor(private readonly _asyncAuthService: AsyncAuthService) {
    super('aza', 'landingHeader');
  }
}
