import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AzaCoreButtonModule} from '@azarus/frontend/core/modules/button/button.module';
import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {DialogCloseButtonModule} from '../dialog-close-button/dialog-close-button.module';

import {GreetingPopupNovemberComponent} from './greeting-popup-november.component';
import {GreetingPopupXmasComponent} from './greeting-popup-xmax.component';

@NgModule({
  imports: [
    CommonModule,
    DialogCloseButtonModule,
    AzaCoreButtonModule,
    AzaCoreSvgIconModule,
    RouterModule,
  ],
  declarations: [GreetingPopupNovemberComponent, GreetingPopupXmasComponent],
})
export class GreetingPopupNovemberModule {}
