import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import {Directive, HostBinding, HostListener, Input} from '@angular/core';

import {SNOWPLOW_DONT_TRACK_CLASSNAME} from '../../constants/dont-track-classname';
import {SnowplowService} from '../../services/snowplow.service';

@Directive()
export abstract class SnowplowEventPrimitiveDirective<
  C extends string,
  A extends string,
> {
  @Input()
  public snowplowAction!: A;

  @Input()
  public snowplowCategory!: C;

  @Input()
  public snowplowValue: NumberInput | undefined;

  @Input()
  public snowplowLabel: string | undefined;

  @Input()
  public snowplowProperty: string | undefined;

  @Input()
  @HostBinding(`class.${SNOWPLOW_DONT_TRACK_CLASSNAME}`)
  public get snowplowDontTrack(): boolean {
    return this._snowplowDontTrack;
  }

  public set snowplowDontTrack(value: BooleanInput) {
    this._snowplowDontTrack = coerceBooleanProperty(value);
  }

  private _snowplowDontTrack = false;

  protected constructor(
    public readonly snowplowService: SnowplowService<C, A>,
  ) {}

  @HostListener('click')
  public eventTrack(): void {
    this.snowplowService.trackStructEvent({
      category: this.snowplowCategory,
      action: this.snowplowAction,
      label: this.snowplowLabel,
      property: this.snowplowProperty,
      value:
        this.snowplowValue !== undefined
          ? coerceNumberProperty(this.snowplowValue)
          : undefined,
    });
  }
}
