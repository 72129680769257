import {isScullyRunning} from '@scullyio/ng-lib';
import Cookies from 'js-cookie';

export function winnerTokenCatchInitializer(
  paramName: string,
  expirationTimeS: number,
): () => void {
  return () => {
    if (isScullyRunning()) {
      return;
    }
    const url = new URL(window.location.href);
    const paramValue = url.searchParams.get(paramName);
    if (paramValue !== null) {
      // add cookie named after paramName value to the cookie storage
      let codeStorage: {code: string; date: string}[] = [];
      try {
        codeStorage = JSON.parse(Cookies.get(paramName) ?? '[]');
        if (!Array.isArray(codeStorage)) {
          codeStorage = [];
        }
      } catch (_e) {
        // do nothing
      }
      if (codeStorage.find((s) => s.code === paramValue) !== undefined) {
        return;
      }
      const tokenEntry = {code: paramValue, date: new Date().toISOString()};
      codeStorage = [...codeStorage, tokenEntry];

      Cookies.set(paramName, JSON.stringify(codeStorage), {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'max-age': expirationTimeS.toString(),
      });
    }
  };
}
