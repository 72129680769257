import {TypedPropertyDecorator} from '../../type/typed-property-decorator';
import {ValueTransformer} from '../value-transformer';

import {classTransformerCache} from './class-transformer-cache';

// TODO make more stricter types avoid unknown
export function field<T>(
  transformer: ValueTransformer<T>,
): TypedPropertyDecorator<T> {
  return (target, key) => {
    classTransformerCache
      .make(target, () => new Map<string, ValueTransformer<unknown>>())
      // TODO stage fields order, use generic array with entry
      .set(key, transformer);
  };
}
