import {ModuleWithProviders, NgModule} from '@angular/core';
import {ScullyLibModule, TransferStateService} from '@scullyio/ng-lib';

import {AzaScullyPatchedTransferStateService} from './patched-transfer-state.service';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ScullyGenerationTime: number;
  }
}

@NgModule({
  imports: [ScullyLibModule.forRoot()],
  exports: [ScullyLibModule],
})
export class AzaScullyPatchedScullyModule {
  public static forRoot(): ModuleWithProviders<AzaScullyPatchedScullyModule> {
    return {
      ngModule: AzaScullyPatchedScullyModule,
      providers: [
        {
          provide: TransferStateService,
          useClass: AzaScullyPatchedTransferStateService,
        },
      ],
    };
  }
}
