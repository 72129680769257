export enum RrpcClientCode {
  // 3500-3599: error

  CONNECTION_TIMEOUT = 3500,

  // 3600-3699: success

  NO_ACTIVE_SUBSCRIPTIONS = 3600,

  NO_FUTURE_SUBSCRIPTIONS = 3601,
}
