import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {Observable, of, switchMap, tap} from 'rxjs';

import {IsoDateString} from '@azarus/common/type/iso-date-string';
import {
  API_GATEWAY_HTTP_URL,
  ApiGatewayUserService,
} from '@azarus/frontend/api/gateway';
import {AZA_CORE_MOCA_CONNECT_URL} from '@azarus/frontend/core/const/moca-connect-url';

import {AppRouteSegment} from '../app-route-segment.enum';
import {AsyncAuthService} from '../auth/async-auth.service';
import {LoginPageQueryParam} from '../pages/login-page/login-page-query-param';
import {UserInfoActionService} from '../services/user-info-action.service';

const isDateExpired = (date: IsoDateString): boolean => {
  const newData = Date.now();
  const parsDate = Date.parse(date);

  return newData >= parsDate;
};

const getJoinMocaverseURL = (
  baseUrl: string,
  jwt: string,
  userId: string,
  redirect: string,
): URL => {
  const redirectUrl = new URL('user/connect/moca/callback', `${baseUrl}`);
  redirectUrl.searchParams.append('redirect', redirect);
  redirectUrl.searchParams.append('userId', userId);

  const url = new URL(`${AZA_CORE_MOCA_CONNECT_URL}`);
  url.searchParams.append('partner', jwt);
  url.searchParams.append('redirect', redirectUrl.toString());

  return url;
};

const mocaConnect = (
  baseUrl: string,
  partnerJwt: string,
  userId: string,
): Observable<boolean> =>
  of(false).pipe(
    tap({
      next: () => {
        window.open(
          getJoinMocaverseURL(baseUrl, partnerJwt, userId, location.href),
          '_self',
        );
      },
    }),
  );

export const canActivateMocaConnectId: CanActivateFn = () => {
  const router = inject(Router);
  const baseUrl = inject(API_GATEWAY_HTTP_URL);
  const user = inject(UserInfoActionService).info$;
  const userData = inject(ApiGatewayUserService).getUser();
  const isUserAuth = inject(AsyncAuthService).isAuthorized$;

  return isUserAuth.pipe(
    switchMap((isAuth) => {
      if (isAuth) {
        return user.pipe(
          switchMap(({_id, mocaVerseData}) => {
            const urlTree = router.createUrlTree([
              AppRouteSegment.ROOT,
              AppRouteSegment.GET_MORE_ENTRIES,
            ]);

            if (
              mocaVerseData.realmId !== null ||
              mocaVerseData.partnerJwtExpAt === null ||
              mocaVerseData.partnerJwt === null
            ) {
              return of(urlTree);
            }

            if (isDateExpired(mocaVerseData.partnerJwtExpAt)) {
              return userData.pipe(
                switchMap(({_id, mocaVerseData}) => {
                  if (
                    mocaVerseData.realmId !== null ||
                    mocaVerseData.partnerJwtExpAt === null ||
                    mocaVerseData.partnerJwt === null
                  ) {
                    return of(urlTree);
                  }

                  return mocaConnect(baseUrl, mocaVerseData.partnerJwt, _id);
                }),
              );
            }

            return mocaConnect(baseUrl, mocaVerseData.partnerJwt, _id);
          }),
        );
      }

      const returnPath = router.serializeUrl(
        router.createUrlTree([
          AppRouteSegment.ROOT,
          AppRouteSegment.CONNECT_MOCA_ID,
        ]),
      );

      const urlTree = router.createUrlTree(
        [AppRouteSegment.ROOT, AppRouteSegment.LOGIN],
        {
          queryParams: {
            [LoginPageQueryParam.RETURN_PATH]: returnPath,
          },
        },
      );

      return of(urlTree);
    }),
  );
};
