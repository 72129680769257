import {defer, map, mergeScan, Observable, of, startWith} from 'rxjs';

import {PaginationResponse} from '@azarus/api-contract';

export function fetchPaginatedData<T>(
  request: (offset: number) => Observable<PaginationResponse<T>>,
  infiniteScrollLoadTrigger: Observable<void>,
): Observable<PaginationResponse<T>> {
  return defer(() => {
    let page = 0;

    return infiniteScrollLoadTrigger.pipe(
      startWith(undefined),
      mergeScan<void, PaginationResponse<T>>(
        (acc) => {
          if (acc.data.length >= acc.total && page !== 0) {
            return of(acc);
          }

          return request(page++).pipe(
            map((response) => ({
              data: [...acc.data, ...response.data],
              total: response.total,
            })),
          );
        },
        {data: [], total: 0},
      ),
    );
  });
}
