import {Router} from '@angular/router';
import {
  catchError,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  repeat,
  ReplaySubject,
  share,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';

import {AccessToken} from '@azarus/api-contract';
import {
  API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT,
  API_BROWSER_EXTENSION_DELETE_USER_METHOD,
  API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_METHOD,
  API_BROWSER_EXTENSION_SIGN_OUT_METHOD,
  ApiBrowserExtensionSignOutEventDataDto,
} from '@azarus/frontend/api/browser-extension';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {AppRouteSegment} from '../app-route-segment.enum';
import {ExtensionDetectorService} from '../services/extension-detector.service';

import {AsyncAuthService} from './async-auth.service';

export class ExtensionAsyncAuthService implements AsyncAuthService {
  private readonly _token$: Observable<AccessToken | null> =
    this._extensionDetector.isExtensionInstalled$.pipe(
      switchMap((isExtensionInstalled) => {
        if (isExtensionInstalled) {
          return this._getAccessTokenMethod();
        }
        return of(null);
      }),
      share({
        resetOnComplete: true,
        resetOnRefCountZero: true,
        connector: () => new ReplaySubject(1),
      }),
    );

  public readonly isAuthorized$: Observable<boolean> = this._token$.pipe(
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
    map((token) => token !== null),
    repeat({
      delay: () =>
        merge(
          this._authStateChangeEvent$,
          fromEvent(document, 'visibilitychange'),
        ),
    }),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  public constructor(
    private readonly _extensionDetector: ExtensionDetectorService,
    private readonly _getAccessTokenMethod: AzaCdkInjectionTokenType<
      typeof API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_METHOD
    >,
    private readonly _signOutMethod: AzaCdkInjectionTokenType<
      typeof API_BROWSER_EXTENSION_SIGN_OUT_METHOD
    >,
    private readonly _authStateChangeEvent$: AzaCdkInjectionTokenType<
      typeof API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT
    >,
    private readonly _deleteUser: AzaCdkInjectionTokenType<
      typeof API_BROWSER_EXTENSION_DELETE_USER_METHOD
    >,
    private readonly _router: Router,
  ) {
    this._authStateChangeEvent$
      .pipe(
        filter(
          (event) =>
            event.data instanceof ApiBrowserExtensionSignOutEventDataDto,
        ),
        switchMap(() => this.isAuthorized$),
        filter((isAuthorized) => !isAuthorized),
      )
      .subscribe(() => {
        void this._router.navigate([
          AppRouteSegment.ROOT,
          AppRouteSegment.LOGIN,
        ]);
      });
  }

  public getToken(): Observable<AccessToken | null> {
    return this._token$.pipe(take(1));
  }

  public logout(): Observable<void> {
    return this._signOutMethod();
  }

  public deleteUser(): Observable<void> {
    return this._deleteUser();
  }
}
