import {inject, InjectionToken} from '@angular/core';

import {API_BROWSER_EXTENSION_MESSAGING_CLIENT} from '../../tokens';

import {API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_REF} from './get-access-token.ref';

export const API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_METHOD = new InjectionToken(
  'API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_METHOD',
  {
    providedIn: 'root',
    factory: () =>
      API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_REF.connect(
        inject(API_BROWSER_EXTENSION_MESSAGING_CLIENT),
      ),
  },
);
