import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BalanceManagementChannelTreasuryCurrentBalanceNextDto} from './balance-management-channel-treasury-current-balance-next.dto';
import {BalanceManagementChannelTreasuryCurrentBalanceParamstDto} from './balance-management-channel-treasury-current-balance-params.dto';

export const BALANCE_MANAGEMENT_CHANNEL_TREASURY_CURRENT_BALANCE_REF =
  new AzaRrpcPersistentRef(
    ['balanceManagement', 'channelTreasuryCurrentBalance'],
    BalanceManagementChannelTreasuryCurrentBalanceParamstDto,
    asClass(BalanceManagementChannelTreasuryCurrentBalanceNextDto),
  );
