import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AzaCoreAzaIconModule} from '@azarus/frontend/core/modules/aza-icon/aza-icon.module';
import {AzaCoreAzaToCurrencyModule} from '@azarus/frontend/core/modules/aza-to-currency/aza-to-currency.module';
import {AzaCoreCurrencyAmountModule} from '@azarus/frontend/core/modules/currency-amount/currency-amount.module';
import {AzaCoreTruncPipeModule} from '@azarus/frontend/core/modules/trunc-pipe/trunc-pipe.module';

import {CurrencyDisplayComponent} from './currency-display.component';
import {CurrencyDisplayReducedComponent} from './currency-display-reduced.component';

@NgModule({
  declarations: [CurrencyDisplayComponent, CurrencyDisplayReducedComponent],
  imports: [
    CommonModule,
    AzaCoreAzaIconModule,
    AzaCoreAzaToCurrencyModule,
    AzaCoreCurrencyAmountModule,
    AzaCoreTruncPipeModule,
  ],
  exports: [CurrencyDisplayComponent, CurrencyDisplayReducedComponent],
})
export class CurrencyDisplayModule {}
