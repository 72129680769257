import {field} from '@azarus/common/transformer/class/field';
import {asDate} from '@azarus/common/transformer/date/as-date';
import {asNumber} from '@azarus/common/transformer/number/as-number';

export class ChannelPresenceHeartbeatNextDto {
  @field(asDate())
  public readonly joinedAt: Date;

  @field(asDate())
  public readonly lastHeartbeatAt: Date;

  @field(asNumber())
  public readonly spentMs: number;

  @field(asNumber())
  public readonly nextHeartbeatInMs: number;

  public constructor(
    joinedAt: ChannelPresenceHeartbeatNextDto['joinedAt'],
    lastHeartbeatAt: ChannelPresenceHeartbeatNextDto['lastHeartbeatAt'],
    spentMs: ChannelPresenceHeartbeatNextDto['spentMs'],
    nextHeartbeatInMs: ChannelPresenceHeartbeatNextDto['nextHeartbeatInMs'],
  ) {
    this.joinedAt = joinedAt;
    this.lastHeartbeatAt = lastHeartbeatAt;
    this.spentMs = spentMs;
    this.nextHeartbeatInMs = nextHeartbeatInMs;
  }
}
