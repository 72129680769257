import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../tokens/http-url';
import {ApiGamePlatformPartySessionData} from '../types';
import {ApiGamePlatformPartySession} from '../types/platform-party-session';
import {ApiGamePlatformPartySessionBaseRequest} from '../types/platform-party-session-base-request';
import {ApiGamePlatformPartySessionCreateRequest} from '../types/platform-party-session-create-request';
import {ApiGamePlatformPartySessionStopRequest} from '../types/platform-party-session-stop-request';

@Injectable({providedIn: 'root'})
export class ApiGamePlatformPartySessionService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getListPlatformSession({
    platformId,
    userId,
  }: ApiGamePlatformPartySessionBaseRequest): Observable<ApiGamePlatformPartySessionData | null> {
    return this._httpClient.get<ApiGamePlatformPartySessionData | null>(
      `${this._httpUrl}/users/${userId}/games/platforms/${platformId}/sessions`,
    );
  }

  public createPlatformPartySession({
    partyId,
    platformId,
    userId,
  }: ApiGamePlatformPartySessionCreateRequest): Observable<ApiGamePlatformPartySession> {
    return this._httpClient.post<ApiGamePlatformPartySession>(
      `${this._httpUrl}/users/${userId}/games/platforms/${platformId}/sessions`,
      {partyId},
    );
  }

  public stopPlatformPartySession({
    partyId,
    platformId,
    userId,
  }: ApiGamePlatformPartySessionStopRequest): Observable<ApiGamePlatformPartySession> {
    return this._httpClient.delete<ApiGamePlatformPartySession>(
      `${this._httpUrl}/users/${userId}/games/platforms/${platformId}/sessions/${partyId}`,
    );
  }
}
