import inspect from 'object-inspect';

import {LiteralPath} from './literal-path';
import {readableLiteralPath} from './readable-literal-path';
import {TransformerError} from './transformer-error';

const PREVIEW_MAX_LENGTH = 128;
const OVERFLOW = '…';

function literalPreview(literal: unknown): string {
  try {
    const preview: string = inspect(literal, {depth: 1});

    return preview.length > PREVIEW_MAX_LENGTH
      ? preview.slice(0, PREVIEW_MAX_LENGTH - OVERFLOW.length) + OVERFLOW
      : preview;
  } catch {
    return '[[preview fail]]';
  }
}

export class LiteralDeserializationError extends TransformerError {
  public override name = 'LiteralDeserializationError';

  public constructor(literal: unknown, path: LiteralPath) {
    super(`${readableLiteralPath(path)} = ${literalPreview(literal)}`);
  }
}
