import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AzaCoreDropdownModule} from '@azarus/frontend/core/modules/dropdown/dropdown.module';
import {AzaCoreNotificationCountModule} from '@azarus/frontend/core/modules/notification-count/notification-count.module';
import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {VerticalMenuItemModule} from '../vertical-menu-item/vertical-menu-item.module';

import {ProfileMenuComponent} from './profile-menu.component';
import {ProfileMenuNavigationDirective} from './profile-menu-navigation.directive';

@NgModule({
  declarations: [ProfileMenuComponent, ProfileMenuNavigationDirective],
  imports: [
    CommonModule,
    AzaCoreNotificationCountModule,
    AzaCoreSvgIconModule,
    VerticalMenuItemModule,
    AzaCoreDropdownModule,
  ],
  exports: [ProfileMenuComponent, ProfileMenuNavigationDirective],
})
export class ProfileMenuModule {}
