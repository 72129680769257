import {
  Directive,
  Inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import {AzaCoreLetContext} from './let.context';

@Directive({
  selector: '[azaCoreLet]',
})
export class AzaCoreLetDirective<T> {
  @Input()
  public azaCoreLet!: T;

  public constructor(
    @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
    @Inject(TemplateRef) templateRef: TemplateRef<AzaCoreLetContext<T>>,
  ) {
    viewContainer.createEmbeddedView(
      templateRef,
      new AzaCoreLetContext<T>(this),
    );
  }

  public static ngTemplateContextGuard<T>(
    _dir: AzaCoreLetDirective<T>,
    _ctx: any,
  ): _ctx is AzaCoreLetDirective<T> {
    return true;
  }
}
