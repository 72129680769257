import {isUnknownArray} from './is-unknown-array';
import {RecursiveArray} from './type/recursive-array';

export function* flatArray<T>(list: RecursiveArray<T>): Iterable<T> {
  for (const item of list) {
    if (isUnknownArray(item)) {
      yield* flatArray<T>(item);
      continue;
    }

    yield item;
  }
}
