import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../../tokens/http-url';
import {ApiGameGameBaseRequest} from '../../../../types/game-base-request';
import {ApiGameQuickTriviaListPartiesResponse} from '../types/list-parties-response';
import {ApiGameQuickTriviaParty} from '../types/party';
import {ApiGameQuickTriviaPartyCreateRequest} from '../types/party-create-request';
import {ApiGameQuickTriviaPartyDeleteRequest} from '../types/party-delete-request';
import {ApiGameQuickTriviaPartyUpdateRequest} from '../types/party-update-request';

@Injectable({providedIn: 'root'})
export class ApiGameQuickTriviaPartiesService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getListParties({
    userId,
    gameId,
    gameVariationId,
  }: ApiGameGameBaseRequest): Observable<ApiGameQuickTriviaListPartiesResponse> {
    return this._httpClient.get<ApiGameQuickTriviaListPartiesResponse>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties`,
    );
  }

  public createParty({
    question,
    userId,
    gameId,
    gameVariationId,
    closingMessage,
  }: ApiGameQuickTriviaPartyCreateRequest): Observable<ApiGameQuickTriviaParty> {
    return this._httpClient.post<ApiGameQuickTriviaParty>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties`,
      {question, closingMessage},
    );
  }

  public updateParty({
    gameId,
    gameVariationId,
    partyId,
    userId,
    question,
    closingMessage,
  }: ApiGameQuickTriviaPartyUpdateRequest): Observable<ApiGameQuickTriviaParty> {
    return this._httpClient.patch<ApiGameQuickTriviaParty>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${partyId}`,
      {question, closingMessage},
    );
  }

  public deleteParty({
    gameId,
    gameVariationId,
    partyId,
    userId,
  }: ApiGameQuickTriviaPartyDeleteRequest): Observable<unknown> {
    return this._httpClient.delete(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${partyId}`,
    );
  }
}
