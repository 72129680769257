import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {GameAppAvailabilityNextDto} from './game-app-availability-next.dto';
import {GameAppAvailabilityParamsDto} from './game-app-availability-params.dto';

export const GAME_APP_AVAILABILITY_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'availability'],
  GameAppAvailabilityParamsDto,
  asClass(GameAppAvailabilityNextDto),
);
