import {defer, map, Observable} from 'rxjs';

import {ValueTransformer} from '@azarus/common/transformer/value-transformer';

import {AzaCdkAbstractExtensionEventRef} from '../abstract-extension-event/abstract-extension-event-ref';

import {AzaCdkExtensionEvent} from './extension-event';
import {AzaCdkExtensionEventClient} from './extension-event-client';

export class AzaCdkExtensionEventRef<T>
  implements AzaCdkAbstractExtensionEventRef<T>
{
  public constructor(
    private readonly _name: string,
    private readonly _dataTransformer: ValueTransformer<T>,
  ) {}

  public createEvent(data: T): AzaCdkExtensionEvent<unknown> {
    return {name: this._name, data: this._dataTransformer.dataToLiteral(data)};
  }

  public connect(
    client: AzaCdkExtensionEventClient,
  ): Observable<AzaCdkExtensionEvent<T>> {
    return defer(() => client.addListener(this._name)).pipe(
      map((event) => ({
        name: event.name,
        data: this._dataTransformer.literalToData(event.data, []),
      })),
    );
  }
}
