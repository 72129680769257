import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {KycStatusNextDto} from './kyc-status-next.dto';
import {KycStatusParamstDto} from './kyc-status-params.dto';

export const KYC_STATUS_REF = new AzaRrpcPersistentRef(
  ['kyc', 'status'],
  KycStatusParamstDto,
  asClass(KycStatusNextDto),
);
