import {Directive, ElementRef, Input} from '@angular/core';

import {hsvs} from '@azarus/frontend/cdk/hsvs/hsvs';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Directive()
export abstract class AzaCoreButtonBase extends AzaCdkHsvsDirective {
  @Input() @hsvs() public color:
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'outlined-primary'
    | null = null;
  @Input() @hsvs() public size: 'small' | 'large' | null = null;
  @hsvs() public readonly fab =
    this._elementRef.nativeElement.hasAttribute('aza-core-fab');

  public constructor(
    private readonly _elementRef: ElementRef<
      HTMLButtonElement | HTMLAnchorElement
    >,
  ) {
    super('aza', 'button');
  }
}
