import {inject, InjectionToken} from '@angular/core';

import {CURRENT_GAME_SESSION_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_GAME_APP_CURRENT_GAME_SESSION_METHOD =
  new InjectionToken('AZA_GATEWAY_GAME_APP_CURRENT_GAME_SESSION_METHOD', {
    providedIn: 'root',
    factory: () =>
      CURRENT_GAME_SESSION_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  });
