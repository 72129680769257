import {inject, InjectionToken, NgZone} from '@angular/core';

import {AzaCdkExtensionEventAngularClient} from '@azarus/frontend/cdk/extension-event/extension-event-angular-client';

export const API_BROWSER_EXTENSION_EVENT_CLIENT = new InjectionToken(
  'API_BROWSER_EXTENSION_EVENT_CLIENT',
  {
    providedIn: 'root',
    factory: () => {
      return new AzaCdkExtensionEventAngularClient(inject(NgZone));
    },
  },
);
