import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {AccessToken} from '../../../identity-management/api/auth/access-token';
import {SecureParams} from '../../../secure-params';

export class BalanceManagementCurrentBalanceParamstDto implements SecureParams {
  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  public constructor(
    accessToken: BalanceManagementCurrentBalanceParamstDto['accessToken'],
  ) {
    this.accessToken = accessToken;
  }
}
