import type {Observable} from 'rxjs';
import {defer, map} from 'rxjs';

import type {RrpcMethodPath} from '../rrpc-both/rrpc-method-path';
import type {RrpcClient} from '../rrpc-client/rrpc-client';
import {asClass} from '../transformer/class/as-class';
import type {ValueTransformer} from '../transformer/value-transformer';

export class AzaRrpcRef<P extends object, A extends readonly unknown[], N> {
  private readonly _params: ValueTransformer<P>;

  public constructor(
    private readonly _path: RrpcMethodPath,
    private readonly _dto: new (...params: A) => P,
    private readonly _next: ValueTransformer<N>,
  ) {
    this._params = asClass(_dto);
  }

  protected _createObservable(client: RrpcClient, data: P): Observable<N> {
    return defer(() =>
      client.unsafeRawLiteralCall(this._path, this._params.dataToLiteral(data)),
    ).pipe(map((literal) => this._next.literalToData(literal, [])));
  }

  public connect(client: RrpcClient): (...params: A) => Observable<N> {
    return (...params) => {
      return this._createObservable(client, new this._dto(...params));
    };
  }
}
