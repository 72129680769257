import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';
import {asNumber} from '@azarus/common/transformer/number/as-number';
export class ChannelPresenceStreamDataNextDto {
  @field(asBoolean())
  public readonly live: boolean;

  @field(asNumber())
  public readonly viewers: number;

  @field(asNumber())
  public readonly players: number;

  public constructor(
    live: ChannelPresenceStreamDataNextDto['live'],
    viewers: ChannelPresenceStreamDataNextDto['viewers'],
    players: ChannelPresenceStreamDataNextDto['players'],
  ) {
    this.live = live;
    this.viewers = viewers;
    this.players = players;
  }
}
