<aza-moca-widget
  *ngIf="!isScullyRunning && (isUserAuth$ | async) && (hasMocaId$ | async)"
  [class]="view('mocaWidget')"
></aza-moca-widget>

<router-outlet></router-outlet>

<a
  aza-discord-button
  [class]="view('discordButton')"
  data-cy="floating-discord-button"
  rel="noopener"
  target="_blank"
  [href]="discordLink"
>
  <span [class]="view('discordButtonContent')" i18n>Chat with Azarus</span>
</a>
