import {field} from '@azarus/common/transformer/class/field';
import {asNumber} from '@azarus/common/transformer/number/as-number';

export class ApiBrowserExtensionNotifyButtonAreaChangeParamsDto {
  @field(asNumber())
  public readonly x1: number;
  @field(asNumber())
  public readonly y1: number;
  @field(asNumber())
  public readonly x2: number;
  @field(asNumber())
  public readonly y2: number;

  public constructor(x1: number, y1: number, x2: number, y2: number) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
  }
}
