import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';
import {asDate} from '@azarus/common/transformer/date/as-date';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asNumber} from '@azarus/common/transformer/number/as-number';
import {asString} from '@azarus/common/transformer/string/as-string';

import {GameAppId} from '../game-app-id';
import {GameSessionId} from '../game-session-id';

export class CurrentGameSessionData {
  @field(asString<GameSessionId>())
  public readonly _id: GameSessionId;

  @field(asString<GameAppId>())
  public readonly gameAppId: GameAppId;

  @field(asNumber())
  public readonly gameAppVersion: number;

  @field(asBoolean())
  public readonly isGiveaway: boolean;

  @field(asNullable(asDate()))
  public readonly nextGiveawayAt: Date | null;

  @field(asNullable(asString()))
  public readonly customBalanceTag: string | null;

  public constructor(
    _id: CurrentGameSessionData['_id'],
    gameAppId: CurrentGameSessionData['gameAppId'],
    gameCurrentVersion: CurrentGameSessionData['gameAppVersion'],
    isGiveaway: CurrentGameSessionData['isGiveaway'],
    nextGiveawayAt: CurrentGameSessionData['nextGiveawayAt'],
    customBalanceTag: CurrentGameSessionData['customBalanceTag'],
  ) {
    this._id = _id;
    this.gameAppId = gameAppId;
    this.gameAppVersion = gameCurrentVersion;
    this.isGiveaway = isGiveaway;
    this.nextGiveawayAt = nextGiveawayAt;
    this.customBalanceTag = customBalanceTag;
  }
}
