import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  AccountId,
  StreamerAccount,
  StreamerPlatformInfoResponse,
  StreamerPublicKey,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayAccountService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public deleteAccount(accountId: AccountId): Observable<unknown> {
    return this._http.delete<unknown>(
      `${this._httpUrl}/accounts/${accountId}/logout`,
    );
  }

  public getAccounts(): Observable<StreamerAccount[]> {
    return this._http.get<StreamerAccount[]>(`${this._httpUrl}/accounts`, {});
  }

  public getStreamerPlatformInfo(
    streamerKey: StreamerPublicKey,
  ): Observable<StreamerPlatformInfoResponse> {
    return this._http.get<StreamerPlatformInfoResponse>(
      `${this._httpUrl}/accounts/platform-info/${streamerKey}`,
      {},
    );
  }

  public postYoutubeChannels(googleAccessToken: string): Observable<void> {
    return this._http.post<void>(
      `${this._httpUrl}/accounts/connect-streamer/youtube`,
      {googleAccessToken},
    );
  }
}
