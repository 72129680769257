import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {CdkPortal} from '@angular/cdk/portal';
import {
  Directive,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {BehaviorSubject, EMPTY, map, Subscription, switchMap} from 'rxjs';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {insideZone} from '@azarus/frontend/cdk/util/inside-zone';

import {AZA_CORE_DROPDOWN_OPTIONS} from './dropdown-options';

@Directive()
export abstract class AzaCoreDropdownBase
  extends AzaCdkHsvsDirective
  implements OnDestroy
{
  @Input() public set reference(reference: HTMLElement) {
    this._reference = reference;
    this._positionStrategy = this._createPositionStrategy();
  }
  public get reference(): HTMLElement {
    if (this._reference === null) {
      throw new Error('Reference not provided!');
    }
    return this._reference;
  }
  private _reference: HTMLElement | null = null;

  @Output() public readonly openedChange = new EventEmitter<boolean>();

  @ViewChild(CdkPortal, {static: true})
  public contentTemplate!: CdkPortal;
  public position = null;

  private readonly _scrollStrategy =
    this._overlay.scrollStrategies.reposition();
  private _positionStrategy$ =
    new BehaviorSubject<FlexibleConnectedPositionStrategy | null>(null);
  private set _positionStrategy(
    positionStrategy: FlexibleConnectedPositionStrategy,
  ) {
    this._positionStrategy$.next(positionStrategy);
  }
  private get _positionStrategy(): FlexibleConnectedPositionStrategy {
    const positionStrategy = this._positionStrategy$.getValue();
    if (positionStrategy === null) {
      throw new Error('Position strategy not initialized!');
    }
    return positionStrategy;
  }
  private _overlayRef: OverlayRef | null = null;

  public readonly isUpwards$ = this._positionStrategy$.pipe(
    switchMap((positionStrategy) => {
      if (positionStrategy === null) {
        return EMPTY;
      }
      return positionStrategy.positionChanges;
    }),
    map((position) => position.connectionPair.originY === 'top'),
    insideZone(this._zone),
  );

  /**
   * Tracking events while dropdown is active
   * When dropdown closes, should unsubscribe
   */
  private _activeDropDownSubscriptions: Subscription | null = null;

  public constructor(
    private readonly _overlay: Overlay,
    private readonly _zone: NgZone,
    @Inject(AZA_CORE_DROPDOWN_OPTIONS)
    private readonly _options: AzaCdkInjectionTokenType<
      typeof AZA_CORE_DROPDOWN_OPTIONS
    >,
  ) {
    super('aza', 'dropdown');
  }

  public ngOnDestroy(): void {
    if (this._activeDropDownSubscriptions !== null) {
      this._activeDropDownSubscriptions.unsubscribe();
    }
    if (this._overlayRef !== null) {
      this._overlayRef.dispose();
    }
  }

  @Input() public set opened(opened: boolean) {
    if (opened) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show(): void {
    if (this._overlayRef === null) {
      this._overlayRef = this._overlay.create(this._getOverlayConfig());
    }
    this._overlayRef.attach(this.contentTemplate);
    this._activeDropDownSubscriptions = new Subscription();
    this._activeDropDownSubscriptions.add(
      this._overlayRef.backdropClick().subscribe(() => {
        this.hide();
        this.openedChange.next(false);
      }),
    );
  }

  public hide(): void {
    if (this._overlayRef === null) {
      return;
    }
    this._overlayRef.detach();

    if (this._activeDropDownSubscriptions !== null) {
      this._activeDropDownSubscriptions.unsubscribe();
    }
  }

  private _createPositionStrategy(): FlexibleConnectedPositionStrategy {
    const positions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 10,
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -10,
      },
    ];
    return this._overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions(this._options.positions ?? positions);
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._positionStrategy,
      scrollStrategy: this._scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }
}
