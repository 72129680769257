import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {QueryFiltersResponse} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayProductsQueryFilterService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _url: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getFilters(): Observable<QueryFiltersResponse> {
    return this._http.get<QueryFiltersResponse>(
      `${this._url}/products/query-filters`,
    );
  }
}
