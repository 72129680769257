import {inject, InjectionToken} from '@angular/core';

import {GAME_SESSION_STATE_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_GAME_APP_GAME_SESSION_STATE_METHOD =
  new InjectionToken('API_GATEWAY_GAME_APP_GAME_SESSION_STATE_METHOD', {
    providedIn: 'root',
    factory: () =>
      GAME_SESSION_STATE_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  });
