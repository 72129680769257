import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BlockchainBridgeUnwrapTransactionStateNextDto} from './blockchain-bridge-unwrap-transaction-state-next.dto';
import {BlockchainBridgeUnwrapTransactionStateParamstDto} from './blockchain-bridge-unwrap-transaction-state-params.dto';

export const BLOCKCHAIN_BRIDGE_UNWRAP_TRANSACTION_STATE_REF =
  new AzaRrpcPersistentRef(
    ['blockchainBridge', 'unwrapTransactionState'],
    BlockchainBridgeUnwrapTransactionStateParamstDto,
    asClass(BlockchainBridgeUnwrapTransactionStateNextDto),
  );
