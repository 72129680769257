<div [class]="view('widget')" i18n>
  <span [class]="view('subtitleMobile')">Get extra entries!</span>
  <span [class]="view('subtitle')">Get extra entries to our giveaways!</span>

  <a aza-moca-button [routerLink]="connectMocaIdPageLink">
    <img
      alt="Moca logo"
      height="24"
      src="assets/img/moca-logo.webp"
      width="25"
    />

    <span [class]="view('btnTitle')">Join Mocaverse</span>
  </a>
</div>
