import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  GenerateOtpBody,
  GenerateOtpResponse,
  LoginBody,
  TokenCreds,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayAuthService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public generateOtp(body: GenerateOtpBody): Observable<GenerateOtpResponse> {
    return this._http.post<GenerateOtpResponse>(
      `${this._httpUrl}/auth/otp`,
      body,
    );
  }

  public login(body: LoginBody): Observable<TokenCreds> {
    return this._http.post<TokenCreds>(`${this._httpUrl}/auth/login`, body);
  }

  public refreshCreds(
    refreshToken: TokenCreds['refreshToken'],
    accessToken: TokenCreds['accessToken'],
  ): Observable<TokenCreds> {
    return this._http.post<TokenCreds>(
      `${this._httpUrl}/auth/tokens`,
      {
        refreshToken,
      },
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  }

  public logout(accessToken: TokenCreds['accessToken']): Observable<unknown> {
    return this._http.post(
      `${this._httpUrl}/auth/logout`,
      {},
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  }
}
