import {field} from '@azarus/common/transformer/class/field';
import {asEnum} from '@azarus/common/transformer/enum/as-enum';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';

import {BlockchainTransactionStatus} from '../../balance-management';

export class BlockchainBridgeUnwrapTransactionStateNextDto {
  @field(asNullable(asString()))
  public readonly txHash: string | null;

  @field(asEnum(BlockchainTransactionStatus))
  public readonly status: BlockchainTransactionStatus;

  public constructor(
    txHash: BlockchainBridgeUnwrapTransactionStateNextDto['txHash'],
    status: BlockchainBridgeUnwrapTransactionStateNextDto['status'],
  ) {
    this.txHash = txHash;
    this.status = status;
  }
}
