import {LiteralPath} from './literal-path';

export abstract class ValueTransformer<D> {
  protected constructor() {}

  public abstract dataToLiteral(data: D): unknown;

  public abstract literalToData(literal: unknown, path: LiteralPath): D;

  public abstract isSupport(data: unknown): data is D;
}
