import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {last, map, tap} from 'rxjs';

import {AppRouteSegment} from '../app-route-segment.enum';
import {UserInfoActionService} from '../services/user-info-action.service';

import {AsyncAuthService} from './async-auth.service';

export const canActivateLogout: CanActivateFn = () => {
  const userInfo = inject(UserInfoActionService);
  const authService = inject(AsyncAuthService);
  const router = inject(Router);

  return authService.logout().pipe(
    last(() => true, null),
    tap({
      next: () => {
        userInfo.resetUserState();
      },
    }),
    map(() =>
      router.createUrlTree([AppRouteSegment.ROOT, AppRouteSegment.LOGIN]),
    ),
  );
};
