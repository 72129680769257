import {inject, InjectionToken} from '@angular/core';

import {CHANNEL_PRESENCE_STREAM_DATA_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_CHANEL_PRESENCE_STREAM_DATA_METHOD =
  new InjectionToken('API_GATEWAY_CHANEL_PRESENCE_STREAM_DATA_METHOD', {
    providedIn: 'root',
    factory: () =>
      CHANNEL_PRESENCE_STREAM_DATA_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  });
