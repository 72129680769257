import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {GameSessionId} from '../game-session-id';

export class GameSessionJoinableNextDto {
  @field(asString<GameSessionId>())
  public readonly _id: GameSessionId;

  @field(asBoolean())
  public readonly isJoinable: boolean;

  public constructor(
    _id: GameSessionJoinableNextDto['_id'],
    isJoinable: GameSessionJoinableNextDto['isJoinable'],
  ) {
    this._id = _id;
    this.isJoinable = isJoinable;
  }
}
