import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

// TODO specs
export class NullableTransformer<T> extends ValueTransformer<T | null> {
  public constructor(private readonly _transformer: ValueTransformer<T>) {
    super();
  }

  public dataToLiteral(data: T | null): unknown {
    if (data === null) {
      return null;
    }

    return this._transformer.dataToLiteral(data);
  }

  public literalToData(literal: unknown, path: LiteralPath): T | null {
    if (literal === null) {
      return null;
    }

    return this._transformer.literalToData(literal, path);
  }

  public isSupport(data: unknown): data is T | null {
    return data === null || this._transformer.isSupport(data);
  }
}
