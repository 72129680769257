<ng-container *ngIf="user$ | async as user">
  <img
    [class]="view('picture')"
    alt="profile picture"
    i18n-alt
    [src]="user.avatar"
  />

  <div [class]="view('userNameWrapper')">
    <ng-content></ng-content>

    <div [class]="view('userName')" data-cy="user-profile-menu-username">
      {{ user.name }}
    </div>
  </div>
</ng-container>
