import {Inject, ModuleWithProviders, NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ChildrenOutletContexts} from '@angular/router';

import {AzaCdkInjectionTokenType} from '../alias/injection-token-type';

import {AzaCdkDialogOutletService} from './dialog-outlet.service';
import {AZA_CDK_DIALOG_OUTLET_NAME} from './dialog-outlet-name';

@NgModule({
  providers: [AzaCdkDialogOutletService],
  imports: [MatDialogModule],
})
export class AzaCdkDialogOutletModule {
  public static forRoot(
    outlet: string,
  ): ModuleWithProviders<AzaCdkDialogOutletModule> {
    return {
      ngModule: AzaCdkDialogOutletModule,
      providers: [{provide: AZA_CDK_DIALOG_OUTLET_NAME, useValue: outlet}],
    };
  }

  public constructor(
    parentContexts: ChildrenOutletContexts,
    dialogOutlet: AzaCdkDialogOutletService,
    @Inject(AZA_CDK_DIALOG_OUTLET_NAME)
    outlet: AzaCdkInjectionTokenType<typeof AZA_CDK_DIALOG_OUTLET_NAME>,
  ) {
    parentContexts.onChildOutletCreated(outlet, dialogOutlet);
  }
}
