import {ModuleWithProviders, NgModule} from '@angular/core';
import {isScullyRunning} from '@scullyio/ng-lib';

import {GoogleIdentityServiceService} from './google-identity-service.service';

@NgModule({})
export class GoogleIdentityServiceModule {
  public static forRoot(): ModuleWithProviders<GoogleIdentityServiceModule> {
    if (!isScullyRunning()) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        GoogleIdentityServiceService.libraryLoaded$.next();
        GoogleIdentityServiceService.libraryLoaded$.complete();
      };
      document.body.appendChild(script);
    }
    return {
      ngModule: GoogleIdentityServiceModule,
      providers: [],
    };
  }
}
