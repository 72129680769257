import {EnumDefinition} from '../../type/enum-definition';
import {EnumValue} from '../../type/enum-value';

import {EnumTransformer} from './enum-transformer';

export function asEnum<K extends string, V extends EnumValue>(
  definition: EnumDefinition<K, V>,
): EnumTransformer<K, V> {
  return EnumTransformer.from<K, V>(definition);
}
