import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AzaCoreAnimatedCurrencyAmountModule} from '@azarus/frontend/core/modules/animated-currency-amount/animated-currency-amount.module';
import {AzaCoreAzaIconModule} from '@azarus/frontend/core/modules/aza-icon/aza-icon.module';
import {AzaCoreLetModule} from '@azarus/frontend/core/modules/let/let.module';
import {AzaCoreTruncPipeModule} from '@azarus/frontend/core/modules/trunc-pipe/trunc-pipe.module';

import {UserCornerComponent} from './user-corner.component';
@NgModule({
  declarations: [UserCornerComponent],
  imports: [
    CommonModule,
    AzaCoreAnimatedCurrencyAmountModule,
    AzaCoreLetModule,
    AzaCoreTruncPipeModule,
    AzaCoreAzaIconModule,
  ],
  exports: [UserCornerComponent],
})
export class UserCornerModule {}
