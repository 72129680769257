import {
  defer,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  share,
  Subject,
} from 'rxjs';

import {ValueTransformer} from '@azarus/common/transformer/value-transformer';

class LocalValue<T> {
  public constructor(
    public readonly valueString: string | null,
    public readonly value: T,
  ) {}
}

export class AzaCdkLocalStorageObservable<T> extends Observable<T> {
  private readonly _localChanges$ = new Subject<T>();

  private _local: LocalValue<T> | null = null;

  private readonly _item$: Observable<T> = merge(
    this._localChanges$,
    defer(() => of(this.getValue())),
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(({key}) => key === null || key === this._key),
      map(({newValue}) => this._lazyParse(newValue)),
      share(),
    ),
  );

  public constructor(
    private readonly _key: string,
    private readonly _defaultValueProvider: () => T,
    private readonly _valueTransformer: ValueTransformer<T>,
  ) {
    super((subscriber) => this._item$.subscribe(subscriber));
  }

  private _lazyParse(newString: string | null): T {
    if (newString === null) {
      this._local = new LocalValue<T>(null, this._defaultValueProvider());
    } else if (this._local === null || this._local.valueString !== newString) {
      try {
        this._local = new LocalValue<T>(
          newString,
          this._valueTransformer.literalToData(JSON.parse(newString), [
            'localStorage',
            this._key,
          ]),
        );
      } catch {
        this._local = new LocalValue<T>(
          newString,
          this._defaultValueProvider(),
        );
      }
    }

    return this._local.value;
  }

  public getValue(): T {
    return this._lazyParse(localStorage.getItem(this._key));
  }

  public save(value: T): void {
    const valueString: string = JSON.stringify(
      this._valueTransformer.dataToLiteral(value),
    );

    this._local = new LocalValue<T>(valueString, value);
    window.localStorage.setItem(this._key, valueString);
    this._localChanges$.next(value);
  }
}
