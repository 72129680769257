import {AzaCdkContextWithImplicit} from '@azarus/frontend/cdk/context-with-implicit/context-with-implicit';

import {AzaCoreLetDirective} from './let.directive';

export class AzaCoreLetContext<T> implements AzaCdkContextWithImplicit<T> {
  public constructor(
    private readonly _internalDirectiveInstance: AzaCoreLetDirective<T>,
  ) {}

  public get $implicit(): T {
    return this._internalDirectiveInstance.azaCoreLet;
  }

  public get azaCoreLet(): T {
    return this._internalDirectiveInstance.azaCoreLet;
  }
}
