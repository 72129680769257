import {
  ComponentRef,
  EventEmitter,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  ProviderToken,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  ActivatedRoute,
  ChildrenOutletContexts,
  Data,
  Router,
  RouterOutletContract,
} from '@angular/router';
import {Subscription} from 'rxjs';

import {AZA_CDK_DIALOG_OUTLET_NAME} from './dialog-outlet-name';

@Injectable()
export class AzaCdkDialogOutletService
  implements OnDestroy, RouterOutletContract
{
  private _currentDialog: MatDialogRef<unknown, unknown> | null = null;
  private _subscriptions: Subscription | null = null;
  private _activatedRoute: ActivatedRoute | null = null;

  public constructor(
    private readonly _parentContexts: ChildrenOutletContexts,
    private readonly _dialog: MatDialog,
    private readonly _injector: Injector,
    private readonly _router: Router,
    @Inject(AZA_CDK_DIALOG_OUTLET_NAME) private readonly _outlet: string,
  ) {}

  public ngOnDestroy(): void {
    this._parentContexts.onChildOutletDestroyed(this._outlet);
  }

  public deactivate(): void {
    if (this._currentDialog === null) {
      return;
    }
    this._currentDialog.close();
    this._currentDialog = null;
    this._subscriptions?.unsubscribe();
    this._subscriptions = null;
    this.deactivateEvents.emit();
  }

  public activateWith(activatedRoute: ActivatedRoute): void {
    if (this._currentDialog !== null) {
      throw new Error('Cannot activate an already activated outlet');
    }
    const {
      snapshot: {routeConfig, data},
    } = activatedRoute;

    if (routeConfig === null || routeConfig.component === undefined) {
      throw new Error('Wrong route config');
    }

    this._activatedRoute = activatedRoute;

    const injector = new DialogOutletInjector(
      activatedRoute,
      this._parentContexts.getOrCreateContext(this._outlet).children,
      this._injector,
    );

    this._currentDialog = this._dialog.open(routeConfig.component, {
      ...data?.['config'],
      ...{
        closeOnNavigation: false,
        disableClose: true,
        injector,
      },
    });

    this._subscriptions = new Subscription();
    this._subscriptions.add(
      this._currentDialog.backdropClick().subscribe(() => {
        void this._router.navigate([{outlets: {[this._outlet]: null}}], {
          queryParamsHandling: 'preserve',
        });
      }),
    );
    this._subscriptions.add(
      this._currentDialog.afterOpened().subscribe(() => {
        this.activateEvents.emit();
      }),
    );
  }

  public get isActivated(): boolean {
    return this._currentDialog !== null;
  }

  public get component(): Object | null {
    return this._currentDialog;
  }

  public get activatedRouteData(): Data {
    return this._activatedRoute?.snapshot.data ?? {};
  }

  public get activatedRoute(): ActivatedRoute | null {
    return this._activatedRoute;
  }

  public activateEvents = new EventEmitter<unknown>();
  public deactivateEvents = new EventEmitter<unknown>();
  public attachEvents = new EventEmitter<unknown>();
  public detachEvents = new EventEmitter<unknown>();

  public detach(): ComponentRef<unknown> {
    throw new Error(
      'Detach is not implemented for the CommonDialogOutletService',
    );
  }
  public attach(
    _ref: ComponentRef<unknown>,
    _activatedRoute: ActivatedRoute,
  ): void {
    throw new Error(
      'Attach is not implemented for the CommonDialogOutletService',
    );
  }
}

class DialogOutletInjector implements Injector {
  public constructor(
    private _route: ActivatedRoute,
    private _childContexts: ChildrenOutletContexts,
    private _parent: Injector,
  ) {}

  public get(token: ProviderToken<unknown>, notFoundValue?: unknown): unknown {
    if (token === ActivatedRoute) {
      return this._route;
    }

    if (token === ChildrenOutletContexts) {
      return this._childContexts;
    }

    return this._parent.get(token, notFoundValue);
  }
}
