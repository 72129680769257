import {azaCoreAzaNav} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const AZA_COIN_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.AZA_COIN],
  label: $localize` Get AzaCoin`,
  icon: azaCoreAzaNav,
};
