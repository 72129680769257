import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {GameSessionId} from '../game-session-id';

export class GameSessionJoinableParamsDto {
  @field(asString<GameSessionId>())
  public readonly gameSessionId: GameSessionId;

  public constructor(
    gameSessionId: GameSessionJoinableParamsDto['gameSessionId'],
  ) {
    this.gameSessionId = gameSessionId;
  }
}
