import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {BalanceCustomTag} from '../../../common/enums/balance-custom-tag.enum';
import {AccessToken} from '../../../identity-management/api/auth/access-token';
import {SecureParams} from '../../../secure-params';

export class BalanceManagementCurrentCustomTagBalanceParamstDto
  implements SecureParams
{
  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  @field(asString())
  public readonly customBalanceTag: BalanceCustomTag;

  public constructor(
    accessToken: BalanceManagementCurrentCustomTagBalanceParamstDto['accessToken'],
    customBalanceTag: BalanceManagementCurrentCustomTagBalanceParamstDto['customBalanceTag'],
  ) {
    this.accessToken = accessToken;
    this.customBalanceTag = customBalanceTag;
  }
}
