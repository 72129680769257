import {asArray} from '@azarus/common/transformer/array/as-array';
import {asClass} from '@azarus/common/transformer/class/as-class';
import {field} from '@azarus/common/transformer/class/field';

import {ShopUserPurchasesData} from './shop-user-purchases-data';

export class ShopUserPurchasesNextDto {
  @field(asArray(asClass(ShopUserPurchasesData)))
  public readonly data: ShopUserPurchasesData[];

  public constructor(data: ShopUserPurchasesNextDto['data']) {
    this.data = data;
  }
}
