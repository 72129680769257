import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionNotifyOverlayOpenParamsDto} from './notify-overlay-open-params.dto';

export const API_BROWSER_EXTENSION_NOTIFY_OVERLAY_OPEN_REF =
  new AzaCdkMessagingRef(
    'notifyOverlayOpen',
    ApiBrowserExtensionNotifyOverlayOpenParamsDto,
    asNever(),
  );
