import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionNotifyOverlayClosedParamsDto} from './notify-overlay-closed-params.dto';

export const API_BROWSER_EXTENSION_NOTIFY_OVERLAY_CLOSED_REF =
  new AzaCdkMessagingRef(
    'notifyOverlayClosed',
    ApiBrowserExtensionNotifyOverlayClosedParamsDto,
    asNever(),
  );
