import {ScrollStrategyOptions} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class AzaCdkScrollBlockingService {
  private set _blockingRequesters(blockingRequesters: number) {
    if (this._blockingRequestersCount === 0 && blockingRequesters === 1) {
      this._scrollStrategy.enable();
    } else if (this._blockingRequestersCount > 0 && blockingRequesters === 0) {
      this._scrollStrategy.disable();
    }
    this._blockingRequestersCount = blockingRequesters;
  }
  private get _blockingRequesters(): number {
    return this._blockingRequestersCount;
  }
  private _blockingRequestersCount = 0;

  private readonly _scrollStrategy = this._scrollStrategyOptions.block();

  public constructor(
    private readonly _scrollStrategyOptions: ScrollStrategyOptions,
  ) {}

  public requestBlocking(): void {
    this._blockingRequesters++;
  }

  public removeBlockingRequest(): void {
    this._blockingRequesters--;
  }
}
