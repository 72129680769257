import {asClass} from '@azarus/common/transformer/class/as-class';
import {field} from '@azarus/common/transformer/class/field';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';

import {CurrentGameSessionData} from './current-game-session-data';

export class CurrentGameSessionNextDto {
  @field(asNullable(asClass(CurrentGameSessionData)))
  public readonly data: CurrentGameSessionData | null;

  public constructor(data: CurrentGameSessionNextDto['data']) {
    this.data = data;
  }
}
