import {NgZone} from '@angular/core';
import {Observable} from 'rxjs';

import {insideZone} from '../util/inside-zone';

import {AzaCdkMessagingClient} from './messaging-client';
import {AzaCdkMessagingClientId} from './messaging-client-id';

export class AzaCdkMessagingAngularClient extends AzaCdkMessagingClient {
  public constructor(
    private readonly _zone: NgZone,
    protected override readonly _clientId: AzaCdkMessagingClientId,
    protected override readonly _window: Window = window,
    protected override readonly _targetOrigin: string = window.origin,
  ) {
    super(_clientId, _window, _targetOrigin);
  }

  public override unsafeCall(
    method: string,
    params: unknown,
  ): Observable<unknown> {
    return super.unsafeCall(method, params).pipe(insideZone(this._zone));
  }
}
