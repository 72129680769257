import {Injectable} from '@angular/core';
import {
  AsyncSubject,
  map,
  merge,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class GoogleIdentityServiceService {
  public static libraryLoaded$ = new AsyncSubject<void>();
  private _callback$ = new Subject<google.accounts.oauth2.TokenResponse>();
  private _errorCallback$ = new Subject<
    | google.accounts.oauth2.ClientConfigError
    | google.accounts.oauth2.TokenResponse
  >();
  private _client$: Observable<google.accounts.oauth2.TokenClient> =
    GoogleIdentityServiceService.libraryLoaded$.pipe(
      map(() =>
        google.accounts.oauth2.initTokenClient({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_id: environment.googleIdentity.clientId,
          scope: 'email https://www.googleapis.com/auth/youtube.readonly',
          callback: (response: google.accounts.oauth2.TokenResponse) => {
            if (response.error !== undefined && response.error !== null) {
              this._errorCallback$.next(response);
              return;
            }
            this._callback$.next(response);
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          error_callback: (error: google.accounts.oauth2.ClientConfigError) => {
            this._errorCallback$.next(error);
          },
        }),
      ),
      shareReplay(1),
    );

  public requestAccessToken(): Observable<google.accounts.oauth2.TokenResponse> {
    return merge(
      this._errorCallback$.pipe(switchMap((e) => throwError(() => e))),
      this._client$.pipe(
        tap((client) => {
          client.requestAccessToken();
        }),
      ),
    ).pipe(
      switchMap(() => this._callback$),
      take(1),
    );
  }
}
