import {field} from '@azarus/common/transformer/class/field';
import {asNumber} from '@azarus/common/transformer/number/as-number';
import {asString} from '@azarus/common/transformer/string/as-string';

import {UserId} from '../../identity-management';

export class WinnerDto {
  @field(asString<UserId>())
  public readonly _id: UserId;

  @field(asString())
  public readonly name: string;

  @field(asString())
  public readonly avatar: string;

  @field(asNumber())
  public readonly amount: number;

  public constructor(
    _id: WinnerDto['_id'],
    name: WinnerDto['name'],
    avatar: WinnerDto['avatar'],
    amount: WinnerDto['amount'],
  ) {
    this._id = _id;
    this.name = name;
    this.avatar = avatar;
    this.amount = amount;
  }
}
