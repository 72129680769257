import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Component({
  selector: 'aza-vertical-menu-item',
  templateUrl: './vertical-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class VerticalMenuItemComponent extends AzaCdkHsvsDirective {
  @Input()
  public path!: any[] | string | null | undefined;

  public constructor() {
    super('aza', 'verticalMenuItem');
  }
}
