import {ConnectedPosition} from '@angular/cdk/overlay';
import {InjectionToken} from '@angular/core';

export const AZA_CORE_DROPDOWN_OPTIONS =
  new InjectionToken<AzaCoreDropdownOptions>(
    'AZA_CORPORATE_WEB_DROPDOWN_OPTIONS',
    {
      factory: () => ({}),
    },
  );

export interface AzaCoreDropdownOptions {
  readonly positions?: ConnectedPosition[];
}
