import {inject, InjectionToken} from '@angular/core';

import {GAME_APP_AVAILABILITY_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_GAME_APP_AVAILABILITY_METHOD = new InjectionToken(
  'AZA_GATEWAY_GAME_APP_AVAILABILITY_METHOD',
  {
    providedIn: 'root',
    factory: () =>
      GAME_APP_AVAILABILITY_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  },
);
