import {Injectable} from '@angular/core';
import {
  EMPTY,
  mergeMap,
  Observable,
  repeat,
  ReplaySubject,
  share,
  startWith,
  Subject,
  switchMap,
  tap,
  timer,
} from 'rxjs';

import {Me} from '@azarus/api-contract';
import {ApiGatewayUserService} from '@azarus/frontend/api/gateway';

@Injectable({providedIn: 'root'})
export class UserInfoActionService {
  private _invalidateUserSource$ = new Subject<void>();
  /**
   * Some requests that update user entity return updated object, so it is faster
   * to simply pass that value into the user source instead of invalidating
   * the source and waiting for another request to be complete
   *
   * Emit when need to update the user source with already available new data
   *
   * Invalidating user data will complete and recreate this subject
   * @private
   */
  private _userUpdate$: Subject<Me> = new Subject();

  public readonly info$: Observable<Me> = this._userApi.getUser().pipe(
    repeat({delay: () => this._invalidateUserSource$}),
    switchMap((user) => this._userUpdate$.pipe(startWith(user))),
    share({
      connector: () => new ReplaySubject<Me>(1),
      resetOnRefCountZero: () => timer(2000),
      resetOnComplete: () => timer(2000),
      resetOnError: true,
    }),
  );

  public constructor(private readonly _userApi: ApiGatewayUserService) {}

  public invalidateUserInfo(): void {
    this._invalidateUserSource$.next();
  }

  public acceptTermsOfService(): Observable<never> {
    return this._userApi.acceptTermsOfService().pipe(
      tap((user) => {
        this._userUpdate$.next(user);
      }),
      mergeMap(() => EMPTY),
    );
  }

  public resetUserState(): void {
    this._invalidateUserSource$.complete();
    this._invalidateUserSource$ = new Subject<void>();

    this._userUpdate$.complete();
    this._userUpdate$ = new Subject<Me>();
  }
}
