import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BalanceManagementCurrentCustomTagBalanceNextDto} from './balance-management-user-current-custom-tag-balance-next.dto';
import {BalanceManagementCurrentCustomTagBalanceParamstDto} from './balance-management-user-current-custom-tag-balance-params.dto';

export const BALANCE_MANAGEMENT_CURRENT_CUSTOM_TAG_BALANCE_REF =
  new AzaRrpcPersistentRef(
    ['balanceManagement', 'currentCustomTagBalance'],
    BalanceManagementCurrentCustomTagBalanceParamstDto,
    asClass(BalanceManagementCurrentCustomTagBalanceNextDto),
  );
