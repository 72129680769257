import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionNotifyVideoAdStateChangeParamsDto} from './notify-video-ad-state-change-params.dto';

export const API_BROWSER_EXTENSION_NOTIFY_VIDEO_AD_STATE_CHANGE_REF =
  new AzaCdkMessagingRef(
    'notifyVideoAdStateChange',
    ApiBrowserExtensionNotifyVideoAdStateChangeParamsDto,
    asNever(),
  );
