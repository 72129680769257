import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import {isScullyRunning} from '@scullyio/ng-lib';
import {map} from 'rxjs';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {AZA_CORE_DISCORD_URL} from '@azarus/frontend/core/const/discord-url';

import {AsyncAuthService} from './auth/async-auth.service';
import {UserInfoActionService} from './services/user-info-action.service';

@Component({
  selector: 'aza-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends AzaCdkHsvsDirective {
  public readonly discordLink = AZA_CORE_DISCORD_URL;

  public readonly isScullyRunning = isScullyRunning();

  public readonly isUserAuth$ = inject(AsyncAuthService).isAuthorized$;
  public readonly hasMocaId$ = inject(UserInfoActionService).info$.pipe(
    map(
      ({mocaVerseData}) =>
        mocaVerseData.realmId === null &&
        mocaVerseData.partnerJwt !== null &&
        mocaVerseData.partnerJwtExpAt !== null,
    ),
  );

  public constructor() {
    super('aza', 'appRoot');

    // Banner showing
    // if (!isScullyRunning()) {
    //   router.events
    //     .pipe(
    //       filter((event) => event instanceof NavigationEnd),
    //       debounceTime(1000),
    //     )
    //     .subscribe(() => {
    //       greeting.showIfNotShownToday();
    //     });
    // }
  }
}
