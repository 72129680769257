import {animate, style, transition, trigger} from '@angular/animations';

export const AZA_CORE_GROW_CURRENCY_AMOUNT_ANIMATION = trigger(
  'growCurrencyAmount',
  [
    transition(':increment', [
      style({width: '{{ rowWidthStart }}px'}),
      animate('150ms ease', style({width: '*'})),
    ]),
  ],
);
