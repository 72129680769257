import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AzaCoreLetModule} from '@azarus/frontend/core/modules/let/let.module';
import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {LogoModule} from '../logo/logo.module';
import {VerticalMenuItemModule} from '../vertical-menu-item/vertical-menu-item.module';

import {MobileMenuComponent} from './mobile-menu.component';
import {MobileMenuBaseComponent} from './mobile-menu-base.component';
import {MobileMenuContentDirective} from './mobile-menu-content.directive';

@NgModule({
  declarations: [
    MobileMenuComponent,
    MobileMenuBaseComponent,
    MobileMenuContentDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LogoModule,
    VerticalMenuItemModule,
    AzaCoreSvgIconModule,
    AzaCoreLetModule,
  ],
  exports: [
    MobileMenuComponent,
    MobileMenuBaseComponent,
    MobileMenuContentDirective,
  ],
})
export class MobileMenuModule {}
