import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BalanceManagementAzarusCurrentBalanceNextDto} from './balance-management-azarus-current-balance-next.dto';
import {BalanceManagementAzarusCurrentBalanceParamstDto} from './balance-management-azarus-current-balance-params.dto';

export const BALANCE_MANAGEMENT_AZARUS_CURRENT_BALANCE_REF =
  new AzaRrpcPersistentRef(
    ['balanceManagement', 'azarusCurrentBalance'],
    BalanceManagementAzarusCurrentBalanceParamstDto,
    asClass(BalanceManagementAzarusCurrentBalanceNextDto),
  );
