import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {UnwrapCorrelationId} from '../../balance-management/api/transactions/unwrap-correlation-id';
import {AccessToken} from '../../identity-management/api/auth/access-token';
import {SecureParams} from '../../secure-params';

export class BlockchainBridgeUnwrapTransactionStateParamstDto
  implements SecureParams
{
  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  @field(asString<UnwrapCorrelationId>())
  public readonly correlationId: UnwrapCorrelationId;

  public constructor(
    accessToken: BlockchainBridgeUnwrapTransactionStateParamstDto['accessToken'],
    correlationId: BlockchainBridgeUnwrapTransactionStateParamstDto['correlationId'],
  ) {
    this.accessToken = accessToken;
    this.correlationId = correlationId;
  }
}
