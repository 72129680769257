import {Inject, ModuleWithProviders, NgModule} from '@angular/core';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {SnowplowService} from '../services/snowplow.service';
import {SNOWPLOW_TRACKER_COLLECTOR_DOMAIN} from '../tokens/tracker-collector-domain';
import {SNOWPLOW_TRACKER_CONFIG} from '../tokens/tracker-config';
import {SNOWPLOW_TRACKER_NAME} from '../tokens/tracker-name';
import {SnowplowConfig} from '../types/config';

@NgModule()
export class SnowplowModule<C extends string, A extends string> {
  public static forRoot<C extends string, A extends string>(
    name: string,
    collectorDomain: string,
    config: Partial<SnowplowConfig>,
  ): ModuleWithProviders<SnowplowModule<C, A>> {
    return {
      ngModule: SnowplowModule,
      providers: [
        {provide: SNOWPLOW_TRACKER_NAME, useValue: name},
        {provide: SNOWPLOW_TRACKER_CONFIG, useValue: config},
        {
          provide: SNOWPLOW_TRACKER_COLLECTOR_DOMAIN,
          useValue: collectorDomain,
        },
      ],
    };
  }

  public constructor(
    snowplowService: SnowplowService<C, A>,
    @Inject(SNOWPLOW_TRACKER_CONFIG)
    config: AzaCdkInjectionTokenType<typeof SNOWPLOW_TRACKER_CONFIG>,
    @Inject(SNOWPLOW_TRACKER_COLLECTOR_DOMAIN)
    collectorDomain: AzaCdkInjectionTokenType<
      typeof SNOWPLOW_TRACKER_COLLECTOR_DOMAIN
    >,
  ) {
    snowplowService.newTracker(collectorDomain, config);
  }
}
