import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionIsChannelAllowsGameParamsDto} from './is-channel-allows-game-params.dto';

export const API_BROWSER_EXTENSION_IS_CHANNEL_ALLOWS_GAME_REF =
  new AzaCdkMessagingRef(
    'signIn',
    ApiBrowserExtensionIsChannelAllowsGameParamsDto,
    asNever(),
  );
