import {inject, InjectionToken} from '@angular/core';

import {API_BROWSER_EXTENSION_MESSAGING_CLIENT} from '../../tokens';

import {API_BROWSER_EXTENSION_SIGN_IN_REF} from './sign-in.ref';

export const API_BROWSER_EXTENSION_SIGN_IN_METHOD = new InjectionToken(
  'API_BROWSER_EXTENSION_SIGN_IN_METHOD',
  {
    providedIn: 'root',
    factory: () =>
      API_BROWSER_EXTENSION_SIGN_IN_REF.connect(
        inject(API_BROWSER_EXTENSION_MESSAGING_CLIENT),
      ),
  },
);
