import {InjectionToken} from '@angular/core';

/**
 * Provides a map of symbols to their weight in stringified numbers
 * Typically weight can be defined by checking width of an element containing
 * the symbols with the same font size
 *
 * The default value is defined for Prompt font
 * Provide a different map on app/lazy loaded module/component level when
 * using a different font
 *
 * For monospace fonts all weights should be 1
 */
export const AZA_CORE_ANIMATED_CURRENCY_AMOUNT_WEIGHT = new InjectionToken<
  ReadonlyMap<string, number>
>('AZA_CORE_ANIMATED_CURRENCY_AMOUNT_WEIGHT', {
  providedIn: 'root',
  factory: () =>
    new Map([
      ['1', 6],
      ['2', 10],
      ['3', 9],
      ['4', 11],
      ['5', 9],
      ['6', 10],
      ['7', 9],
      ['8', 10],
      ['9', 10],
      ['0', 11],
      ['.', 3],
      [',', 5],
      [' ', 4],
    ]),
});
