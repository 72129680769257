import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreDropdownBase} from './dropdown.base';
import {AZA_CORE_DROPDOWN_REF} from './dropdown-ref';

@Component({
  selector: 'aza-core-dropdown',
  templateUrl: './dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AZA_CORE_DROPDOWN_REF,
      useExisting: AzaCoreDropdownComponent,
    },
  ],
})
export class AzaCoreDropdownComponent extends AzaCoreDropdownBase {}
