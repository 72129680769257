import {azaCoreStreamer} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const STREAMER_DASHBOARD_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.STREAMER_DASHBOARD],
  label: $localize`Streamer Dashboard`,
  icon: azaCoreStreamer,
};
