import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionSignInParamsDto} from './sign-in-params.dto';

export const API_BROWSER_EXTENSION_SIGN_IN_REF = new AzaCdkMessagingRef(
  'signIn',
  ApiBrowserExtensionSignInParamsDto,
  asNever(),
);
