<svg
  [class]="view('svg')"
  fill="none"
  viewBox="0 0 36 36"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M36 18a18 18 0 1 1-36 0 18 18 0 0 1 36 0Z" fill="#FFB422" />
  <path
    d="M31.9 18a13.9 13.9 0 1 1-27.8 0 13.9 13.9 0 0 1 27.8 0Z"
    fill="#FFE8A3"
  />
  <path
    d="m21.4 24.95-3.6-12.02-.53-3.36h2.4l5.38 15.38H21.4Zm-3.28-12.02-3.6 12.02h-3.64l5.36-15.38h2.42l-.54 3.36Z"
    fill="#C78E3A"
  />
</svg>
