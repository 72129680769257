import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BalanceManagementCurrentBalanceNextDto} from './balance-management-user-current-balance-next.dto';
import {BalanceManagementCurrentBalanceParamstDto} from './balance-management-user-current-balance-params.dto';

export const BALANCE_MANAGEMENT_CURRENT_BALANCE_REF = new AzaRrpcPersistentRef(
  ['balanceManagement', 'currentBalance'],
  BalanceManagementCurrentBalanceParamstDto,
  asClass(BalanceManagementCurrentBalanceNextDto),
);
