import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  ConfirmUnwrapTransferRes,
  GetEstimatedFeeRequest,
  GetEstimatedFeeRes,
  StartUnwrapTransferRequest,
  StartUnwrapTransferRes,
  UnwrapTransferDetailsRes,
  VerifyUnwrapTransferRequest,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayBlockchainBridgeService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getEstimatedFee(
    body: GetEstimatedFeeRequest,
  ): Observable<GetEstimatedFeeRes> {
    return this._http.post<GetEstimatedFeeRes>(
      `${this._httpUrl}/blockchain-bridge/estimated-fee`,
      body,
    );
  }

  public startUnwrapTransfer(
    body: StartUnwrapTransferRequest,
  ): Observable<StartUnwrapTransferRes> {
    return this._http.post<StartUnwrapTransferRes>(
      `${this._httpUrl}/blockchain-bridge/unwrap/start`,
      body,
    );
  }

  public verifyTransfer(body: VerifyUnwrapTransferRequest): Observable<void> {
    return this._http.post<void>(
      `${this._httpUrl}/blockchain-bridge/unwrap/verify`,
      body,
    );
  }

  public confirmTransfer(): Observable<ConfirmUnwrapTransferRes> {
    return this._http.post<ConfirmUnwrapTransferRes>(
      `${this._httpUrl}/blockchain-bridge/unwrap/confirm`,
      {},
    );
  }

  public getTransferInfo(): Observable<UnwrapTransferDetailsRes> {
    return this._http.get<UnwrapTransferDetailsRes>(
      `${this._httpUrl}/blockchain-bridge/unwrap/transfer`,
    );
  }
}
