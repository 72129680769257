import {azaCoreLogOut} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const LOGOUT_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.LOGOUT],
  label: $localize`Log Out`,
  icon: azaCoreLogOut,
};
