import {formatNumber} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {AZA_CORE_ANIMATED_CURRENCY_AMOUNT_WEIGHT} from './animated-currency-amount-weight-map';

@Pipe({
  name: 'azaCoreCurrencyAmountWeight',
})
export class AzaCoreCurrencyAmountWeightPipe implements PipeTransform {
  public constructor(
    @Inject(LOCALE_ID)
    private readonly _locale: AzaCdkInjectionTokenType<typeof LOCALE_ID>,
    @Inject(AZA_CORE_ANIMATED_CURRENCY_AMOUNT_WEIGHT)
    private readonly _weightMap: AzaCdkInjectionTokenType<
      typeof AZA_CORE_ANIMATED_CURRENCY_AMOUNT_WEIGHT
    >,
  ) {}

  public transform(value: number): number {
    const stringifiedNumber = formatNumber(value, this._locale);
    return Array.from(stringifiedNumber).reduce(
      (acc, number) => acc + (this._weightMap.get(number) ?? 0),
      0,
    );
  }
}
