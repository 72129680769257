import {inject, InjectionToken} from '@angular/core';
import {map, switchMap} from 'rxjs';

import {retentionState} from '@azarus/common/rx/retention-state';
import {API_GATEWAY_USER_CURRENT_BALANCE_METHOD} from '@azarus/frontend/api/gateway';

import {AsyncAuthService} from '../auth/async-auth.service';

export const USER_BALANCE = new InjectionToken('USER_BALANCE', {
  providedIn: 'root',
  factory: () => {
    const token = inject(AsyncAuthService).getToken();

    const balance = inject(API_GATEWAY_USER_CURRENT_BALANCE_METHOD);

    return token.pipe(
      switchMap((token) => {
        if (token === null) {
          throw new Error('Token is invalidated');
        }

        return balance(token).pipe(map(({balance}) => balance));
      }),
      retentionState(2000),
    );
  },
});
