import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {UserInfoActionService} from '../../services/user-info-action.service';

@Component({
  selector: 'aza-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent extends AzaCdkHsvsDirective {
  public readonly user$ = this._userSource$.info$;

  public constructor(private readonly _userSource$: UserInfoActionService) {
    super('aza', 'user');
  }
}
