import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreSvgIconBase} from './svg-icon.base';

@Component({
  selector: 'aza-core-svg-icon',
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzaCoreSvgIconComponent extends AzaCoreSvgIconBase {}
