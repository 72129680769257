import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AzaCoreCurrencyAmountBase} from './currency-amount.base';

@Component({
  selector: 'aza-core-currency-amount',
  templateUrl: './currency-amount.component.html',
  styleUrls: ['./currency-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzaCoreCurrencyAmountComponent extends AzaCoreCurrencyAmountBase {}
