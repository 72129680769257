import {asClass} from '@azarus/common/transformer/class/as-class';
import {field} from '@azarus/common/transformer/class/field';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';

import {CurrentYoutubeGameSessionData} from './current-youtube-game-session-data';

export class CurrentYoutubeGameSessionNextDto {
  @field(asNullable(asClass(CurrentYoutubeGameSessionData)))
  public readonly data: CurrentYoutubeGameSessionData | null;

  public constructor(data: CurrentYoutubeGameSessionNextDto['data']) {
    this.data = data;
  }
}
