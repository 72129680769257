import {inject, Injectable} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import {AccessToken} from '@azarus/api-contract';

import {API_GAME_WS_URL} from '../../../../tokens';
import {ApiGamePlatformsId} from '../../../../types';
import {ApiGamePlatformPartySessionState} from '../../types';

@Injectable({providedIn: 'root'})
export class ApiGamePlatformPartySessionStateService {
  public readonly wsUrl = inject(API_GAME_WS_URL);

  public getPlatformPartySessionState(
    jwtToke: AccessToken,
    platformId: ApiGamePlatformsId,
  ): WebSocketSubject<ApiGamePlatformPartySessionState> {
    return webSocket<ApiGamePlatformPartySessionState>(
      `wss://${this.wsUrl}?token=${jwtToke}&platformId=${platformId}`,
    );
  }
}
