import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {azaCoreLightMode} from '@azarus/frontend/core/icons';

import {LOGOUT_NAV_ITEM} from '../../top-level-components/constants/logout-nav-item';

import {MobileMenuContentDirective} from './mobile-menu-content.directive';

@Component({
  selector: 'aza-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MobileMenuComponent extends AzaCdkHsvsDirective {
  @ContentChild(MobileMenuContentDirective, {read: TemplateRef})
  public readonly mobileContent!: TemplateRef<unknown>;

  public readonly logoutNavItem = LOGOUT_NAV_ITEM;

  public readonly lightThemeIcon = azaCoreLightMode;

  public constructor() {
    super('aza', 'mobileMenu');
  }
}
