import {ViewportScroller} from '@angular/common';
import {NgModule, OnDestroy} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {
  NavigationEnd,
  Router,
  RouterModule,
  Routes,
  Scroll,
} from '@angular/router';
import {
  asyncScheduler,
  filter,
  observeOn,
  pairwise,
  startWith,
  Subscription,
} from 'rxjs';

import {classPredicate} from '@azarus/common/utils/class-predicate';
import {isExist} from '@azarus/common/utils/is-exist';
import {AzaCdkDialogOutletModule} from '@azarus/frontend/cdk/dialog-outlet/dialog-outlet.module';
import {AzaCdkRouterResolverHelperService} from '@azarus/frontend/cdk/router-resolver-helper/router-resolver-helper.service';
import {AzaScullyOpenGraph} from '@azarus/frontend/scully/seo/open-graph';

import {AppRouteSegment} from './app-route-segment.enum';
import {canActivateLogout} from './auth/can-activate-logout';
import {canActivateMocaConnectId} from './guards/can-activate-moca-connect-id';
import {canMatchWithOptionalSignupComplete} from './guards/can-match-with-optional-signup-complete';
import {canMatchWithSignupComplete} from './guards/can-match-with-signup-complete';
import {canMatchWithSignupIncomplete} from './guards/can-match-with-signup-incomplete';
import {NewsRouteParam} from './pages/news-post-page/news-route-param';
import {PaymentRouteParams} from './pages/payment-page/types/payment-route-params';
import {resolveOptionalUserBalance} from './resolve/resolve-optional-user-balance';
import {resolveOptionalUserInfo} from './resolve/resolve-optional-user-info';
import {DESCRIPTION_RESOLVE_KEY} from './resolve-key/description-resolve-key';
import {OPEN_GRAPH_RESOLVE_KEY} from './resolve-key/open-graph-resolve-key';
import {TITLE_RESOLVE_KEY} from './resolve-key/title-resolve-key';
import {LandingComponent} from './top-level-components/landing/landing.component';
import {LandingWrapperComponent} from './top-level-components/landing-wrapper/landing-wrapper.component';
import {DIALOG_OUTLET} from './types/dialog-outlet';

const routes: Routes = [
  {
    path: AppRouteSegment.ROOT,
    component: LandingComponent,
    resolve: {
      user: resolveOptionalUserInfo,
      balance: resolveOptionalUserBalance,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/viewer-page/viewer-page.module').then(
            (m) => m.ViewerPageModule,
          ),
      },
      {
        path: '',
        component: LandingWrapperComponent,
        children: [
          {
            path: AppRouteSegment.LOGIN,
            canMatch: [canMatchWithSignupIncomplete],
            loadChildren: () =>
              import('./pages/login-page/login-page.module').then(
                (m) => m.LoginPageModule,
              ),
          },
          {
            path: AppRouteSegment.STREAMER,
            loadChildren: () =>
              import('./pages/streamer-page/streamer-page.module').then(
                (m) => m.StreamerPageModule,
              ),
          },
          {
            path: AppRouteSegment.AZA_COIN,
            loadChildren: () =>
              import('./pages/aza-coin-page/aza-coin-page.module').then(
                (m) => m.AzaCoinPageModule,
              ),
          },
          {
            path: AppRouteSegment.SHOP,
            loadChildren: () =>
              import('./pages/shop-page/shop-page.module').then(
                (m) => m.ShopPageModule,
              ),
          },
          {
            path: AppRouteSegment.NEWS,
            loadChildren: () =>
              import('./pages/news-page/news-page.module').then(
                (m) => m.NewsPageModule,
              ),
          },
          {
            path: `${AppRouteSegment.NEWS}/:${NewsRouteParam.URL_SEGMENT}`,
            loadChildren: () =>
              import('./pages/news-post-page/news-post-page.module').then(
                (m) => m.NewsPostPageModule,
              ),
          },
          {
            path: AppRouteSegment.ABOUT_AZARUS,
            loadChildren: () =>
              import('./pages/about-azarus-page/about-azarus-page.module').then(
                (m) => m.AboutAzarusPageModule,
              ),
          },
          {
            path: AppRouteSegment.CONTACTS,
            loadChildren: () =>
              import('./pages/contacts-page/contacts-page.module').then(
                (m) => m.ContactsPageModule,
              ),
          },
          {
            path: AppRouteSegment.FAQ,
            loadChildren: () =>
              import('./pages/faq-page/faq-page.module').then(
                (m) => m.FaqPageModule,
              ),
          },
          {
            path: AppRouteSegment.WALLET,
            canMatch: [canMatchWithSignupComplete],
            loadChildren: () =>
              import('./pages/wallet-page/wallet-page.module').then(
                (m) => m.WalletPageModule,
              ),
          },
          {
            path: AppRouteSegment.DATA_COLLECTION_PERMISSIONS,
            canMatch: [canMatchWithSignupComplete],
            loadChildren: () =>
              import(
                './pages/data-collection-permissions-page/data-collection-permissions-page.module'
              ).then((m) => m.DataCollectionPermissionsPageModule),
          },
          {
            path: `${AppRouteSegment.PAYMENT}/:${PaymentRouteParams.ITEM_ID}`,
            canMatch: [canMatchWithSignupComplete],
            loadChildren: () =>
              import('./pages/payment-page/payment-page.module').then(
                (m) => m.PaymentPageModule,
              ),
          },
          {
            path: AppRouteSegment.ADVERTISE,
            loadChildren: () =>
              import('./pages/advertise-page/advertise-page.module').then(
                (m) => m.AdvertisePageModule,
              ),
          },
          {
            path: AppRouteSegment.GET_MORE_ENTRIES,
            loadChildren: () =>
              import(
                './pages/get-more-entries/get-more-entries-page.module'
              ).then((m) => m.GetMoreEntriesModule),
          },
        ],
      },
    ],
  },
  {
    path: AppRouteSegment.STREAMER_DASHBOARD,
    canMatch: [canMatchWithSignupComplete],
    loadChildren: () =>
      import('./pages/streamer-dashboard/streamer-dashboard.module').then(
        (m) => m.StreamerDashboardModule,
      ),
  },
  {
    path: AppRouteSegment.DIALOG_STORE_ITEM_PURCHASE,
    outlet: DIALOG_OUTLET,
    data: {
      config: {
        maxWidth: 'clamp(330px, 90vw, 800px)',
        panelClass: 'aza-dialog',
      },
    },
    loadChildren: () =>
      import(
        './modules/shop-item-purchase-dialog/shop-item-purchase-dialog.module'
      ).then((m) => m.ShopItemPurchaseDialogModule),
    canMatch: [canMatchWithOptionalSignupComplete],
  },
  {
    path: AppRouteSegment.DIALOG_PURCHASE_WITH_COINBASE_SUCCESS,
    outlet: DIALOG_OUTLET,
    data: {
      config: {
        maxWidth: 'clamp(330px, 90vw, 541px)',
        panelClass: 'aza-dialog',
        closeOnNavigation: true,
      },
    },
    loadChildren: () =>
      import(
        './modules/purchase-with-coinbase-success-dialog/purchase-with-coinbase-success-dialog.module'
      ).then((m) => m.PurchaseWithCoinbaseSuccessDialogModule),
  },
  {
    path: AppRouteSegment.LOGOUT,
    canActivate: [canActivateLogout],
    runGuardsAndResolvers: 'always',
    children: [],
  },
  {
    path: AppRouteSegment.CONNECT_MOCA_ID,
    canActivate: [canActivateMocaConnectId],
    runGuardsAndResolvers: 'always',
    children: [],
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/not-found-page/not-found-page.module').then(
        (m) => m.NotFoundPageModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
    }),
    AzaCdkDialogOutletModule.forRoot(DIALOG_OUTLET),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule implements OnDestroy {
  private readonly _subscriptions = new Subscription();

  public constructor(
    router: Router,
    private readonly _viewportScroller: ViewportScroller,
    resolverHelper: AzaCdkRouterResolverHelperService,
    titleService: Title,
    private readonly _meta: Meta,
  ) {
    this._subscriptions.add(
      router.events
        .pipe(
          filter(classPredicate(Scroll)),
          startWith(null),
          pairwise(),
          observeOn(asyncScheduler),
        )
        .subscribe(([previousEvent, currentEvent]) => {
          this._handleNavigationScroll(previousEvent, currentEvent);
        }),
    );

    this._subscriptions.add(
      resolverHelper.stackByKey(TITLE_RESOLVE_KEY).subscribe((titles) => {
        const name: string = $localize`Azarus game challenge network`;

        titleService.setTitle([...titles.reverse(), name].join(' | '));
      }),
    );

    this._subscriptions.add(
      resolverHelper
        .deepestByKey(DESCRIPTION_RESOLVE_KEY, 'primary', () => null)
        .subscribe((description) => {
          this._updateDescription(description);
        }),
    );

    this._subscriptions.add(
      resolverHelper
        .deepestByKey(OPEN_GRAPH_RESOLVE_KEY, 'primary', () => null)
        .subscribe((openGraph) => {
          this._updateOpenGraph(openGraph);
        }),
    );
  }

  private _updateDescription(description: string | null): void {
    for (const tag of this._meta.getTags('name=description')) {
      tag.remove();
    }

    if (description === null) {
      return;
    }

    this._meta.updateTag({name: 'description', content: description});
  }

  private _updateOpenGraph(openGraph: AzaScullyOpenGraph | null): void {
    for (const tag of this._meta.getTags('name^="og:"')) {
      tag.remove();
    }

    if (openGraph === null) {
      return;
    }

    for (const [key, content] of Object.entries(openGraph)) {
      this._meta.updateTag({name: `og:${key}`, content});
    }
  }

  private _getScrollEventUrl(scroll: Scroll): string {
    let url =
      scroll.routerEvent instanceof NavigationEnd
        ? scroll.routerEvent.urlAfterRedirects
        : scroll.routerEvent.url;
    return url.split('?')[0];
  }

  private _handleNavigationScroll(
    previousEvent: Scroll | null,
    currentEvent: Scroll | null,
  ): void {
    if (currentEvent === null) {
      return;
    }

    // sometimes it is undefined although not typed properly
    if (isExist(currentEvent.position)) {
      // backward navigation
      this._viewportScroller.scrollToPosition(currentEvent.position);
      return;
    }

    if (isExist(currentEvent.anchor)) {
      // anchor navigation
      document.getElementById(currentEvent.anchor)?.scrollIntoView();
      return;
    }

    if (previousEvent === null) {
      return;
    }

    const previousUrl = this._getScrollEventUrl(previousEvent);
    const currentUrl = this._getScrollEventUrl(currentEvent);
    if (previousUrl !== currentUrl) {
      // Routes don't match, this is actual forward navigation
      // Default behavior: scroll to top
      // forward navigation
      this._viewportScroller.scrollToPosition([0, 0]);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
