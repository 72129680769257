import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';
import {asEnum} from '@azarus/common/transformer/enum/as-enum';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';

import {Platform} from '../../balance-management';
import {AccessToken} from '../../identity-management/api/auth/access-token';
import {SecureParams} from '../../secure-params';

export class ChannelPresenceHeartbeatParamsDto implements SecureParams {
  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  @field(asEnum(Platform))
  public readonly platform: Platform;

  @field(asString())
  public readonly nameOnPlatform: string;

  @field(asNullable(asString()))
  public readonly playingDocument: string | null = null;

  @field(asBoolean())
  public readonly isLive: boolean;

  public constructor(
    accessToken: ChannelPresenceHeartbeatParamsDto['accessToken'],
    platform: ChannelPresenceHeartbeatParamsDto['platform'],
    nameOnPlatform: ChannelPresenceHeartbeatParamsDto['nameOnPlatform'],
    playingDocument: ChannelPresenceHeartbeatParamsDto['playingDocument'] = null,
    isLive: ChannelPresenceHeartbeatParamsDto['isLive'],
  ) {
    this.accessToken = accessToken;
    this.platform = platform;
    this.nameOnPlatform = nameOnPlatform;
    this.playingDocument = playingDocument;
    this.isLive = isLive;
  }
}
