import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {AppRouteSegment} from '../../app-route-segment.enum';

@Component({
  selector: 'aza-moca-widget',
  templateUrl: './moca-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MocaWidgetComponent extends AzaCdkHsvsDirective {
  public readonly connectMocaIdPageLink = [
    AppRouteSegment.ROOT,
    AppRouteSegment.CONNECT_MOCA_ID,
  ];

  public constructor() {
    super('aza', 'mocaWidget');
  }
}
