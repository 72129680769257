import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {LogoModule} from '../logo/logo.module';

import {HeaderComponent} from './header.component';
import {HeaderNavigationDirective} from './header-navigation.directive';
import {HeaderUserCornerDirective} from './header-user-corner.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNavigationDirective,
    HeaderUserCornerDirective,
  ],
  imports: [CommonModule, LogoModule, RouterModule],
  exports: [
    HeaderComponent,
    HeaderNavigationDirective,
    HeaderUserCornerDirective,
  ],
})
export class HeaderModule {}
