import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

const NOT_A_NUMBER_REPRESENTATION = 'NaN';
const POSITIVE_INFINITY_REPRESENTATION = 'Infinity';
const NEGATIVE_INFINITY_REPRESENTATION = '-Infinity';

// TODO specs
export class NumberTransformer extends ValueTransformer<number> {
  public static readonly simple = new NumberTransformer();

  private constructor() {
    super();
  }

  public dataToLiteral(data: number): unknown {
    if (Number.isNaN(data)) {
      return NOT_A_NUMBER_REPRESENTATION;
    }

    if (data === Number.POSITIVE_INFINITY) {
      return POSITIVE_INFINITY_REPRESENTATION;
    }

    if (data === Number.NEGATIVE_INFINITY) {
      return NEGATIVE_INFINITY_REPRESENTATION;
    }

    return data;
  }

  public literalToData(literal: unknown, path: LiteralPath): number {
    switch (literal) {
      case NOT_A_NUMBER_REPRESENTATION:
        return Number.NaN;
      case POSITIVE_INFINITY_REPRESENTATION:
        return Number.POSITIVE_INFINITY;
      case NEGATIVE_INFINITY_REPRESENTATION:
        return Number.NEGATIVE_INFINITY;
    }

    if (typeof literal !== 'number') {
      throw new LiteralDeserializationError(literal, path);
    }

    return literal;
  }

  public isSupport(data: unknown): data is number {
    return typeof data === 'number';
  }
}
