import {inject, InjectionToken} from '@angular/core';

import {API_BROWSER_EXTENSION_MESSAGING_CLIENT} from '../../tokens';

import {API_BROWSER_EXTENSION_IS_CHANNEL_ALLOWS_GAME_REF} from './is-channel-allows-game.ref';

export const API_BROWSER_EXTENSION_IS_CHANNEL_ALLOWS_GAME_METHOD =
  new InjectionToken('API_BROWSER_EXTENSION_IS_CHANNEL_ALLOWS_GAME_METHOD', {
    providedIn: 'root',
    factory: () =>
      API_BROWSER_EXTENSION_IS_CHANNEL_ALLOWS_GAME_REF.connect(
        inject(API_BROWSER_EXTENSION_MESSAGING_CLIENT),
      ),
  });
