import {inject} from '@angular/core';
import {CanMatchFn, Router} from '@angular/router';
import {map, take} from 'rxjs';

import {AppRouteSegment} from '../app-route-segment.enum';
import {SignupStateService} from '../services/signup-state.service';

export const canMatchWithSignupIncomplete: CanMatchFn = () => {
  const signupStateService = inject(SignupStateService);
  const router = inject(Router);

  return signupStateService.signupComplete$.pipe(
    take(1),
    map((signupComplete) => {
      if (signupComplete) {
        return router.createUrlTree([
          AppRouteSegment.ROOT,
          AppRouteSegment.SHOP,
        ]);
      }
      return true;
    }),
  );
};
