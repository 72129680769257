import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

import {NeverSerializationError} from './never-serialization-error';

// TODO specs
export class NeverTransformer extends ValueTransformer<never> {
  public static readonly simple = new NeverTransformer();

  private constructor() {
    super();
  }

  public dataToLiteral(data: never): unknown {
    throw new NeverSerializationError(data);
  }

  public literalToData(literal: unknown, path: LiteralPath): never {
    throw new LiteralDeserializationError(literal, path);
  }

  public isSupport(_data: unknown): _data is never {
    return false;
  }
}
