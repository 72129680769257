import {flatArray} from '../utils/flat-array';
import {join} from '../utils/join';
import {map} from '../utils/map';

import {LiteralPath} from './literal-path';

const COMMON_FIELD_NAME = /^[a-zA-Z_$][0-9a-zA-Z_$]*$/;

function readableFieldName(fieldName: string | number): string {
  if (typeof fieldName === 'string' && COMMON_FIELD_NAME.test(fieldName)) {
    return `.${fieldName}`;
  }

  return `[${JSON.stringify(fieldName)}]`;
}

export function readableLiteralPath(path: LiteralPath): string {
  return join(map(flatArray(path), readableFieldName));
}
