import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Component({
  selector: 'a[aza-discord-button]',
  templateUrl: './discord-button.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscordButtonComponent extends AzaCdkHsvsDirective {
  public constructor() {
    super('aza', 'discordButton');
  }
}
