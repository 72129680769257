import {Injectable} from '@angular/core';
import * as Bowser from 'bowser';
import {defer, Observable, of, shareReplay, startWith} from 'rxjs';

import {API_BROWSER_EXTENSION_INSTALLATION_INDICATION_ATTRIBUTE} from '@azarus/frontend/api/browser-extension';

import {SUPPORTED_BROWSERS} from '../const/supported-browsers';

@Injectable({providedIn: 'root'})
export class ExtensionDetectorService {
  private readonly _browser = Bowser.getParser(window.navigator.userAgent);
  private _extensionInstallationIndicationCache = false;

  public isInstalled(): boolean {
    if (!this._isBrowserSupported() || !this.isDesktop()) {
      return false;
    }
    return document.documentElement.hasAttribute(
      API_BROWSER_EXTENSION_INSTALLATION_INDICATION_ATTRIBUTE,
    );
  }

  public readonly isExtensionInstalled$: Observable<boolean> = defer(() => {
    if (!this._isBrowserSupported() || !this.isDesktop()) {
      return of(false);
    }

    if (this._extensionInstallationIndicationCache) {
      return of(true);
    }

    this._extensionInstallationIndicationCache =
      document.documentElement.hasAttribute(
        API_BROWSER_EXTENSION_INSTALLATION_INDICATION_ATTRIBUTE,
      );

    if (this._extensionInstallationIndicationCache) {
      return of(true);
    }

    return new Observable<boolean>((observer) => {
      const mutationObserver = new MutationObserver(() => {
        this._extensionInstallationIndicationCache = true;
        observer.next(true);
        observer.complete();
        mutationObserver.disconnect();
      });

      mutationObserver.observe(document.body, {
        attributes: true,
        attributeFilter: [
          API_BROWSER_EXTENSION_INSTALLATION_INDICATION_ATTRIBUTE,
        ],
      });
    }).pipe(startWith(false));
  }).pipe(shareReplay({bufferSize: 1, refCount: true}));

  private _isBrowserSupported(): boolean {
    return SUPPORTED_BROWSERS.includes(this._browser.getBrowserName());
  }

  public isDesktop(): boolean {
    return this._browser.getPlatformType() === Bowser.PLATFORMS_MAP.desktop;
  }
}
