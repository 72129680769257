import {asClass} from '@azarus/common/transformer/class/as-class';
import {asOneOf} from '@azarus/common/transformer/one-of/as-one-of';
import {AzaCdkExtensionEventRef} from '@azarus/frontend/cdk/extension-event/extension-event-ref';

import {ApiBrowserExtensionSignInEventDataDto} from './sign-in-event-data.dto';
import {ApiBrowserExtensionSignOutEventDataDto} from './sign-out-event-data.dto';

export const API_BROWSER_EXTENSION_AUTH_STATE_CHANGE_EVENT_REF =
  new AzaCdkExtensionEventRef(
    'authStateChangeEvent',
    asOneOf([
      asClass(ApiBrowserExtensionSignInEventDataDto),
      asClass(ApiBrowserExtensionSignOutEventDataDto),
    ]),
  );
