import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';

export class ApiBrowserExtensionNotifyVideoAdStateChangeParamsDto {
  @field(asBoolean())
  public readonly videoAdInProgress: boolean;

  public constructor(
    videoAdInProgress: ApiBrowserExtensionNotifyVideoAdStateChangeParamsDto['videoAdInProgress'],
  ) {
    this.videoAdInProgress = videoAdInProgress;
  }
}
