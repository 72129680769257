import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AzaCoreBadgeModule} from '../badge/badge.module';

import {AzaCoreNotificationCountComponent} from './notification-count.component';

@NgModule({
  imports: [AzaCoreBadgeModule, CommonModule],
  declarations: [AzaCoreNotificationCountComponent],
  exports: [AzaCoreNotificationCountComponent],
})
export class AzaCoreNotificationCountModule {}
