import {EnumDefinition} from '../../type/enum-definition';
import {EnumValue} from '../../type/enum-value';
import {isEnumValue} from '../../utils/is-enum-value';
import {LazyFactory} from '../../utils/lazy-factory';
import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

export class EnumTransformer<
  K extends string,
  V extends EnumValue,
> extends ValueTransformer<V> {
  private static readonly _cache = new LazyFactory();

  public static from<K extends string, V extends EnumValue>(
    definition: EnumDefinition<K, V>,
  ): EnumTransformer<K, V> {
    return this._cache.make(
      definition,
      () => new EnumTransformer<K, V>(definition),
    );
  }

  private constructor(private readonly _enumDefinition: EnumDefinition<K, V>) {
    super();
  }

  public dataToLiteral(data: V): unknown {
    return data;
  }

  public literalToData(literal: unknown, path: LiteralPath): V {
    if (!isEnumValue(literal, this._enumDefinition)) {
      throw new LiteralDeserializationError(literal, path);
    }

    return literal;
  }

  public isSupport(data: unknown): data is V {
    return isEnumValue(data, this._enumDefinition);
  }
}
