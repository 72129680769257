import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';

import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {DialogCloseButtonComponent} from './dialog-close-button.component';

@NgModule({
  declarations: [DialogCloseButtonComponent],
  imports: [AzaCoreSvgIconModule, MatDialogModule],
  exports: [DialogCloseButtonComponent],
})
export class DialogCloseButtonModule {}
