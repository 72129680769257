import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {AccessToken} from '../../identity-management';
import {SecureParams} from '../../secure-params';
import {GameAppId} from '../game-app-id';

export class GameAppAvailabilityParamsDto implements SecureParams {
  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  @field(asString<GameAppId>())
  public readonly gameAppId: GameAppId;

  public constructor(
    accessToken: GameAppAvailabilityParamsDto['accessToken'],
    gameAppId: GameAppAvailabilityParamsDto['gameAppId'],
  ) {
    this.accessToken = accessToken;
    this.gameAppId = gameAppId;
  }
}
