import {isUnknownObject} from '../../utils/is-unknown-object';
import {LazyFactory} from '../../utils/lazy-factory';
import {prototypeChain} from '../../utils/prototype-chain';
import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

import {ClassSerializationError} from './class-serialization-error';
import {classTransformerCache} from './class-transformer-cache';

// TODO specs
// TODO make more stricter types avoid unknown
function extractClassFieldsTransformers<T>(
  constructor: new (...args: never) => unknown,
): Map<keyof T, ValueTransformer<unknown>> {
  const result = new Map<keyof T, ValueTransformer<unknown>>();

  for (const prototype of prototypeChain(constructor.prototype)) {
    const ownClassTransformers =
      classTransformerCache.get<Map<keyof T, ValueTransformer<unknown>>>(
        prototype,
      );

    if (ownClassTransformers !== null) {
      for (const [key, transformer] of ownClassTransformers) {
        result.set(key, transformer);
      }
    }
  }

  return result;
}

export class ClassTransformer<T extends object> extends ValueTransformer<T> {
  private static readonly _cache = new LazyFactory();

  public static from<T extends object>(
    constructor: new (...args: never) => T,
  ): ClassTransformer<T> {
    return this._cache.make(
      constructor,
      () => new ClassTransformer<T>(constructor),
    );
  }

  private readonly _classFieldsTransformers: Map<
    keyof T & string,
    ValueTransformer<unknown>
  >;

  private constructor(
    private readonly _constructor: new (...args: never) => T,
  ) {
    super();
    this._classFieldsTransformers =
      extractClassFieldsTransformers(_constructor);
  }

  public dataToLiteral(data: T): unknown {
    const literal: Record<string, unknown> = {};

    for (const [key, transformer] of this._classFieldsTransformers) {
      if (!(key in data)) {
        throw new ClassSerializationError(data);
      }

      literal[key] = transformer.dataToLiteral(data[key]);
    }

    return literal;
  }

  public literalToData(literal: unknown, path: LiteralPath): T {
    if (!isUnknownObject(literal)) {
      throw new LiteralDeserializationError(literal, path);
    }

    const data: T = Object.create(this._constructor.prototype);

    for (const [key, transformer] of this._classFieldsTransformers) {
      // TODO remove any
      data[key] = transformer.literalToData(literal[key], [path, key]) as any;
    }

    return data;
  }

  public isSupport(data: unknown): data is T {
    return data instanceof this._constructor;
  }
}
