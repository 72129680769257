import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {GameSessionJoinableNextDto} from './game-session-joinable-next.dto';
import {GameSessionJoinableParamsDto} from './game-session-joinable-params.dto';

export const GAME_SESSION_JOINABLE_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'gameSessionJoinable'],
  GameSessionJoinableParamsDto,
  asClass(GameSessionJoinableNextDto),
);
