import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, switchMap} from 'rxjs';

import {API_GAME_HTTP_URL} from '@azarus/frontend/api/game';
import {API_GATEWAY_HTTP_URL} from '@azarus/frontend/api/gateway';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {AsyncAuthService} from '../auth/async-auth.service';

@Injectable({providedIn: 'root'})
export class AuthInterceptorService implements HttpInterceptor {
  public constructor(
    private readonly _asyncAuth: AsyncAuthService,
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _azarusApiOrigin: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    @Inject(API_GAME_HTTP_URL)
    private readonly _azarusGameApi: AzaCdkInjectionTokenType<
      typeof API_GAME_HTTP_URL
    >,
  ) {}

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const requestUrlOrigin = new URL(req.url, location.href).origin;

    if (
      (requestUrlOrigin !== this._azarusApiOrigin &&
        requestUrlOrigin !== this._azarusGameApi) ||
      req.headers.has('Authorization')
    ) {
      return next.handle(req);
    }

    return this._asyncAuth.getToken().pipe(
      map((accessToken) => {
        if (accessToken === null) {
          return req;
        }

        return req.clone({
          headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
        });
      }),
      switchMap((req) => next.handle(req)),
    );
  }
}
