import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MocaButtonModule} from '../moca-button/moca-button.module';

import {MocaWidgetComponent} from './moca-widget.component';

@NgModule({
  declarations: [MocaWidgetComponent],
  imports: [MocaButtonModule, RouterModule],
  exports: [MocaWidgetComponent],
})
export class MocaWidgetModule {}
