import {concatMap, EMPTY, fromEvent, Observable, of, share} from 'rxjs';

import {AzaCdkAbstractExtensionEventClient} from '../abstract-extension-event/abstract-extension-event-client';
import {isExtensionEvent} from '../abstract-extension-event/is-extension-event';

import {AzaCdkExtensionEvent} from './extension-event';

export class AzaCdkExtensionEventClient
  implements AzaCdkAbstractExtensionEventClient
{
  private readonly _messages$ = fromEvent<MessageEvent>(window, 'message').pipe(
    share({resetOnRefCountZero: true}),
  );

  public constructor(
    protected readonly _window: Window = window,
    protected readonly _eventOrigin: string = window.origin,
  ) {}

  public addListener(name: string): Observable<AzaCdkExtensionEvent<unknown>> {
    return this._messages$.pipe(
      concatMap((messageEvent) => {
        if (
          messageEvent.origin !== this._eventOrigin ||
          messageEvent.source !== this._window
        ) {
          return EMPTY;
        }

        const event = messageEvent.data;
        if (!isExtensionEvent(event) || event.name !== name) {
          return EMPTY;
        }

        return of(event);
      }),
    );
  }
}
