import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AzaCoreDropdownCommonModule} from './dropdown.common-module';
import {AzaCoreDropdownComponent} from './dropdown.component';

@NgModule({
  declarations: [AzaCoreDropdownComponent],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    AzaCoreDropdownCommonModule,
  ],
  exports: [AzaCoreDropdownCommonModule, AzaCoreDropdownComponent],
})
export class AzaCoreDropdownModule {}
