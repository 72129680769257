import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreAnimatedCurrencyAmountBase} from './animated-currency-amount.base';
import {AZA_CORE_FLIP_CURRENCY_AMOUNT_ANIMATION} from './animations/flip-currency-amount-animation';
import {AZA_CORE_GROW_CURRENCY_AMOUNT_ANIMATION} from './animations/grow-currency-amount-animation';
import {AZA_CORE_SHRINK_CURRENCY_AMOUNT_ANIMATION} from './animations/shrink-currency-amount-animation';

@Component({
  selector: 'aza-core-animated-currency-amount',
  templateUrl: './animated-currency-amount.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    AZA_CORE_FLIP_CURRENCY_AMOUNT_ANIMATION,
    AZA_CORE_GROW_CURRENCY_AMOUNT_ANIMATION,
    AZA_CORE_SHRINK_CURRENCY_AMOUNT_ANIMATION,
  ],
})
export class AzaCoreAnimatedCurrencyAmountComponent extends AzaCoreAnimatedCurrencyAmountBase {}
