import {AccessToken} from '@azarus/api-contract';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionGetAccessTokenParamsDto} from './get-access-token-params.dto';

export const API_BROWSER_EXTENSION_GET_ACCESS_TOKEN_REF =
  new AzaCdkMessagingRef(
    'getAccessToken',
    ApiBrowserExtensionGetAccessTokenParamsDto,
    asNullable(asString<AccessToken>()),
  );
