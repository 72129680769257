import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionNotifyButtonAreaChangeParamsDto} from './notify-button-area-change-params.dto';

export const API_BROWSER_EXTENSION_NOTIFY_BUTTON_AREA_CHANGE_REF =
  new AzaCdkMessagingRef(
    'notifyButtonAreaChange',
    ApiBrowserExtensionNotifyButtonAreaChangeParamsDto,
    asNever(),
  );
