import {
  APP_INITIALIZER,
  inject,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';

import {winnerTokenCatchInitializer} from './initializers/win-token-catch.initializer';
import {ATT_IMPORT_CODES_EXPIRATION_TIME} from './tokens/att-import-codes-expiration-time';
import {ATT_IMPORT_CODES_PARAM_NAME} from './tokens/att-import-codes-param-name';

@NgModule({})
export class AppAttModule {
  public static forRoot(
    importCodesParamName: string,
    importCodesExpirationTimeS: number,
  ): ModuleWithProviders<AppAttModule> {
    return {
      ngModule: AppAttModule,
      providers: [
        {
          provide: ATT_IMPORT_CODES_PARAM_NAME,
          useValue: importCodesParamName,
        },
        {
          provide: ATT_IMPORT_CODES_EXPIRATION_TIME,
          useValue: importCodesExpirationTimeS,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => {
            const paramName = inject(ATT_IMPORT_CODES_PARAM_NAME);
            const expTimeS = inject(ATT_IMPORT_CODES_EXPIRATION_TIME);
            return winnerTokenCatchInitializer(paramName, expTimeS);
          },
          multi: true,
        },
      ],
    };
  }
}
