import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

export class ApiBrowserExtensionSignOutEventDataDto {
  @field(asString()) public readonly reason:
    | 'MANUAL_SIGN_OUT'
    | 'USER_DELETED'
    | 'REFRESH_TOKEN_EXPIRED'
    | 'REFRESH_TOKEN_FAILED';

  public constructor(reason: ApiBrowserExtensionSignOutEventDataDto['reason']) {
    this.reason = reason;
  }
}
