export class LazyFactory {
  private readonly _cache = new WeakMap<object, unknown>();

  public constructor() {}

  public make<R>(target: object, handler: () => R): R {
    if (this._cache.has(target)) {
      return this._cache.get(target) as R;
    }

    const result: R = handler();

    this._cache.set(target, result);

    return result;
  }

  public get<R>(target: object): R | null {
    if (this._cache.has(target)) {
      return this._cache.get(target) as R;
    }

    return null;
  }
}
