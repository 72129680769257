import {isUnknownArray} from '../../utils/is-unknown-array';
import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

import {ArraySerializationError} from './array-serialization-error';

// TODO specs
// TODO maybe serialize arrays sparse, like {'0': 'a', '2': 'c', length: 3}
export class ArrayTransformer<T> extends ValueTransformer<T[]> {
  public constructor(private readonly _transformer: ValueTransformer<T>) {
    super();
  }

  public dataToLiteral(data: readonly T[]): unknown {
    const result: unknown[] = [];

    for (let index = 0; index < data.length; index++) {
      if (!(index in data)) {
        throw new ArraySerializationError(data);
      }

      result[index] = this._transformer.dataToLiteral(data[index]);
    }

    return result;
  }

  public literalToData(literal: unknown, path: LiteralPath): T[] {
    if (!isUnknownArray(literal)) {
      throw new LiteralDeserializationError(literal, path);
    }

    return literal.map<T>((item, index) =>
      this._transformer.literalToData(item, [path, index]),
    );
  }

  public isSupport(data: unknown): data is T[] {
    return (
      isUnknownArray(data) &&
      data.every((item) => this._transformer.isSupport(item))
    );
  }
}
