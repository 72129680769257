import {animate, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs';

import {isExist} from '@azarus/common/utils/is-exist';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {environment} from '../../../environments/environment';

import {HeaderNavigationDirective} from './header-navigation.directive';
import {HeaderUserCornerDirective} from './header-user-corner.directive';

@Component({
  selector: 'aza-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('loading', [
      transition(':enter', [style({opacity: 0}), animate('1000ms ease-out')]),
    ]),
  ],
})
export class HeaderComponent extends AzaCdkHsvsDirective {
  public readonly challengesSiteUrl = environment.challengesSiteUrl;

  public navigationContent: TemplateRef<unknown> | undefined = undefined;
  public userCornerContent: TemplateRef<unknown> | undefined = undefined;

  @ContentChild(HeaderNavigationDirective, {read: TemplateRef})
  public set _navigationContent(value: TemplateRef<unknown> | undefined) {
    if (value === undefined) {
      return;
    }

    this.navigationContent = value;
    this._cdr.markForCheck();
  }

  @ContentChild(HeaderUserCornerDirective, {read: TemplateRef})
  public set _userCornerContent(value: TemplateRef<unknown> | undefined) {
    if (value === undefined) {
      return;
    }

    this.userCornerContent = value;
    this._cdr.markForCheck();
  }

  public readonly isLoading$ = this._router.events.pipe(
    map((event) => {
      if (event instanceof NavigationStart) {
        return true;
      }

      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        return false;
      }

      return null;
    }),
    filter(isExist),
    distinctUntilChanged(),
  );

  public constructor(
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
  ) {
    super('aza', 'header');
  }
}
