import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

// TODO specs
export class BooleanTransformer extends ValueTransformer<boolean> {
  public static readonly simple = new BooleanTransformer();

  private constructor() {
    super();
  }

  public dataToLiteral(data: boolean): unknown {
    return data;
  }

  public literalToData(literal: unknown, path: LiteralPath): boolean {
    if (typeof literal !== 'boolean') {
      throw new LiteralDeserializationError(literal, path);
    }

    return literal;
  }

  public isSupport(data: unknown): data is boolean {
    return typeof data === 'boolean';
  }
}
