import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  skipWhile,
  Subscription,
} from 'rxjs';

import {AzaCdkScrollBlockingService} from '@azarus/frontend/cdk/scroll-blocking/scroll-blocking.service';

@Injectable({providedIn: 'root'})
export class MobileMenuService implements OnDestroy {
  private readonly _subscription = new Subscription();

  private readonly _opened$ = new BehaviorSubject<boolean>(false);

  public readonly opened$ = this._opened$.asObservable();

  public constructor(
    private readonly _router: Router,
    private readonly _scrollBlockingService: AzaCdkScrollBlockingService,
  ) {
    this._subscription.add(
      this._router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this._opened$.next(false);
        }),
    );

    this._subscription.add(
      this.opened$
        .pipe(
          skipWhile((opened) => !opened),
          distinctUntilChanged(),
        )
        .subscribe((opened) => {
          if (opened) {
            this._scrollBlockingService.requestBlocking();
          } else {
            this._scrollBlockingService.removeBlockingRequest();
          }
        }),
    );
  }

  public toggle(): void {
    this._opened$.next(!this._opened$.value);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
