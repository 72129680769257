import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BalanceManagementAzarusTreasuryCurrentBalanceNextDto} from './balance-management-azarus-treasury-current-balance-next.dto';
import {BalanceManagementAzarusTreasuryCurrentBalanceParamstDto} from './balance-management-azarus-treasury-current-balance-params.dto';

export const BALANCE_MANAGEMENT_AZARUS_TREASURY_CURRENT_BALANCE_REF =
  new AzaRrpcPersistentRef(
    ['balanceManagement', 'azarusTreasuryCurrentBalance'],
    BalanceManagementAzarusTreasuryCurrentBalanceParamstDto,
    asClass(BalanceManagementAzarusTreasuryCurrentBalanceNextDto),
  );
