import {field} from '@azarus/common/transformer/class/field';
import {asEnum} from '@azarus/common/transformer/enum/as-enum';
import {asString} from '@azarus/common/transformer/string/as-string';

import {Platform} from '../../balance-management/api/transactions/platform';
import {AccessToken} from '../../identity-management';
import {SecureParams} from '../../secure-params';

export class CurrentYoutubeGameSessionParamsDto implements SecureParams {
  @field(asEnum(Platform))
  public readonly platform: Platform;

  @field(asString())
  public readonly nameOnPlatform: string;

  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  @field(asString())
  public readonly videoDocumentId: string;

  public constructor(
    platform: CurrentYoutubeGameSessionParamsDto['platform'],
    nameOnPlatform: CurrentYoutubeGameSessionParamsDto['nameOnPlatform'],
    accessToken: CurrentYoutubeGameSessionParamsDto['accessToken'],
    videoDocumentId: CurrentYoutubeGameSessionParamsDto['videoDocumentId'],
  ) {
    this.platform = platform;
    this.nameOnPlatform = nameOnPlatform;
    this.accessToken = accessToken;
    this.videoDocumentId = videoDocumentId;
  }
}
