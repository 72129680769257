import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

export class ApiBrowserExtensionIsChannelAllowsGameParamsDto {
  @field(asString())
  public readonly channelId: string;

  public constructor(
    channelId: ApiBrowserExtensionIsChannelAllowsGameParamsDto['channelId'],
  ) {
    this.channelId = channelId;
  }
}
