import {Inject, Pipe, PipeTransform} from '@angular/core';

import {toFixedNumber} from '@azarus/common/utils/to-fixed-number';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {AZA_CORE_TRUNC_PIPE_DEFAULT_DIGITS} from './trunc-pipe-default-digits';

@Pipe({
  name: 'azaCoreTrunc',
})
export class AzaCoreTruncPipe implements PipeTransform {
  public constructor(
    @Inject(AZA_CORE_TRUNC_PIPE_DEFAULT_DIGITS)
    private readonly _defaultDigits: AzaCdkInjectionTokenType<
      typeof AZA_CORE_TRUNC_PIPE_DEFAULT_DIGITS
    >,
  ) {}

  public transform(num: number, digits = this._defaultDigits): number {
    return toFixedNumber(num, digits);
  }
}
