import {EnumDefinition} from '../type/enum-definition';
import {EnumValue} from '../type/enum-value';

import {extractEnumValues} from './extract-enum-values';

export function isEnumValue<K extends string, V extends EnumValue>(
  value: unknown,
  enumDefinition: EnumDefinition<K, V>,
): value is V {
  return extractEnumValues<K, V>(enumDefinition).includes(value as V);
}
