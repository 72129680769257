import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {isScullyRunning} from '@scullyio/ng-lib';
import {of, switchMap} from 'rxjs';

import {AsyncAuthService} from '../auth/async-auth.service';
import {USER_BALANCE} from '../tokens/user-balance';

export const resolveOptionalUserBalance: ResolveFn<number | null> = () => {
  if (isScullyRunning()) {
    return of(null);
  }
  const authService = inject(AsyncAuthService);
  const userBalance = inject(USER_BALANCE);

  return authService.isAuthorized$.pipe(
    switchMap((isAuthorized) => (isAuthorized ? userBalance : of(null))),
  );
};
