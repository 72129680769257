import {
  concatMap,
  filter,
  fromEvent,
  Observable,
  of,
  share,
  take,
  throwError,
  timeout,
} from 'rxjs';

import {getResponseMethod} from './get-response-method';
import {AzaCdkMessagingClientError} from './messaging-client-error';
import {AzaCdkMessagingClientId} from './messaging-client-id';
import {AzaCdkMessagingResponse} from './messaging-response';

export class AzaCdkMessagingClient {
  private _lastMessageId = 0;
  private readonly _messages$ = fromEvent<
    MessageEvent<AzaCdkMessagingResponse>
  >(window, 'message').pipe(share({resetOnRefCountZero: true}));

  public constructor(
    protected readonly _clientId: AzaCdkMessagingClientId,
    protected readonly _window: Window = window,
    protected readonly _targetOrigin: string = window.origin,
  ) {}

  public unsafeCall(method: string, params: unknown): Observable<unknown> {
    const messageId = ++this._lastMessageId;
    this._window.postMessage(
      {
        clientId: this._clientId,
        messageId,
        method,
        params,
      },
      this._targetOrigin,
    );
    return this._messages$.pipe(
      filter(
        (event) =>
          event.origin === this._targetOrigin &&
          event.source === this._window &&
          event.data.responseMethod ===
            getResponseMethod(this._clientId, method, messageId),
      ),
      take(1),
      concatMap((event) => {
        const response = event.data;
        if (!response.success) {
          return throwError(
            () => new AzaCdkMessagingClientError(response.error),
          );
        }

        return of(response.data);
      }),
      timeout(5000),
    );
  }
}
