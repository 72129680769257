import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';

import {KycLinkResponseBody} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayKycService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getLink(): Observable<string> {
    return this._http
      .get<KycLinkResponseBody>(`${this._httpUrl}/kyc/link`)
      .pipe(map(({link}) => link));
  }
}
