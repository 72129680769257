import {isScullyRunning} from '@scullyio/ng-lib';

import {AZA_SCULLY_AZARUS_SNOWPLOW_FUNCTION} from './azarus-snowplow-function';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GlobalSnowplowNamespace?: unknown[];
    snowplow(...args: unknown[]): unknown;
  }
}

window.GlobalSnowplowNamespace ??= [];
window.GlobalSnowplowNamespace?.push(AZA_SCULLY_AZARUS_SNOWPLOW_FUNCTION);

function snowplowFallback(...args: unknown[]): void {
  snowplowFallback.q.push(args);
}

snowplowFallback.q = [] as Parameters<typeof snowplowFallback>[];

window[AZA_SCULLY_AZARUS_SNOWPLOW_FUNCTION] = snowplowFallback;

function loadSnowplow(): void {
  void import(/* webpackChunkName: "sp" */ './sp');
}

if (!isScullyRunning()) {
  if (document.readyState === 'complete') {
    loadSnowplow();
  } else {
    window.addEventListener('load', loadSnowplow, {once: true});
  }
}

export {};
