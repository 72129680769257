<aza-mobile-menu-base>
  <ng-template azaMobileMenuContent>
    <ng-container [ngTemplateOutlet]="mobileContent"></ng-container>

    <aza-vertical-menu-item
      data-cy="user-profile-menu-logout-item"
      snowplowAction="logout"
      snowplowCategory="auth"
      snowplowEvent
      [path]="logoutNavItem.path"
    >
      <aza-core-svg-icon [icon]="logoutNavItem.icon"></aza-core-svg-icon>
      {{ logoutNavItem.label }}
    </aza-vertical-menu-item>
  </ng-template>
</aza-mobile-menu-base>
