import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AzaCoreCurrencyAmountModule} from '../currency-amount/currency-amount.module';

import {AzaCoreAnimatedCurrencyAmountCommonModule} from './animated-currency-amount.common-module';
import {AzaCoreAnimatedCurrencyAmountComponent} from './animated-currency-amount.component';

@NgModule({
  declarations: [AzaCoreAnimatedCurrencyAmountComponent],
  imports: [
    CommonModule,
    AzaCoreAnimatedCurrencyAmountCommonModule,
    AzaCoreCurrencyAmountModule,
  ],
  exports: [AzaCoreAnimatedCurrencyAmountComponent],
})
export class AzaCoreAnimatedCurrencyAmountModule {}
