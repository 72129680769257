import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreLogoSvgBase} from './logo-svg.base';

@Component({
  selector: 'aza-core-logo-svg',
  templateUrl: './logo-svg.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzaCoreLogoSvgComponent extends AzaCoreLogoSvgBase {}
