import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DialogCloseButtonModule} from '../dialog-close-button/dialog-close-button.module';

import {ContactUsPopupComponent} from './contact-us-popup.component';

@NgModule({
  imports: [CommonModule, DialogCloseButtonModule],
  declarations: [ContactUsPopupComponent],
})
export class ContactUsPopupModule {}
