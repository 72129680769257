import {Observable} from 'rxjs';

import {AccessToken} from '@azarus/api-contract';

export abstract class AsyncAuthService {
  public abstract isAuthorized$: Observable<boolean>;
  public abstract getToken(): Observable<AccessToken | null>;
  public abstract logout(): Observable<void>;
  public abstract deleteUser(): Observable<void>;
}
