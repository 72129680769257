import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {ChannelPresenceStreamDataNextDto} from './channel-presence-stream-data-next.dto';
import {ChannelPresenceStreamDataParamsDto} from './channel-presence-stream-data-params.dto';

export const CHANNEL_PRESENCE_STREAM_DATA_REF = new AzaRrpcPersistentRef(
  ['channelPresence', 'streamData'],
  ChannelPresenceStreamDataParamsDto,
  asClass(ChannelPresenceStreamDataNextDto),
);
