import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';

export class GameAppAvailabilityNextDto {
  @field(asBoolean())
  public readonly available: boolean;

  public constructor(available: GameAppAvailabilityNextDto['available']) {
    this.available = available;
  }
}
