import {
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import {createErrorHandler} from '@sentry/angular';

@NgModule({})
export class AzaCdkSentryModule {
  public static forRoot(
    useSentryErrorHandler: boolean,
  ): ModuleWithProviders<AzaCdkSentryModule> {
    let providers: Provider[] = [];
    if (useSentryErrorHandler) {
      providers = [
        ...providers,
        {
          provide: ErrorHandler,
          useValue: createErrorHandler({
            showDialog: false,
          }),
        },
      ];
    }
    return {
      ngModule: AzaCdkSentryModule,
      providers,
    };
  }
}
