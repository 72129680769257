import {isObject} from './is-object';

/**
 * Alias to simplify checks
 * @see {@link isObject} (stricter)
 */
export function isUnknownObject(
  value: unknown,
): value is object & Record<string, unknown> {
  return isObject(value);
}
