<ng-container *azaCoreLet="isAuthorized$ | async as isAuthorized">
  <aza-header
    [class]="view('header', {clear: isScullyRunning || !isAuthorized})"
  >
    <ng-container *ngIf="!isScullyRunning && isAuthorized !== null">
      <ng-template azaHeaderNavigation>
        <aza-mobile-menu *ngIf="isAuthorized; else baseMobileMenu">
          <ng-template azaMobileMenuContent>
            <aza-user [class]="view('userMobMenu')" i18n>Player</aza-user>

            <ng-container
              [ngTemplateOutlet]="mobileMenuNavItems"
            ></ng-container>

            <ng-container [ngTemplateOutlet]="baseNavItems"></ng-container>
          </ng-template>
        </aza-mobile-menu>
      </ng-template>

      <ng-template azaHeaderUserCorner>
        <nav [class]="view('nav')">
          <a
            *ngFor="let item of navItems"
            [class]="view('navItem', {active: route.isActive})"
            routerLinkActive
            [routerLink]="item.path"
            #route="routerLinkActive"
          >
            <aza-core-svg-icon [icon]="item.icon"></aza-core-svg-icon>

            {{ item.label }}
          </a>
        </nav>

        <aza-user-corner *ngIf="isAuthorized; else authButton">
          <aza-profile-menu [class]="view('profileMenu')">
            <aza-user i18n>Player</aza-user>

            <ng-template azaProfileMenuNavigation>
              <ng-container [ngTemplateOutlet]="baseNavItems"></ng-container>
            </ng-template>
          </aza-profile-menu>
        </aza-user-corner>
      </ng-template>
    </ng-container>
  </aza-header>

  <ng-template #baseMobileMenu>
    <aza-mobile-menu-base>
      <ng-template azaMobileMenuContent>
        <ng-container [ngTemplateOutlet]="mobileMenuNavItems"></ng-container>
      </ng-template>
    </aza-mobile-menu-base>
  </ng-template>

  <ng-template #baseNavItems>
    <aza-vertical-menu-item [path]="walletNavItem.path">
      <aza-core-svg-icon [icon]="walletNavItem.icon"></aza-core-svg-icon>

      {{ walletNavItem.label }}
    </aza-vertical-menu-item>

    <aza-vertical-menu-item [path]="getMoreEntriesNavItem.path">
      <aza-core-svg-icon
        [icon]="getMoreEntriesNavItem.icon"
      ></aza-core-svg-icon>

      {{ getMoreEntriesNavItem.label }}
    </aza-vertical-menu-item>

    <aza-vertical-menu-item [path]="streamerDashboardNavIem.path">
      <aza-core-svg-icon
        [icon]="streamerDashboardNavIem.icon"
      ></aza-core-svg-icon>

      {{ streamerDashboardNavIem.label }}
    </aza-vertical-menu-item>

    <aza-vertical-menu-item [path]="dataCollectionPermissionsNavIem.path">
      <aza-core-svg-icon
        [icon]="dataCollectionPermissionsNavIem.icon"
      ></aza-core-svg-icon>
      {{ dataCollectionPermissionsNavIem.label }}
    </aza-vertical-menu-item>
  </ng-template>

  <ng-template #authButton>
    <a
      aza-core-button
      [class]="view('buttonAuth')"
      color="secondary"
      [routerLink]="loginLink"
    >
      <div class="__aza-text-with-icon__">
        <aza-core-svg-icon [icon]="logInIcon"></aza-core-svg-icon>

        <span i18n>Log In / Sign Up</span>
      </div>
    </a>
  </ng-template>

  <ng-template #mobileMenuNavItems>
    <aza-vertical-menu-item [path]="streamerNavIem.path">
      <aza-core-svg-icon [icon]="streamerNavIem.icon"></aza-core-svg-icon>

      {{ streamerNavIem.label }}
    </aza-vertical-menu-item>

    <aza-vertical-menu-item [path]="shopNavItem.path">
      <aza-core-svg-icon [icon]="shopNavItem.icon"></aza-core-svg-icon>

      {{ shopNavItem.label }}
    </aza-vertical-menu-item>

    <aza-vertical-menu-item [path]="advertiseNavItem.path">
      <aza-core-svg-icon [icon]="advertiseNavItem.icon"></aza-core-svg-icon>

      {{ advertiseNavItem.label }}
    </aza-vertical-menu-item>
  </ng-template>
</ng-container>
