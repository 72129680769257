import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {CurrentGameSessionNextDto} from './current-game-session-next.dto';
import {CurrentGameSessionParamsDto} from './current-game-session-params.dto';

export const CURRENT_GAME_SESSION_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'currentGameSession'],
  CurrentGameSessionParamsDto,
  asClass(CurrentGameSessionNextDto),
);
