import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DialogCloseButtonModule} from '../dialog-close-button/dialog-close-button.module';

import {GreetingPopupComponent} from './greeting-popup.component';

@NgModule({
  imports: [CommonModule, DialogCloseButtonModule],
  declarations: [GreetingPopupComponent],
})
export class GreetingPopupModule {}
