import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';

export class ApiBrowserExtensionNotifyGameStateChangeParamsDto {
  @field(asBoolean())
  public readonly gameInProgress: boolean;

  public constructor(
    gameInProgress: ApiBrowserExtensionNotifyGameStateChangeParamsDto['gameInProgress'],
  ) {
    this.gameInProgress = gameInProgress;
  }
}
