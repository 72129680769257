import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter, Subscription} from 'rxjs';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {
  azaCoreChevronDown,
  azaCoreLightMode,
} from '@azarus/frontend/core/icons';
import {
  AZA_CORE_DROPDOWN_OPTIONS,
  AzaCoreDropdownOptions,
} from '@azarus/frontend/core/modules/dropdown/dropdown-options';

import {LOGOUT_NAV_ITEM} from '../../top-level-components/constants/logout-nav-item';

import {ProfileMenuNavigationDirective} from './profile-menu-navigation.directive';

@Component({
  selector: 'aza-profile-menu',
  templateUrl: './profile-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: AZA_CORE_DROPDOWN_OPTIONS,
      useValue: {
        positions: [
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 10,
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetY: -10,
          },
        ],
      } as AzaCoreDropdownOptions,
    },
  ],
})
export class ProfileMenuComponent
  extends AzaCdkHsvsDirective
  implements OnDestroy
{
  private readonly _subscription = new Subscription();

  @ContentChild(ProfileMenuNavigationDirective, {read: TemplateRef})
  public readonly navigationContent!: TemplateRef<unknown>;

  public readonly chevronDownIcon = azaCoreChevronDown;
  public readonly lightThemeIcon = azaCoreLightMode;

  public readonly logoutNavItem = LOGOUT_NAV_ITEM;

  public opened = false;

  public constructor(
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
  ) {
    super('aza', 'profileMenu');

    this._subscription.add(
      this._router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe(() => {
          this.opened = false;
          this._cdr.markForCheck();
        }),
    );
  }

  public onTriggerClick(): void {
    this.opened = !this.opened;
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
