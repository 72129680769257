import {EnumDefinition} from '../type/enum-definition';
import {EnumValue} from '../type/enum-value';

import {LazyFactory} from './lazy-factory';

const cache = new LazyFactory();

export function extractEnumValues<K extends string, V extends EnumValue>(
  enumDefinition: EnumDefinition<K, V>,
): readonly V[] {
  return cache.make<readonly V[]>(enumDefinition, () => {
    const copy: {[key: string]: V} = Object.assign({}, enumDefinition);

    // find and remove all numeric key entry
    for (const key in enumDefinition) {
      if (typeof enumDefinition[key] === 'number') {
        delete copy[enumDefinition[key]];
      }
    }

    return Object.values<V>(copy);
  });
}
