import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {Platform} from '../../balance-management';

export class NextGameAtParamsDto {
  @field(asString<Platform>())
  public readonly platform: Platform;

  @field(asString())
  public readonly platformId: string;

  public constructor(
    gameSessionId: NextGameAtParamsDto['platform'],
    platformId: NextGameAtParamsDto['platformId'],
  ) {
    this.platform = gameSessionId;
    this.platformId = platformId;
  }
}
