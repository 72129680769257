import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreAzaIconBaseComponent} from './aza-icon.base';

@Component({
  selector: 'aza-core-aza-icon',
  templateUrl: './aza-icon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzaCoreAzaIconComponent extends AzaCoreAzaIconBaseComponent {}
