<div *ngIf="isLoading$ | async" @loading [class]="view('loading')"></div>

<div class="__aza-container-fluid__" [class]="view('container')">
  <div [class]="view('content')">
    <div *ngIf="navigationContent" [class]="view('mobileMenu')">
      <ng-container [ngTemplateOutlet]="navigationContent"></ng-container>
    </div>

    <div [class]="view('navWrapper')">
      <a
        [class]="view('logoLink')"
        aria-label="Azarus home"
        i18n-aria-label
        routerLink="/"
      >
        <aza-logo size="medium"></aza-logo>
      </a>
    </div>

    <div *ngIf="userCornerContent" [class]="view('userCorner')">
      <ng-container [ngTemplateOutlet]="userCornerContent"></ng-container>
    </div>
  </div>
</div>
