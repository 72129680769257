import {azaCoreSettings} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const DATA_COLLECTION_PERMISSIONS_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.DATA_COLLECTION_PERMISSIONS],
  label: $localize`Data`,
  icon: azaCoreSettings,
};
