import {Injectable} from '@angular/core';
import {distinctUntilChanged, map, of, switchMap} from 'rxjs';

import {AsyncAuthService} from '../auth/async-auth.service';

import {UserInfoActionService} from './user-info-action.service';

@Injectable({providedIn: 'root'})
export class SignupStateService {
  public readonly signupComplete$ = this._authService.isAuthorized$.pipe(
    switchMap((isAuthorized) => {
      if (!isAuthorized) {
        return of(false);
      }
      return this._userService.info$.pipe(map(({tos}) => tos));
    }),
    distinctUntilChanged(),
  );

  public constructor(
    private readonly _authService: AsyncAuthService,
    private readonly _userService: UserInfoActionService,
  ) {}
}
