import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {ShopUserPurchasesNextDto} from './shop-user-purchases-next.dto';
import {ShopUserPurchasesParamstDto} from './shop-user-purchases-params.dto';

export const SHOP_USER_PURCHASES_REF = new AzaRrpcPersistentRef(
  ['shopPurchases', 'userPurchases'],
  ShopUserPurchasesParamstDto,
  asClass(ShopUserPurchasesNextDto),
);
