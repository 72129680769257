import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Component({
  selector: 'aza-landing-wrapper',
  templateUrl: './landing-wrapper.component.html',
  styleUrls: ['./landing-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingWrapperComponent extends AzaCdkHsvsDirective {
  public constructor() {
    super('aza', 'landingWrapper');
  }
}
