import {field} from '@azarus/common/transformer/class/field';
import {asNumber} from '@azarus/common/transformer/number/as-number';

export class LastDistributedNextDto {
  @field(asNumber())
  public readonly lastAzaDistributed: number;

  public constructor(
    lastAzaDistributed: LastDistributedNextDto['lastAzaDistributed'],
  ) {
    this.lastAzaDistributed = lastAzaDistributed;
  }
}
