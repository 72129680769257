import {Directive} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Directive()
export class AzaCoreAzaIconBaseComponent extends AzaCdkHsvsDirective {
  public constructor() {
    super('azaCore', 'azaIcon');
  }
}
