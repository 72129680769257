import {NgModule} from '@angular/core';

import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {VerticalMenuItemExternalComponent} from './vertical-menu-item-external.component';

@NgModule({
  declarations: [VerticalMenuItemExternalComponent],
  imports: [AzaCoreSvgIconModule],
  exports: [VerticalMenuItemExternalComponent],
})
export class VerticalMenuItemExternalModule {}
