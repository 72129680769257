import {inject, InjectionToken} from '@angular/core';

import {SnowplowService} from '@azarus/frontend/snowplow';

import {SnowplowAction} from '../modules/snowplow/snowplow-action';
import {SnowplowCategory} from '../modules/snowplow/snowplow-category';

/**
 * Simple InjectionToken to type a generic SnowplowService<C, A>.
 * This spares us to type SnowplowService every time we use it.
 *
 * Also, it's a little less boilerplate instead of an
 * inheriting class, like we did with SnowplowEventDirective.
 */

export const SNOWPLOW_SERVICE = new InjectionToken<
  SnowplowService<SnowplowCategory, SnowplowAction>
>('Provides typed SnowplowService', {
  factory() {
    return inject(SnowplowService);
  },
});
