export enum ProductType {
  BEST_SELLER = 0,
  PERIOD_DEAL = 1,
  FACE_PRODUCT = 2,
  LATEST_ADDITION = 3,
  DISABLE_PRODUCT = 4,
  RESTRICTION_TAGS = 5,
  SORTING_PRIORITY = 6,
  DISCOUNT = 7,
  RETRO_FAVORITES = 8,
  LATEST_RELEASES = 9,
}
