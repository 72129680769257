import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {NextGameAtNextDto} from './next-game-at-next.dto';
import {NextGameAtParamsDto} from './next-game-at-params.dto';

export const NEXT_GAME_AT_REF = new AzaRrpcPersistentRef(
  ['gameApp', 'nextGameAt'],
  NextGameAtParamsDto,
  asClass(NextGameAtNextDto),
);
