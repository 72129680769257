import {inject, InjectionToken} from '@angular/core';

import {NEXT_GAME_AT_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_NEXT_GAME_AT_METHOD = new InjectionToken(
  'API_GATEWAY_NEXT_GAME_AT_METHOD',
  {
    providedIn: 'root',
    factory: () => NEXT_GAME_AT_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  },
);
