import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionSignOutParamsDto} from './sign-out-params.dto';

export const API_BROWSER_EXTENSION_SIGN_OUT_REF = new AzaCdkMessagingRef(
  'signOut',
  ApiBrowserExtensionSignOutParamsDto,
  asNever(),
);
