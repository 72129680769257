import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  Me,
  UnwrapAvailable,
  User,
  UserBalance,
  UserGdpr,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayUserService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getUser(): Observable<Me> {
    return this._http.get<Me>(`${this._httpUrl}/user`);
  }

  public acceptTermsOfService(): Observable<Me> {
    return this._http.post<Me>(`${this._httpUrl}/user/tos`, {});
  }

  public updateFields(
    fields: readonly (keyof Pick<User, 'name' | 'avatar'>)[],
  ): Observable<User> {
    return this._http.post<User>(`${this._httpUrl}/user/auto-fields`, {fields});
  }

  public getUserBalance(): Observable<UserBalance> {
    return this._http.get<UserBalance>(`${this._httpUrl}/user/balance`);
  }

  public getAvailableBalanceForTransfer(): Observable<UnwrapAvailable> {
    return this._http.get<UnwrapAvailable>(
      `${this._httpUrl}/user/balance/unwrap-available`,
    );
  }

  public deleteUser(): Observable<void> {
    return this._http.delete<void>(`${this._httpUrl}/user`);
  }

  public patchGdpr(
    fields: Record<keyof UserGdpr, boolean>,
  ): Observable<UserGdpr> {
    return this._http.patch<UserGdpr>(`${this._httpUrl}/user/gdpr`, fields);
  }
}
