import {formatNumber} from '@angular/common';
import {Directive, HostBinding, Inject, Input, LOCALE_ID} from '@angular/core';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Directive({})
export abstract class AzaCoreCurrencyAmountBase extends AzaCdkHsvsDirective {
  @Input()
  public set amount(amount: number) {
    const [integerPart, floatingPart] = amount.toString().split('.');
    this.integerPart = integerPart;
    this.floatingPart = floatingPart ?? null;
  }

  @HostBinding('dir') public dir = 'ltr';

  public integerPart: string | null = null;
  public floatingPart: string | null = null;

  public readonly separator: string;

  public constructor(
    @Inject(LOCALE_ID)
    private readonly _locale: AzaCdkInjectionTokenType<typeof LOCALE_ID>,
  ) {
    super('aza', 'currencyAmount');
    const match = formatNumber(0, this._locale, '1.1-1').match(/^0([.,])0$/);
    this.separator = match?.[1] ?? '.';
  }
}
