import {inject, InjectionToken} from '@angular/core';

import {BLOCKCHAIN_BRIDGE_UNWRAP_TRANSACTION_STATE_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens/rrpc-client';

export const API_GATEWAY_BLOCKCHAIN_BRIDGE_UNWRAP_TRANSACTION_STATE_METHOD =
  new InjectionToken(
    'API_GATEWAY_BLOCKCHAIN_BRIDGE_UNWRAP_TRANSACTION_STATE_METHOD',
    {
      providedIn: 'root',
      factory: () =>
        BLOCKCHAIN_BRIDGE_UNWRAP_TRANSACTION_STATE_REF.connect(
          inject(API_GATEWAY_RRPC_CLIENT),
        ),
    },
  );
