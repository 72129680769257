import {Inject, Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {SNOWPLOW_TRACKER_WINDOW_KEY} from '../constants/tracker-name';
import {SNOWPLOW_TRACKER_NAME} from '../tokens/tracker-name';
import {SnowplowAddItemParams} from '../types/add-item-params';
import {SnowplowAddTransParams} from '../types/add-trans-params';
import {SnowplowSelfDescribingEvent} from '../types/self-describing-event';
import {SnowplowStructEventParams} from '../types/struct-event-params';
import {SnowplowSuperPropertyContext} from '../types/super-property-context';

@Injectable({providedIn: 'root'})
export class SnowplowService<C extends string, A extends string> {
  public eventCalls$ = new Subject<string>();

  public get tracker(): (...args: any) => any {
    return window[SNOWPLOW_TRACKER_WINDOW_KEY as keyof Window];
  }

  public constructor(
    @Inject(SNOWPLOW_TRACKER_NAME)
    public name: AzaCdkInjectionTokenType<typeof SNOWPLOW_TRACKER_NAME>,
  ) {}

  // TODO: required in future
  // /**
  //  * Social tracking will be used to track the way users interact with Facebook, Twitter and Google + widgets,
  //  * e.g. to capture "like this" or "tweet this" events.
  //  * @param action	Social action performed
  //  * @param network	Social network
  //  * @param target	Object social action is performed on e.g. page ID, product ID
  //  */
  // trackSocialInteraction(action: string, network: string, target?: string) {
  //   window[this.trackerFunction]('trackSocialInteraction', action, network, target);
  // }

  public newTracker(collectorUrl: string, options: {[key: string]: any}): void {
    this.tracker('newTracker', this.name, collectorUrl, options);
  }

  // TODO: it can be useful in the future https://github.com/azarusio/aza-nano-extension/pull/23#discussion_r467945627
  /**
   * Track a self-describing event.
   * https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#371-trackselfdescribingevent
   */
  public trackSelfDescribingEvent(
    event: SnowplowSelfDescribingEvent,
    contextProvider?: () => object,
  ): void {
    this.eventCalls$.next('trackSelfDescribingEvent');
    this.tracker('trackSelfDescribingEvent', event, contextProvider);
  }

  /**
   * @param {SnowplowStructEventParams} params
   */
  public trackStructEvent(
    params: SnowplowStructEventParams<C, A>,
    contextProvider?: () => object,
  ): void {
    this.eventCalls$.next('trackStructEvent');
    this.tracker(
      'trackStructEvent',
      params.category,
      params.action,
      params.label,
      params.property,
      params.value,
      contextProvider,
    );
  }

  /**
   * https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#391-enablelinkclicktracking
   * @param filter
   * @param pseudoClicks
   * @param content
   * @param contexts
   */
  public enableLinkClickTracking(
    filter?: object,
    pseudoClicks?: unknown,
    content?: boolean,
    contexts?: () => object | object[],
  ): void {
    this.tracker(
      'enableLinkClickTracking',
      filter,
      pseudoClicks,
      content,
      contexts,
    );
  }

  /**
   * https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#392-refreshlinkclicktracking
   */
  public refreshLinkClickTracking(): void {
    this.eventCalls$.next('trackLinkClick');
    this.tracker('refreshLinkClickTracking');
  }

  // TODO: https://github.com/azarusio/aza-nano-extension/pull/23/files/9427c14c79864fc5811709ec53f9fe76239d962f#r467946732
  public trackLinkClick(
    targetUrl: unknown,
    elementId?: string,
    elementClasses?: string[],
    elementTarget?: string,
    elementContent?: string,
  ): void {
    this.tracker(
      'trackLinkClick',
      targetUrl,
      elementId,
      elementClasses,
      elementTarget,
      elementContent,
    );
  }

  public setUserId(name: string): void {
    this.tracker('setUserId', name);
  }

  public setPlatform(platform: string): void {
    this.tracker('setPlatform', platform);
  }

  public addGlobalContexts(contexts: SnowplowSuperPropertyContext[]): void {
    this.tracker('addGlobalContexts', contexts);
  }

  public removeGlobalContexts(contexts: SnowplowSuperPropertyContext[]): void {
    this.tracker('removeGlobalContexts', contexts);
  }

  public clearGlobalContexts(): void {
    this.tracker('clearGlobalContexts');
  }

  // TODO: required in future
  // /**
  //  * Use the trackTiming method to track user timing events such as how long resources take to load.
  //  * @param category	Timing category	string
  //  * @param variable	Timed variable	string
  //  * @param timing	Number of milliseconds elapsed number
  //  * @param label Label for the event	string
  //  */
  // trackTiming(category: string, variable: string, timing: number, label?: string) {
  //   window[this.trackerFunction]('trackTiming', category, variable, timing, label);
  // }

  public trackPageView(title?: string): void {
    this.eventCalls$.next('trackPageView');
    this.tracker('trackPageView', title);
  }

  /**
   * The addTrans method creates a transaction object.
   * https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#331-addtrans
   * @param {SnowplowAddTransParams} params
   */
  public addTrans(params: SnowplowAddTransParams): void {
    this.tracker(
      'addTrans',
      params.orderId,
      params.affiliation,
      params.total,
      params.tax,
      params.shipping,
      params.city,
      params.state,
      params.country,
      params.currency,
    );
  }

  /**
   * The addItem method is used to capture the details of each product item included in the transaction.
   * It should therefore be called once for each item.
   * @param {SnowplowAddItemParams} params
   */
  public addItem(params: SnowplowAddItemParams): void {
    this.tracker(
      'addItem',
      params.orderId,
      params.sku,
      params.name,
      params.category,
      params.price,
      params.quantity,
      params.currency,
    );
  }

  /**
   * Once the transaction object has been created (using addTrans) and the relevant item data added to it using the addItem method,
   * we are ready to send the data to the collector. This is initiated using the trackTrans method.
   */
  public trackTrans(): void {
    this.tracker('trackTrans');
  }

  public updatePageActivity(): void {
    this.eventCalls$.next('updatePageActivity');
    this.tracker('updatePageActivity');
  }
}
