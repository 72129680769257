import {field} from '@azarus/common/transformer/class/field';
import {asEnum} from '@azarus/common/transformer/enum/as-enum';
import {asString} from '@azarus/common/transformer/string/as-string';

import {Platform} from '../../balance-management/api/transactions/platform';

export class CurrentGameSessionParamsDto {
  @field(asEnum(Platform))
  public readonly platform: Platform;

  @field(asString())
  public readonly nameOnPlatform: string;

  public constructor(
    platform: CurrentGameSessionParamsDto['platform'],
    nameOnPlatform: CurrentGameSessionParamsDto['nameOnPlatform'],
  ) {
    this.platform = platform;
    this.nameOnPlatform = nameOnPlatform;
  }
}
