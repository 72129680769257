import {
  ChangeDetectionStrategy,
  Component,
  Self,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {UserCornerService} from './user-corner.service';

@Component({
  selector: 'aza-user-corner',
  templateUrl: './user-corner.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [UserCornerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCornerComponent extends AzaCdkHsvsDirective {
  public readonly balance$ = this._balance.lazyUserBalance$;

  public constructor(@Self() private readonly _balance: UserCornerService) {
    super('aza', 'userCorner');
  }
}
