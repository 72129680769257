import {field} from '@azarus/common/transformer/class/field';
import {asDate} from '@azarus/common/transformer/date/as-date';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';

import {GameAppId} from '../game-app-id';

export class NextGameAtNextDto {
  @field(asNullable(asString<GameAppId>()))
  public readonly gameAppId: GameAppId | null;

  @field(asNullable(asDate()))
  public readonly nextAt: Date | null;

  public constructor(
    gameAppId: NextGameAtNextDto['gameAppId'],
    nextAt: NextGameAtNextDto['nextAt'],
  ) {
    this.gameAppId = gameAppId;
    this.nextAt = nextAt;
  }
}
