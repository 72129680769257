import {azaCoreWinners} from '@azarus/frontend/core/icons';

import {AppRouteSegment} from '../../app-route-segment.enum';
import {NavItem} from '../nav-item';

export const GET_MORE_ENTRIES_NAV_ITEM: NavItem = {
  path: [AppRouteSegment.ROOT, AppRouteSegment.GET_MORE_ENTRIES],
  label: $localize`Get More Entries`,
  icon: azaCoreWinners,
};
