import {inject, InjectionToken} from '@angular/core';

import {KYC_STATUS_REF} from '@azarus/api-contract';

import {API_GATEWAY_RRPC_CLIENT} from '../../tokens';

export const API_GATEWAY_KYC_STATUS_METHOD = new InjectionToken(
  'API_GATEWAY_KYC_STATUS_METHOD',
  {
    providedIn: 'root',
    factory: () => KYC_STATUS_REF.connect(inject(API_GATEWAY_RRPC_CLIENT)),
  },
);
