import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionNotifyButtonAreaLeftParamsDto} from './notify-button-area-left-params.dto';

export const API_BROWSER_EXTENSION_NOTIFY_BUTTON_AREA_LEFT_REF =
  new AzaCdkMessagingRef(
    'notifyButtonAreaLeft',
    ApiBrowserExtensionNotifyButtonAreaLeftParamsDto,
    asNever(),
  );
