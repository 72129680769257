import {HttpResponse} from '@angular/common/http';
import {parse} from 'content-disposition-attachment';

export function readFileFromResponse(
  response: HttpResponse<ArrayBuffer>,
): File {
  if (response.body === null) {
    throw new Error('Unable to construct a file from null');
  }

  const contentDispositionHeader = response.headers.get('content-disposition');
  if (contentDispositionHeader === null) {
    throw new Error('Content-Disposition response header is missing');
  }

  const contentDisposition = parse(contentDispositionHeader);
  if (
    !contentDisposition.attachment ||
    contentDisposition.filename === undefined
  ) {
    throw new Error(
      'Expected file name to be set in the Content-Disposition response header',
    );
  }

  return new File([response.body], contentDisposition.filename);
}
