import {field} from '@azarus/common/transformer/class/field';
import {asString} from '@azarus/common/transformer/string/as-string';

import {AccessToken} from '../../identity-management';
import {SecureParams} from '../../secure-params';

export class ChannelPresenceStreamDataParamsDto implements SecureParams {
  @field(asString<AccessToken>())
  public readonly accessToken: AccessToken;

  public constructor(
    accessToken: ChannelPresenceStreamDataParamsDto['accessToken'],
  ) {
    this.accessToken = accessToken;
  }
}
