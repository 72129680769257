import {inject} from '@angular/core';
import {CanMatchFn, Router} from '@angular/router';
import {map, of, switchMap, take} from 'rxjs';

import {AppRouteSegment} from '../app-route-segment.enum';
import {AsyncAuthService} from '../auth/async-auth.service';
import {LoginPageQueryParam} from '../pages/login-page/login-page-query-param';
import {UserInfoActionService} from '../services/user-info-action.service';

/**
 * Unlike canMatchWIthSignupComplete it only checks if signup is completed for logged in users
 *
 * @param route
 * @param urlSegments
 * @returns
 */
export const canMatchWithOptionalSignupComplete: CanMatchFn = (
  _,
  urlSegments,
) => {
  const authService = inject(AsyncAuthService);
  const userInfoActionService = inject(UserInfoActionService);
  const router = inject(Router);

  const returnPath = router.serializeUrl(
    router.createUrlTree(urlSegments.map((urlSegment) => urlSegment.path)),
  );
  return authService.isAuthorized$.pipe(
    take(1),
    switchMap((isAuthorized) => {
      if (!isAuthorized) {
        return of(true);
      }
      return userInfoActionService.info$.pipe(
        map(({tos}) => {
          if (!tos) {
            return router.createUrlTree(
              [AppRouteSegment.ROOT, AppRouteSegment.LOGIN],
              {
                queryParams: {
                  [LoginPageQueryParam.RETURN_PATH]: returnPath,
                },
              },
            );
          }
          return true;
        }),
      );
    }),
  );
};
