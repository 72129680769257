import {asArray} from '@azarus/common/transformer/array/as-array';
import {asClass} from '@azarus/common/transformer/class/as-class';
import {field} from '@azarus/common/transformer/class/field';

import {WinnerDto} from './winner.dto';

export class LastWinnersNextDto {
  @field(asArray(asClass(WinnerDto)))
  public readonly lastWinners: WinnerDto[];

  public constructor(lastWinners: LastWinnersNextDto['lastWinners']) {
    this.lastWinners = lastWinners;
  }
}
