import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcPersistentRef} from '@azarus/common/utils/aza-rrpc-persistent-ref';

import {BalanceManagementStreamerCurrentBalanceNextDto} from './balance-management-streamer-current-balance-next.dto';
import {BalanceManagementStreamerCurrentBalanceParamstDto} from './balance-management-streamer-current-balance-params.dto';

export const BALANCE_MANAGEMENT_STREAMER_CURRENT_BALANCE_REF =
  new AzaRrpcPersistentRef(
    ['balanceManagement', 'streamerCurrentBalance'],
    BalanceManagementStreamerCurrentBalanceParamstDto,
    asClass(BalanceManagementStreamerCurrentBalanceNextDto),
  );
