import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  GetProductsQueryParams,
  PaginationResponse,
  ProductResponse,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayProductsService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _url: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getProducts(
    queryParams: GetProductsQueryParams,
  ): Observable<PaginationResponse<ProductResponse>> {
    return this._http.get<PaginationResponse<ProductResponse>>(
      `${this._url}/products`,
      {
        params: {...queryParams},
      },
    );
  }

  public getProductById(productId: string): Observable<ProductResponse> {
    return this._http.get<ProductResponse>(
      `${this._url}/products/${productId}`,
    );
  }

  public getProductsFace(): Observable<readonly ProductResponse[]> {
    return this._http.get<ProductResponse[]>(`${this._url}/products/face`);
  }

  public getProductsLatestAddition(): Observable<readonly ProductResponse[]> {
    return this._http.get<ProductResponse[]>(
      `${this._url}/products/latest-addition`,
    );
  }
}
