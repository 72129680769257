import {asNever} from '@azarus/common/transformer/never/as-never';
import {AzaCdkMessagingRef} from '@azarus/frontend/cdk/messaging/messaging-ref';

import {ApiBrowserExtensionDeleteUserParamsDto} from './delete-user-params.dto';

export const API_BROWSER_EXTENSION_DELETE_USER_REF = new AzaCdkMessagingRef(
  'deleteUser',
  ApiBrowserExtensionDeleteUserParamsDto,
  asNever(),
);
