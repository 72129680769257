import 'reflect-metadata';

import {Directive, HostBinding} from '@angular/core';

import {AzaCdkHsvsStates} from './hsvs-states';
import {AzaCdkHsvsValue} from './hsvs-value';
import {AZA_CDK_METADATA_HSVS} from './metadata-hsvs-keys';

const EMPTY_STATES: AzaCdkHsvsStates = {};

@Directive({})
export abstract class AzaCdkHsvsDirective {
  private readonly _mappedKeys: ReadonlyMap<keyof this & string, string>;

  protected constructor(
    public readonly _prefix: string,
    public readonly _name: string,
  ) {
    this._mappedKeys =
      Reflect.getMetadata(AZA_CDK_METADATA_HSVS, this) ?? new Map();
  }

  @HostBinding('class') public get hostClass(): string {
    let result = `h_${this._prefix}_${this._name}`;

    for (const [key, cssName] of this._mappedKeys) {
      // already checked in decorator
      const value = this[key] as unknown as AzaCdkHsvsValue;

      if (value === false || value === null) {
        continue;
      }

      if (value === true) {
        result += ` hs_${cssName}`;

        continue;
      }

      result += ` hs_${key}_${value}`;
    }

    return result;
  }

  public view(name: string, states: AzaCdkHsvsStates = EMPTY_STATES): string {
    let result = `hv_${this._prefix}_${this._name} v_${name}`;

    for (const key in states) {
      const value: AzaCdkHsvsValue = states[key];

      if (value === false || value === null) {
        continue;
      }

      if (value === true) {
        result += ` vs_${key}`;

        continue;
      }

      result += ` vs_${key}_${value}`;
    }

    return result;
  }
}
