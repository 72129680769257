import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';

import {AzaCoreSvgIconModule} from '@azarus/frontend/core/modules/svg-icon/svg-icon.module';

import {DialogCloseButtonModule} from '../dialog-close-button/dialog-close-button.module';

import {PurchaseErrorDialogComponent} from './purchase-error-dialog.component';

@NgModule({
  declarations: [PurchaseErrorDialogComponent],
  exports: [PurchaseErrorDialogComponent],
  imports: [
    AzaCoreSvgIconModule,
    DialogCloseButtonModule,
    CommonModule,
    MatDialogModule,
    ClipboardModule,
  ],
})
export class PurchaseErrorDialogModule {}
