import {isInvalidDate} from '../../utils/is-invalid-date';
import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

import {DateSerializationError} from './date-serialization-error';

// TODO specs
// TODO maybe serialize "Invalid Date"
export class DateTransformer extends ValueTransformer<Date> {
  public static readonly simple = new DateTransformer();

  private constructor() {
    super();
  }

  public dataToLiteral(data: Date): unknown {
    if (isInvalidDate(data)) {
      throw new DateSerializationError(data);
    }

    return data.toISOString();
  }

  public literalToData(literal: unknown, path: LiteralPath): Date {
    if (typeof literal !== 'string') {
      throw new LiteralDeserializationError(literal, path);
    }

    const date = new Date(literal);

    if (isInvalidDate(date)) {
      throw new LiteralDeserializationError(literal, path);
    }

    return date;
  }

  public isSupport(data: unknown): data is Date {
    return data instanceof Date;
  }
}
