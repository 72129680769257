import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Component({
  selector: 'button[aza-moca-button], a[aza-moca-button]',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MocaButtonComponent extends AzaCdkHsvsDirective {
  public constructor() {
    super('aza', 'mocaButton');
  }
}
