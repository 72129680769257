import {field} from '@azarus/common/transformer/class/field';
import {asEnum} from '@azarus/common/transformer/enum/as-enum';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';

import {GameSessionId} from '../game-session-id';
import {GameSessionState} from '../game-session-state';

export class GameSessionStateNextDto {
  @field(asString<GameSessionId>())
  public readonly _id: GameSessionId;

  @field(asEnum(GameSessionState))
  public readonly state: GameSessionState;

  @field(asNullable(asString()))
  public readonly errorMessage: string | null;

  public constructor(
    _id: GameSessionStateNextDto['_id'],
    state: GameSessionStateNextDto['state'],
    errorMessage: GameSessionStateNextDto['errorMessage'],
  ) {
    this._id = _id;
    this.state = state;
    this.errorMessage = errorMessage;
  }
}
