import {asArray} from '@azarus/common/transformer/array/as-array';
import {asBoolean} from '@azarus/common/transformer/boolean/as-boolean';
import {field} from '@azarus/common/transformer/class/field';
import {asDate} from '@azarus/common/transformer/date/as-date';
import {asEnum} from '@azarus/common/transformer/enum/as-enum';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';

import {ClientPurchaseStatus} from '../../../enums/client-purchase-status.enum';
import {CodeType} from '../enums/code-type.enum';

export class ShopUserPurchasesData {
  @field(asString())
  public readonly _id: string;

  @field(asDate())
  public readonly date: Date;

  @field(asEnum(ClientPurchaseStatus))
  public readonly status: ClientPurchaseStatus;

  @field(asBoolean())
  public readonly isViewed: boolean;

  @field(asNullable(asString()))
  public readonly code: string | null;

  @field(asEnum(CodeType))
  public readonly codeType: CodeType;

  @field(asString())
  public readonly name: string;

  @field(asString())
  public readonly platform: string;

  @field(asArray(asString()))
  public readonly regions: string[];

  @field(asString())
  public readonly image: string;

  @field(asNullable(asString()))
  public readonly redemptionUrl: string | null;

  public constructor(
    _id: ShopUserPurchasesData['_id'],
    date: ShopUserPurchasesData['date'],
    status: ShopUserPurchasesData['status'],
    isViewed: ShopUserPurchasesData['isViewed'],
    code: ShopUserPurchasesData['code'],
    codeType: ShopUserPurchasesData['codeType'],
    name: ShopUserPurchasesData['name'],
    platform: ShopUserPurchasesData['platform'],
    regions: ShopUserPurchasesData['regions'],
    image: ShopUserPurchasesData['image'],
    redemptionUrl: ShopUserPurchasesData['redemptionUrl'],
  ) {
    this._id = _id;
    this.date = date;
    this.status = status;
    this.isViewed = isViewed;
    this.code = code;
    this.codeType = codeType;
    this.name = name;
    this.platform = platform;
    this.regions = regions;
    this.image = image;
    this.redemptionUrl = redemptionUrl;
  }
}
