import {
  catchError,
  concatMap,
  defer,
  EMPTY,
  last,
  map,
  Observable,
  of,
} from 'rxjs';

import {asClass} from '@azarus/common/transformer/class/as-class';
import {NeverTransformer} from '@azarus/common/transformer/never/never-transformer';
import {ValueTransformer} from '@azarus/common/transformer/value-transformer';

import {AzaCdkMessagingClient} from './messaging-client';
import {AzaCdkMessagingResponse} from './messaging-response';

export class AzaCdkMessagingRef<
  P extends object,
  A extends readonly unknown[],
  N,
> {
  private readonly _params: ValueTransformer<P>;

  public constructor(
    public readonly method: string,
    private readonly _dto: new (...params: A) => P,
    private readonly _next: ValueTransformer<N>,
  ) {
    this._params = asClass(_dto);
  }

  protected _createObservable(
    client: AzaCdkMessagingClient,
    data: P,
  ): Observable<N> {
    return defer(() => {
      const response$ = client.unsafeCall(
        this.method,
        this._params.dataToLiteral(data),
      );
      if (this._next instanceof NeverTransformer) {
        return EMPTY;
      }
      return response$.pipe(
        concatMap((literal) => {
          return of(this._next.literalToData(literal, []));
        }),
      );
    });
  }

  public connect(
    client: AzaCdkMessagingClient,
  ): (...params: A) => Observable<N> {
    return (...params) => {
      return this._createObservable(client, new this._dto(...params));
    };
  }

  public processMethod(
    responseMethod: string,
    params: unknown,
    fn: (params: P) => Observable<N>,
  ): Observable<AzaCdkMessagingResponse> {
    return fn(this._params.literalToData(params, [])).pipe(
      map((response) => {
        const data = this._next.dataToLiteral(response);
        return {responseMethod, success: true, data} as AzaCdkMessagingResponse;
      }),
      // handles observables that complete but never emit data
      last(() => true, {
        responseMethod,
        success: true,
        data: null,
      } as AzaCdkMessagingResponse),
      catchError((error) => {
        console.error(error);
        return of<AzaCdkMessagingResponse>({
          responseMethod,
          success: false,
          error: error.message,
        });
      }),
    );
  }
}
