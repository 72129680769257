import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

@Component({
  selector: 'aza-landing',
  templateUrl: './landing.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent extends AzaCdkHsvsDirective {
  public constructor() {
    super('aza', 'landing');
  }
}
