import {asClass} from '@azarus/common/transformer/class/as-class';
import {AzaRrpcRef} from '@azarus/common/utils/aza-rrpc-ref';

import {ChannelPresenceHeartbeatNextDto} from './channel-presence-heartbeat-next.dto';
import {ChannelPresenceHeartbeatParamsDto} from './channel-presence-heartbeat-params.dto';

export const CHANNEL_PRESENCE_HEARTBEAT_REF = new AzaRrpcRef(
  ['channelPresence', 'heartbeat'],
  ChannelPresenceHeartbeatParamsDto,
  asClass(ChannelPresenceHeartbeatNextDto),
);
