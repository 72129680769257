import '@azarus/frontend/scully/snowplow/snowplow-lazy';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {init as sentryInit} from '@sentry/angular';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryEnabled) {
  sentryInit({
    dsn: 'https://e5fab22502e843b2a603b2c5059e4e6b@o190201.ingest.sentry.io/6193090',
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
