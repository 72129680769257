import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  PaginationResponse,
  TransactionQueryParams,
  UserTransaction,
  UserTransactionTypesBodyResp,
} from '@azarus/api-contract';
import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {API_GATEWAY_HTTP_URL} from '../../tokens';

@Injectable({providedIn: 'root'})
export class ApiGatewayTransactionsService {
  public constructor(
    @Inject(API_GATEWAY_HTTP_URL)
    private readonly _httpUrl: AzaCdkInjectionTokenType<
      typeof API_GATEWAY_HTTP_URL
    >,
    private readonly _http: HttpClient,
  ) {}

  public getUserTransactions(
    queryParams: TransactionQueryParams,
  ): Observable<PaginationResponse<UserTransaction>> {
    return this._http.get<PaginationResponse<UserTransaction>>(
      `${this._httpUrl}/user/transactions`,
      {params: {...queryParams}},
    );
  }

  public getTransactionTypes(): Observable<UserTransactionTypesBodyResp> {
    return this._http.get<UserTransactionTypesBodyResp>(
      `${this._httpUrl}/transactions/user-transactions-types`,
    );
  }
}
